export enum EResourcesCode {
  WHITEBOARD = 'WHITEBOARD',
  VIDEO_CONFERENCING_EQUIPMENT = 'VIDEO_CONFERENCING_EQUIPMENT',
  MICROPHONE = 'MICROPHONE',
  PENS_AND_PAPERS = 'PENS_AND_PAPERS',
  PROJECTOR = 'PROJECTOR',
  OFFICE_SPEAKERPHONES = 'OFFICE_SPEAKERPHONES',
  DOCKING_STATION = 'DOCKING_STATION',
  MOUSE = 'MOUSE',
  KEYWORD = 'KEYWORD',
  FLIP_CHART = 'FLIP_CHART',
  PRINTER = 'PRINTER',
  COMPUTERS = 'COMPUTERS',
  SCANNER = 'SCANNER',
  UNLIMITED_DRINKING_WATER = 'UNLIMITED_DRINKING_WATER',
  UNLIMITED_COFFEE_AND_TEA = 'UNLIMITED_COFFEE_AND_TEA',
  BREAKFAST = 'BREAKFAST',
  LUNCH_STANDARD = 'LUNCH_STANDARD',
  DRINKS = 'DRINKS',
  LUNCH = 'LUNCH',
  SNACKS = 'SNACKS',
  COFFEE_AND_SNACKS = 'COFFEE_AND_SNACKS',
  LUNCH_MENU_OR_BUFFET = 'LUNCH_MENU_OR_BUFFET',
  UNLIMITED_CONFERENCE_DRINKS_HALF_DAY = 'UNLIMITED_CONFERENCE_DRINKS_HALF_DAY',
  UNLIMITED_CONFERENCE_DRINKS_FULL_DAY = 'UNLIMITED_CONFERENCE_DRINKS_FULL_DAY',
  DINNER_OR_BUFFET = 'DINNER_OR_BUFFET',
  WELCOME_COFFEE = 'WELCOME_COFFEE',
  SCREEN_FOR_VIDEO_PROJECTION = 'SCREEN_FOR_VIDEO_PROJECTION',
  WIFI = 'WIFI',
  SOUND_SYSTEM = 'SOUND_SYSTEM',
  HANDED_WIRED_MICROPHONE = 'HANDED_WIRED_MICROPHONE',
  CLIP_MICROPHONE = 'CLIP_MICROPHONE',
  LECTERN = 'LECTERN',
  PINBOARD = 'PINBOARD',
  MONITOR = 'MONITOR',
  SINGLE_BEDROOM = 'SINGLE_BEDROOM',
  DOUBLE_BEDROOM = 'DOUBLE_BEDROOM',
  SINGLE_BEDROOM_WITH_BREAKFAST = 'SINGLE_BEDROOM_WITH_BREAKFAST',
  DOUBLE_BEDROOM_WITH_BREAKFAST = 'DOUBLE_BEDROOM_WITH_BREAKFAST',
}

export enum EResourcesType {
  EQUIPMENT = 'EQUIPMENT',
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  PACKAGES = 'PACKAGES',
  BEDROOM = 'BEDROOM',
}

/**
 * TODO please don't use it for any functionality
 * these id's are dynamic will be replaced to code field
 */
export enum EBedroomExtras {
  SINGLE_BEDROOM = 100,
  DOUBLE_BEDROOM = 101,
  SINGLE_BEDROOM_WITH_BREAKFAST = 102,
  DOUBLE_BEDROOM_WITH_BREAKFAST = 103,
}

export enum EBedroomExtrasNames {
  SINGLE_BEDROOM = 'Single bedroom',
  DOUBLE_BEDROOM = 'Double bedroom',
  SINGLE_BEDROOM_WITH_BREAKFAST = 'Single bedroom with breakfast',
  DOUBLE_BEDROOM_WITH_BREAKFAST = 'Double bedroom with breakfast',
}

export enum EResourcesName {
  EQUIPMENT = 'Equipment',
  FOOD_AND_BEVERAGE = 'Food & Beverage',
  PACKAGES = 'Packages',
}

export enum EPriceType {
  PER_UNIT = 'PER_UNIT',
  PER_DAY = 'PER_DAY',
  PER_HOUR = 'PER_HOUR',
  PER_ITEM = 'PER_ITEM',
  PER_ROOM = 'PER_ROOM',
  FLATRATE = 'FLATRATE',
  PER_PERSON = 'PER_PERSON',
  FREE = 'FREE',
}

export enum EBookableWith {
  WORK_SPACE = 'WORK_SPACE',
  MEETING_ROOM = 'MEETING_ROOM',
}

export interface IExtrasResponse {
  id: number;
  name: string;
  extraId?: number;
  code: EResourcesCode;
  accommodationExtraId: number;
  description: string;
  extraType: EResourcesType;
  bookableWith: EBookableWith[];
  priceType: EPriceType;
  price: number;
  isLimited: boolean;
  isEnabled: boolean;
  totalPrice: number;
  quantity?: number;
}

export interface ISelectedForBookingExtra {
  id: number;
  selectedQuantity: number;
  price: number;
}

export interface IExtrasRequest {
  description: string | undefined;
  bookableWith: EBookableWith[];
  isLimited: boolean;
  name?: string;
  priceType?: EPriceType;
  price?: number;
  quantity?: number;
}

export interface IExtrasOption {
  id: number;
  name: string;
  type: EResourcesType;
  code: EResourcesCode;
  extraType?: EResourcesType;
}

export enum EDefaultExtras {
  coffee_and_snacks_break = 'coffee_and_snacks_break',
  lunch_as_3_course_menu_or_buffet_including_one_soft_drink = 'lunch_as_3_course_menu_or_buffet_including_one_soft_drink',
  dinner_including_one_soft_drink_or_one_glass_of_wine_beer = 'dinner_including_one_soft_drink_or_one_glass_of_wine/beer',
  unlimited_conference_drinks_half_day = 'unlimited_conference_drinks_half_day',
  unlimited_conference_drinks_full_day = 'unlimited_conference_drinks_full_day',
  screen_for_video_projection = 'screen_for_video_projection',
  wifi = 'wifi',
  pinboard = 'pinboard',
  projector = 'projector',
  flipchart = 'flipchart',
}
