import React, {memo} from 'react';
import {DateTime} from 'luxon';
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import {useAppSelector} from 'store/hooks';

import {NW2MultiDatePickerMobileWithCustomHeader} from './NW2MultiDatePickerMobileWithCustomHeader';

import {ERoomType} from 'types/dto/ERoomType.type';
import {Wrapper} from './NW2MultiDatePicker.styles';

interface IProps extends ReactDatePickerProps {
  isSingleDayDatePicker?: boolean;
  withMonthYearDropdown?: boolean;
  showMonthYearDropdown?: boolean;
  hideCustomHeader?: boolean;
}

export const NW2MultiDatePicker = memo(
  ({
    startDate,
    endDate,
    onChange,
    isSingleDayDatePicker,
    withMonthYearDropdown,
    showMonthYearDropdown = true,
    hideCustomHeader,
    ...rest
  }: IProps) => {
    const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
    const isTablet = useAppSelector(({app}) => app.deviceType.isTablet);

    const roomType = useAppSelector(
      ({search}) => search.searchCriteria.roomType,
    );

    const config = {
      minDate: DateTime.now().toJSDate(),
      timeIntervals: 60,
      timeFormat: 'HH:mm',
      timeCaption: 'time',
      dateFormat: 'd MMM, yyyy',
      calendarStartDay: 1,
      inline: true,
    };

    const configForMonthYearDropdown = withMonthYearDropdown
      ? {
          maxDate: DateTime.now().plus({years: 100}).toJSDate(),
          showMonthYearDropdown,
          scrollableMonthYearDropdown: true,
        }
      : {};

    const configForSingleDayDatePicker = isSingleDayDatePicker
      ? {
          selectsRange: false,
          endDate: null,
        }
      : {};

    const isWorkSpace = roomType === ERoomType.WORK_SPACE;
    const endDateForPicker = isWorkSpace ? null : endDate;

    const renderCustomHeader = ({
      monthDate,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
      customHeaderCount,
    }: ReactDatePickerCustomHeaderProps) => {
      const displayedMonth = monthDate.getMonth();
      const displayedYear = monthDate.getFullYear();

      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      const showPrevButton =
        displayedMonth !== currentMonth || displayedYear !== currentYear;

      return (
        <>
          {customHeaderCount !== 1 && showPrevButton && (
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className='react-datepicker__navigation react-datepicker__navigation--previous'
            >
              <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'></span>
            </button>
          )}
          <span className='react-datepicker__current-month'>
            {`${monthDate.toLocaleString('default', {
              month: 'long',
            })} ${monthDate.getFullYear()}`}
          </span>
          {customHeaderCount !== 0 && (
            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className='react-datepicker__navigation react-datepicker__navigation--next'
            >
              <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--next'></span>
            </button>
          )}
        </>
      );
    };

    const datePickerConfig = {
      selected: startDate,
      onChange,
      startDate,
      endDate: endDateForPicker,
      selectsRange: !isWorkSpace,
      renderCustomHeader:
        isTablet || isMobile || isWorkSpace || hideCustomHeader
          ? undefined
          : renderCustomHeader,
      ...config,
      ...configForMonthYearDropdown,
      ...configForSingleDayDatePicker,
      ...rest,
    };

    return (
      <Wrapper
        isMobile={isMobile}
        withMonthYearDropdown={withMonthYearDropdown}
      >
        {!isMobile ? (
          <DatePicker {...datePickerConfig} />
        ) : (
          <NW2MultiDatePickerMobileWithCustomHeader {...datePickerConfig} />
        )}
      </Wrapper>
    );
  },
);
