import React from 'react';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import getSymbolFromCurrency from 'currency-symbol-map';

const defaultConfig = {
  position: 'suffix',
  thousandsSeparator: '.',
  decimalSeparator: ',',
};

const specificConfig = {
  position: 'prefix',
  thousandsSeparator: ',',
  decimalSeparator: '.',
};

type TProps = {
  price?: number | string | null;
  currency?: string | null;
};
const formatPrice = (
  price?: number | string | null,
  currencyCode?: string | null,
): string => {
  if (!currencyCode) {
    return formatFloatWithOneNumber(price);
  }
  const specificCurrencies = ['USD', 'CAD', 'GBP'];
  const config = specificCurrencies.includes(currencyCode)
    ? specificConfig
    : defaultConfig;

  const symbol = getSymbolFromCurrency(currencyCode);
  if (!symbol) {
    throw new Error(`Unknown currency code: ${currencyCode}`);
  }

  let formattedPrice = formatFloatWithOneNumber(price);
  const parts = formattedPrice.split(',');
  const integerPart = parts[0].replace(
    /\B(?=(\d{3})+(?!\d))/g,
    config.thousandsSeparator,
  );
  const decimalPart = parts[1] || '';

  formattedPrice = `${integerPart}${config.decimalSeparator}${decimalPart}`;

  if (config.position === 'prefix') {
    formattedPrice = `${symbol}${formattedPrice}`;
  } else {
    formattedPrice = `${formattedPrice} ${symbol}`;
  }

  return formattedPrice;
};

const PriceDisplay: React.FC<TProps> = ({price, currency}: TProps) => {
  return <>{formatPrice(price, currency)}</>;
};

export default PriceDisplay;
