import {KeyboardEvent} from 'react';
import Pluralize from 'pluralize';
import {RcFile} from 'antd/es/upload';
import {DateTime} from 'luxon';

import {getDateTime} from './dateUtils';
import {convertExtraNameToEnum} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {EMPTY_OBJECT, US_FORMAT_CURRENCIES} from 'constants/app';

import {TImage} from 'types/app';
import {EAmenitiesCategories} from 'types/venue';
import {EAmenityType} from 'types/amenities';
import {EBookableWith, EDefaultExtras} from 'types/dto/IExtras.type';
import {IExtraResponse} from 'types/dto/IPublicVenue';

export const ONLY_DIGITS_EXP = new RegExp(/^(\s*|\d+)$/);

export const ONLY_DIGITS_WITH_ONE_DOT_EXP = new RegExp(
  /^((?!0)\d{0,10}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/,
); // only digits with one dot & 2 digits after dot

export const pluralize = (word: string, count: number, showNumber = true) =>
  Pluralize(word, count, showNumber);

export const sequencesArrayByRange = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({length}, (_, idx) => idx + start);
};

export const isJpegOrJpgOrPng = (file: RcFile): boolean =>
  file.type === 'image/jpeg' ||
  file.type === 'image/jpg' ||
  file.type === 'image/png';
export const fileSize = (file: RcFile): number => file.size / 1024 / 1024;
export const maxSize = (file: RcFile): boolean => fileSize(file) > 15;
export const minSize = (file: RcFile): boolean => fileSize(file) > 0.05;
export const isImageValid = (file: RcFile): boolean =>
  isJpegOrJpgOrPng(file) && !maxSize(file) && minSize(file);

export const findCoverImage = (images: TImage[]) =>
  images?.find((doc) => doc.isCover) || images[0] || EMPTY_OBJECT;

export const preventNumberOperators = (e: KeyboardEvent<HTMLElement>) => {
  if (
    e.key === ',' ||
    e.key === '.' ||
    e.key === '-' ||
    e.key === '+' ||
    e.key === 'e' ||
    e.key === 'E'
  ) {
    e.preventDefault();
  }
};

export const restrictOutOfRangeAndFloat = (
  val: string,
  min: number,
  max: number,
): number =>
  val === '' || parseInt(val) === 0 || +val < min
    ? min
    : +val > max
    ? max
    : parseInt(val);

export const restrictNoNumericString = (val: string): string => {
  const isNumeric = !isNaN(+val) && !isNaN(parseFloat(val));
  return isNumeric ? val : '';
};

export const amenityCategoryFromKey = (key: EAmenitiesCategories) => {
  switch (key) {
    case EAmenitiesCategories.GENERAL:
      return EAmenityType.VENUE;
    case EAmenitiesCategories.SERVICES:
      return EAmenityType.VENUE_SERVICE;
    case EAmenitiesCategories.ENTERTAINMENT:
      return EAmenityType.VENUE_ENTERTAINMENT;
    case EAmenitiesCategories.ACCESSIBILITY:
      return EAmenityType.VENUE_ACCESSIBILITY;
    case EAmenitiesCategories.TRANSPORTATION:
      return EAmenityType.VENUE_TRANSPORTATION;
    case EAmenitiesCategories.WELLNESS_FITNESS:
      return EAmenityType.VENUE_WELLNESS_AND_FITNESS;
    default:
      break;
  }
};

export const getTrimmedData = (obj: any) => {
  if (obj && typeof obj === 'object') {
    Object.keys(obj).map((key) => {
      if (typeof obj[key] === 'object') {
        getTrimmedData(obj[key]);
      } else if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim();
      }
    });
  }
  return obj;
};

export const getFloors = (floorCount: number) => {
  let floorObject: Record<string, number> = {};

  for (let floorNumber = 1; floorNumber <= floorCount; floorNumber++) {
    floorObject = {
      ...floorObject,
      [`Floor ${floorNumber}`]: floorNumber,
    };
  }
  return floorObject;
};

export const inputTextToNumber = (
  value: string,
  fn: (v: string) => void,
  withoutDot?: boolean,
  minValue?: string,
  deFormat?: boolean,
) => {
  if (minValue) {
    // set minValue
    fn(minValue);
    return;
  }

  const regex = withoutDot ? ONLY_DIGITS_EXP : ONLY_DIGITS_WITH_ONE_DOT_EXP;

  const isContainsSplitter = deFormat
    ? value.includes('.') || value.includes(',')
    : value.includes(',');

  const valueToTest = isContainsSplitter
    ? deFormat
      ? value.replace(/\./g, '').replace(/,/g, '.')
      : value.replace(/,/g, '')
    : value;

  if (regex.test(valueToTest)) {
    fn(value);
  }
};

export const removeExtension = (filename: string) =>
  filename.substring(0, filename.lastIndexOf('.')) || filename;

export const isNull = (val: any) => Object.is(val, null);

export const getExtraNumericInputVisibility = (extraName = '') => {
  const hiddenNumericInputNames: string[] = [EDefaultExtras.wifi];

  return !hiddenNumericInputNames.includes(convertExtraNameToEnum(extraName));
};

export const getHoldUp = (createdAt: string) => {
  const minutesFromCreated = DateTime.now().diff(DateTime.fromISO(createdAt), [
    'minutes',
  ]);
  const format = minutesFromCreated.minutes < 60 ? "m'm'" : "h'h'";

  return minutesFromCreated.toFormat(format);
};

export const getExpiresIn = (
  expirationDate?: string | null,
  isFullDayHoursWording?: boolean,
) => {
  if (!expirationDate) return '-';

  const {days, hours} = getDateTime(expirationDate).diffNow(['days', 'hours']);
  const roundedHours = Math.ceil(hours);
  if (days !== undefined && roundedHours !== undefined) {
    if (days <= 0) {
      if (roundedHours <= 0) return `-`;
      if (roundedHours <= 1) return `less 1 hour`;
      if (roundedHours > 1 && roundedHours <= 24)
        return `${roundedHours} hours`;
    }

    if (hours === 0) {
      return `${days}d`;
    }

    return `${isFullDayHoursWording ? pluralize('day', days) : `${days}d`} ${
      isFullDayHoursWording
        ? pluralize('hour', roundedHours)
        : `and ${roundedHours}h`
    }`;
  }

  return `-`;
};

export const getTimeFromRequestedToCancelled = (
  createdAt: string,
  cancelledAt: string,
) => {
  if (!createdAt || !cancelledAt) return null;
  const differenceInTime = DateTime.fromISO(cancelledAt).diff(
    DateTime.fromISO(createdAt),
    ['minutes'],
  );
  const format = differenceInTime.minutes < 60 ? "m'm'" : "h'h'";

  return differenceInTime.toFormat(format);
};

export const getTimeToCancel = (holdUp: string | null) => {
  if (!holdUp || holdUp.includes('-')) return '';

  if (holdUp.includes('h')) {
    const timeInHours = Number(holdUp.substring(0, holdUp.indexOf('h')));
    return `${pluralize('hour', timeInHours)}`;
  }

  if (holdUp.includes('m')) {
    const timeInMinutes = Number(holdUp.substring(0, holdUp.indexOf('m')));
    return `${pluralize('minute', timeInMinutes)}`;
  }

  const splitTime = String(holdUp).split(':');
  return Number(splitTime[0])
    ? `${pluralize('hour', Number(splitTime[0]))}`
    : `${pluralize('minute', Number(splitTime[1]))}`;
};

export const uniqueArrayOfObject = <T>(
  array: T[],
  keyToBeUnique: keyof T,
): T[] => {
  // Filter by looking at the next objects if the key is present a second time
  return array.filter(
    (x, xi) =>
      !array.slice(xi + 1).some((y) => y[keyToBeUnique] === x[keyToBeUnique]),
  );
};

export const convertSpaceToPlus = (str: string) => str.replace(/\s/g, '+');

export const getRespondDaysAndHours = (
  respondDay: DateTime,
): {
  showDays: string | boolean;
  showHours: string;
} => {
  if (!respondDay) return {showDays: '-', showHours: ''};

  const {days, hours} = getDateTime(String(respondDay)).diffNow([
    'days',
    'hours',
  ]);

  const showDays = !!days && pluralize('day', days);
  const showHours =
    showDays || hours >= 1
      ? pluralize('hour', Math.ceil(hours))
      : 'less 1 hour';

  return {showDays, showHours};
};

// For one extra
export const getExtraShowability = (
  extra: IExtraResponse,
  type: EBookableWith,
) => {
  const {isEnabled, bookableWith} = extra;
  const isBookableWithType = bookableWith?.includes(type);

  return !!(isEnabled && isBookableWithType);
};

// For array of extras
export const getExtrasArrayShowability = (
  extras: IExtraResponse[],
  type: EBookableWith,
) => {
  return !extras
    .map((extra) => getExtraShowability(extra, type))
    .some((element) => !element);
};

export const openNewTab = (route: string) => {
  const win = window.open(route, '_blank', 'noreferrer noopener');
  win?.focus();
};

export const getNumberFromFormatString = (
  value: string | number,
  currency: string,
): number => {
  const isString = typeof value === 'string';
  const isUSFormat = US_FORMAT_CURRENCIES.includes(currency);

  const replacedValue = isString
    ? isUSFormat
      ? (value as string).replace(/,/g, '')
      : (value as string).replace(/\./g, '').replace(/,/g, '.')
    : value;

  const valueWithoutComa = replacedValue === '.' ? '' : replacedValue;
  return Number(valueWithoutComa);
};

export const formatNumberByCurrency = (
  value: number | string,
  currentCurrency: string,
  locales = 'en-US',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
): string => {
  const options = {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  };

  const defaultNumberFormatter = new Intl.NumberFormat('de-DE', options);
  const numberFormatter = new Intl.NumberFormat(locales, options);

  const isUSFormat = US_FORMAT_CURRENCIES.includes(currentCurrency);

  const valueToNumber = getNumberFromFormatString(value, currentCurrency);

  return isUSFormat
    ? numberFormatter.format(valueToNumber)
    : defaultNumberFormatter.format(valueToNumber);
};
