import React, {memo} from 'react';
import styled from 'styled-components';

import Icon from '../Icon';

import {formatDate} from 'utils/stringUtils';
import DateUtils from 'utils/dateUtils';

interface IProps {
  checkIn: string;
  checkOut: string;
  iconMargin?: string;
}

const StyledIcon = styled(Icon)<{iconMargin?: string}>`
  margin: ${({iconMargin}) => iconMargin || '0'};
`;

function DateRangeString({checkIn, checkOut, iconMargin}: IProps) {
  const isMultiDay =
    checkIn && checkOut && !DateUtils.isSameDay(checkIn, checkOut);

  return !isMultiDay ? (
    <>{formatDate(checkIn || checkOut, 'd MMM, YYYY')}</>
  ) : (
    <>
      <span>{`${formatDate(checkIn, 'd MMM, YYYY')} `}</span>
      <StyledIcon icon='NW2_ARROW_RIGHT_THIN' iconMargin={iconMargin} />
      <span> {` ${formatDate(checkOut, 'd MMM, YYYY')}`}</span>
    </>
  );
}

export default memo(DateRangeString);
