import React from 'react';
import {Field, Form} from 'react-final-form';

import NW2NumericInput from 'view/components/NW2NumericButtonInput/NW2NumericButtonInput';
import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';

import {TSelectedOptions} from 'types/search';

import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {FlexContainer} from '../AddFoodBeverageRequest.styles';
import {offsetDef} from 'constants/styleVars';
import {MAX_EXTRAS_NUMBER, MIN_EXTRAS_NUMBER} from 'constants/app';

type TProps = {
  onSave: (arg0: Record<string, any>) => void;
  onCancel: () => void;
  participants: number;
  foodBeverageData: TSelectedOptions;
};

function EditFoodAndBeverage({
  onSave,
  onCancel,
  participants,
  foodBeverageData,
}: TProps) {
  return (
    <Form onSubmit={onSave} initialValues={foodBeverageData.data}>
      {({handleSubmit, submitting, pristine, values}) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormGroup columnNumber={1} gap={parseInt(offsetDef)}>
            {foodBeverageData.options.map(({id, name}) => {
              const isNumericInputVisible =
                values.foodAndBeverage?.includes(id);

              return (
                <FlexContainer
                  key={id}
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <NW2FormItemCheckbox
                    id={String(id)}
                    type='checkbox'
                    name='foodAndBeverage'
                    label={name}
                    value={id}
                  />
                  {isNumericInputVisible && (
                    <Field
                      name={`${id}_`}
                      initialValue={values[`${id}_`] || participants}
                    >
                      {({input}) => (
                        <NW2NumericInput
                          minValue={MIN_EXTRAS_NUMBER}
                          maxValue={MAX_EXTRAS_NUMBER}
                          initialValue={input.value}
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  )}
                </FlexContainer>
              );
            })}
          </FormGroup>

          <NMMSubmitSection
            submitLabel='save'
            handleCancel={onCancel}
            disabled={submitting || pristine}
            gap={offsetDef}
          />
        </form>
      )}
    </Form>
  );
}

export default EditFoodAndBeverage;
