import React, {useEffect} from 'react';
import {useForm} from 'react-final-form';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import UserDetails from 'view/venue/NW2BookingPreview/components/UserDetails/UserDetails';
import BillingAddressViewWithReferenceField from 'view/components/BillingAddress/BillingAddressViewWithReferenceField';
import ItemPaymentInformation from 'view/venue/components/LeftSideContainer/ItemPaymentInformation';
import NW2SpaceDetails from 'view/venue/NW2VenueDetails/components/NW2SpaceDetails/NW2SpaceDetails';
import BillingAddressForm from 'view/components/BillingAddress/BillingAddressForm';
import AboutMeetingForm from 'view/venue/NW2BookingPreview/components/AboutMeetingForm/AboutMeetingForm';

import {OfferRequestLeftSideSpaces} from './components/OfferRequestLeftSideSpaces';
import {OfferReviewLeftSideSpaces} from './components/OfferReviewLeftSideSpaces';
import {OptionDate} from './components/OptionDate/OptionDate';
import {Bedrooms} from 'view/venue/NW2BookingPreview/components/Bedrooms/Bedrooms';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EFormValidationName} from 'types/venue';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {StyledLeftSideContainer} from 'view/venue/components/LeftSideContainer/LeftSideContainer.styles';
import {IOfferRequestDay} from 'types/offer';
import {setOpenLoginPopup} from 'store/app/appSlice';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {TSearchVenuesDay} from 'types/search';
import {CancellationPolicyBlock} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import {useCustomerOrderUnits} from 'view/venue/hooks/useCustomerOrderUnits';

type TProps = {
  isOfferRequest?: boolean;
  isOfferPreview?: boolean;
  preArrivals?: TSearchVenuesDay[] | IOfferRequestDay[];
  postEvents?: TSearchVenuesDay[] | IOfferRequestDay[];
  isAlternative?: boolean;
  isShowLoginModal?: boolean;
  isMeetingRoom?: boolean;
};

export function OfferRequestLeftSidePreview({
  isOfferRequest,
  isOfferPreview,
  preArrivals,
  postEvents,
  isShowLoginModal,
  isAlternative,
}: TProps) {
  const dispatch = useAppDispatch();
  const form = useForm();

  const user = useAppSelector(({app}) => app.user);
  const billingAddresses = useAppSelector(
    ({app}) => app.user.billingAddress || [],
  );
  const offerDetails = useAppSelector(({offers}) => offers.offerDetails);
  const isRegistrationAction = useAppSelector(
    ({app}) => app.isRegistrationAction,
  );

  const {previewRooms} = useCustomerOrderUnits();

  const isAgentRole = user.role === EUserRoleCognito.ROLE_AGENT;
  const isGuestRole = user.role === EUserRoleCognito.ROLE_GUEST;

  const {venueDetails} = useVenueDetailsData();

  const accommodationType = venueDetails.accommodationType;

  useEffect(() => {
    if (isShowLoginModal && !isRegistrationAction) {
      dispatch(setOpenLoginPopup({openLoginPopup: true}));
    }
  }, [dispatch, isShowLoginModal, isRegistrationAction]);

  useEffect(() => {
    if (!previewRooms.length) return;
    form.change('orderDays', previewRooms);
  }, [form, previewRooms]);

  useEffect(() => {
    const defaultBillingAddressValues = {
      companyName: '',
      costCenter: '',
      country: '',
      city: '',
      postCode: '',
      streetHouseNumber: '',
      uuid: '',
    };
    const billingAddress: Partial<ICustomerBillingAddress> =
      user.billingAddress.find(
        (address: ICustomerBillingAddress) => address.isDefault,
      ) || defaultBillingAddressValues;

    form.batch(() => {
      form.change('firstName', user.firstName);
      form.change('lastName', user.lastName);
      form.change('email', user.email);
      form.change('phone', user.phone);

      // iterate object to set keys and values to form
      for (const key in defaultBillingAddressValues) {
        form.change(key, billingAddress[key as keyof ICustomerBillingAddress]);
      }
    });
  }, [form, user]);

  const hasBillingAddress = billingAddresses.some(
    (address: ICustomerBillingAddress) => address.isDefault,
  );

  const isMultiRooms = previewRooms.length > 1;
  const singleVenueUnit =
    !isMultiRooms && previewRooms.length ? previewRooms[0].units[0] : undefined;

  const isMeetingRoom =
    isMultiRooms || singleVenueUnit?.type === ERoomType.MEETING_ROOM;

  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;

  const hasPreArrivals = !!preArrivals?.length;
  const hasPostEvents = !!postEvents?.length;

  return (
    <StyledLeftSideContainer>
      {isOfferRequest && (
        <LeftSideItem
          item={{
            title: 'Option date',
            children: <OptionDate />,
            formValidationName: EFormValidationName.OPTION_DATE_BLOCK,
          }}
        />
      )}

      {hasPreArrivals && (
        <Bedrooms
          rooms={preArrivals}
          isOfferPreview={isOfferPreview}
          title='PRE-ARRIVAL'
        />
      )}

      {isOfferRequest && (
        <OfferRequestLeftSideSpaces
          hasPreArrivals={hasPreArrivals}
          hasPostEvents={hasPostEvents}
          isAlternative={isAlternative}
        />
      )}

      {isOfferPreview && (
        <OfferReviewLeftSideSpaces
          hasPreArrivals={hasPreArrivals}
          hasPostEvents={hasPostEvents}
          isAlternative={isAlternative}
        />
      )}

      {hasPostEvents && (
        <Bedrooms
          rooms={postEvents}
          isOfferPreview={isOfferPreview}
          title='POST-EVENT'
        />
      )}

      <LeftSideItem
        item={{
          title: 'About meeting',
          children: (
            <AboutMeetingForm
              eventName={isOfferPreview ? offerDetails.eventName : undefined}
            />
          ),
        }}
        isHidden={!isMeetingRoom || (isAgentRole && !offerDetails.eventName)}
      />

      <LeftSideItem
        item={{
          title: 'Your details',
          subtitle: isAgentRole ? '' : '(Required)',
          children: <UserDetails isShowSignInCard={isShowLoginModal} />,
          formValidationName: EFormValidationName.YOUR_DETAILS_BLOCK,
        }}
        isHidden={isGuestRole}
      />

      {isOfferPreview && (
        <>
          {/*TODO: task NWRK-2780, NWRK-2781 */}
          {/* do not remove because we may need it in some nearest future */}
          {/*<LeftSideItem*/}
          {/*  item={{*/}
          {/*    title: 'Payment Method',*/}
          {/*    children: <NW2BookingPreviewPaymentMethods />,*/}
          {/*    formValidationName: EFormValidationName.PAYMENT_BLOCK,*/}
          {/*  }}*/}
          {/*  isHidden={!isPayment}*/}
          {/*/>*/}

          <ItemPaymentInformation isHidden={isOffice} />

          <LeftSideItem
            item={{
              title: 'Billing address',
              subtitle: isAgentRole ? '' : '(Required)',
              children: hasBillingAddress ? (
                <BillingAddressViewWithReferenceField
                  isAgentRole={isAgentRole}
                />
              ) : (
                <BillingAddressForm />
              ),
              formValidationName: EFormValidationName.BILLING_ADDRESS_BLOCK,
            }}
            isHidden={isOffice}
          />

          <LeftSideItem
            item={{
              title: 'Modification & Cancellation conditions',
              children: (
                <CancellationPolicyBlock offerNumber={offerDetails.number} />
              ),
            }}
            isHidden={isOffice}
          />
        </>
      )}

      <NW2SpaceDetails isFooterHidden />
    </StyledLeftSideContainer>
  );
}
