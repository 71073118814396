// AMENITIES
import {ReactComponent as RECEPTION} from '../img/icons/amenities/concierge_service.svg';
import {ReactComponent as ELEVATOR} from '../img/icons/amenities/elevator.svg';
import {ReactComponent as RESTAURANT} from '../img/icons/amenities/breakfast.svg';
import {ReactComponent as BAR} from '../img/icons/amenities/bar.svg';
import {ReactComponent as VENDING_MACHINE} from '../img/icons/amenities/vending_machine.svg';
import {ReactComponent as OUTDOOR_TERRACE} from '../img/icons/amenities/outdoor_terrece.svg';
import {ReactComponent as PUBLIC_KITCHEN_NEAR_WORK_AREA} from '../img/icons/amenities/kitchen.svg';
import {ReactComponent as SMOKERS_LOUNGE} from '../img/icons/amenities/smoking_room.svg';
import {ReactComponent as E_CAR_CHARGING_STATION} from '../img/icons/amenities/car_charging_station.svg';
import {ReactComponent as POOL_TABLE} from '../img/icons/amenities/pool_table.svg';
import {ReactComponent as PING_PONG_TABLE} from '../img/icons/amenities/ping_pong_table.svg';
import {ReactComponent as TABLE_FOOTBALL} from '../img/icons/amenities/football_table.svg';
import {ReactComponent as LIBRARY} from '../img/icons/amenities/library.svg';
import {ReactComponent as BOARD_GAMES} from '../img/icons/amenities/board_games.svg';
import {ReactComponent as ARCADE_GAMES} from '../img/icons/amenities/arcade_games.svg';
import {ReactComponent as PHONE_BOOTH} from '../img/icons/amenities/phone_booth.svg';
import {ReactComponent as POWER_PLUG} from '../img/icons/amenities/power_plug.svg';
import {ReactComponent as WIRELESS_LAN} from '../img/icons/amenities/wifi.svg';
import {ReactComponent as ERGONOMIC_CHAIR} from '../img/icons/amenities/ergonomic_chair.svg';
import {ReactComponent as OFFICE_DESK} from '../img/icons/amenities/desk.svg';
import {ReactComponent as DOCKING_STATION} from '../img/icons/amenities/docking_station.svg';
import {ReactComponent as HEIGH_ADJUSTABLE_TABLE} from '../img/icons/amenities/desk_standing.svg';
import {ReactComponent as KEYWORD} from '../img/icons/amenities/keyboard_wired.svg';
import {ReactComponent as MOUSE} from '../img/icons/amenities/mouse.svg';
import {ReactComponent as WIRED_LAN} from '../img/icons/amenities/wired_lan.svg';
import {ReactComponent as LOUNGE_AREA} from '../img/icons/amenities/outdoor_terrece.svg';
import {ReactComponent as NEWWORK_BASICS} from '../img/icons/basic_box.svg';
import {ReactComponent as AIR_CONDITIONING} from '../img/icons/amenities/air_conditioning.svg';
import {ReactComponent as FLIP_CHART} from '../img/icons/amenities/flip_chart.svg';
import {ReactComponent as NATURAL_DAYLIGHT} from '../img/icons/amenities/natural_daylight.svg';
import {ReactComponent as OFFICE_SPEAKERPHONES} from '../img/icons/amenities/office_speakerphones.svg';
import {ReactComponent as PROJECTOR} from '../img/icons/amenities/projector.svg';
import {ReactComponent as SCREEN} from '../img/icons/amenities/screen.svg';
import {ReactComponent as WHEELCHAIR_ACCESSIBLE} from '../img/icons/amenities/wheelchair_accessible.svg';
import {ReactComponent as WHITEBOARD} from '../img/icons/amenities/whiteboard.svg';

// NAVBAR
import {ReactComponent as LOGO} from 'img/logo.svg';
import {ReactComponent as USER} from '../img/icons/navbar/user.svg';
import {ReactComponent as SEARCH} from '../img/icons/navbar/search.svg';
import {ReactComponent as MORE_HORIZONTAL} from '../img/icons/navbar/more-horizontal.svg';
import {ReactComponent as MORE_VERTICAL} from '../img/icons/navbar/more-vertical.svg';
import {ReactComponent as TABLE_LAMP} from '../img/icons/navbar/table-lamp.svg';
import {ReactComponent as BOOK_BOOKMARK} from '../img/icons/navbar/book_bookmark.svg';
import {ReactComponent as SIGN_OUT} from '../img/icons/navbar/signOut.svg';

// Sidebar
import {ReactComponent as FUTURE} from '../img/icons/future.svg';
import {ReactComponent as HISTORY} from '../img/icons/history.svg';
import {ReactComponent as USER_SIGNAL} from '../img/icons/user_signal.svg';
import {ReactComponent as CALENDAR_TODAY} from '../img/icons/calendar_today.svg';
import {ReactComponent as CALENDAR_TOMORROW} from '../img/icons/calendar_tomorrow.svg';

// PROPERTY
import {ReactComponent as BUILDING} from '../img/icons/property/building.svg';
import {ReactComponent as BUILDING_GRAY_BG} from '../img/icons/property/building_gray_bg.svg';
import {ReactComponent as BUILDING_SUCCESS} from '../img/icons/property/building_success.svg';
import {ReactComponent as WHITE_HOUSE} from '../img/icons/white_house.svg';

// KPI
import {ReactComponent as MONETIZATION_TOUCH_COIN} from '../img/icons/monetization_touch_coin.svg';
import {ReactComponent as TASK_LIST_CLOCK} from '../img/icons/task_list_clock.svg';
import {ReactComponent as BOOKING_CANCELLATION} from '../img/icons/booking_cancelations.svg';
import {ReactComponent as BOOKING_GROUPS} from '../img/icons/booking_groups.svg';

// OTHER
import {ReactComponent as ARROW_MOVE} from '../img/arrowMove.svg';
import {ReactComponent as ARROW_DOWN_WIDE} from '../img/icons/arrow_down_wide.svg';
import {ReactComponent as GO_BACK} from '../img/icons/goBack.svg';
import {ReactComponent as EMAIL} from '../img/icons/email.svg';
import {ReactComponent as DOCUMENT} from '../img/icons/document.svg';
import {ReactComponent as SHARE} from '../img/icons/share.svg';
import {ReactComponent as LEFT_ARROW_CIRCLED} from '../img/icons/left_arrow_circled.svg';
import {ReactComponent as RIGHT_ARROW_CIRCLED} from '../img/icons/right_arrow_circled.svg';
import {ReactComponent as WARN} from '../img/icons/warn.svg';
import {ReactComponent as ARRIVAL} from '../img/icons/arrival.svg';
import {ReactComponent as COVER_LABEL} from '../img/coverLabel.svg';
import {ReactComponent as DELETE} from '../img/delete.svg';
import {ReactComponent as FULLSCREEN} from '../img/fullscreen.svg';
import {ReactComponent as MAP_PIN} from '../img/icons/map-pin.svg';
import {ReactComponent as MAP_PIN_STYLED} from '../img/icons/map-pin-styled.svg';
import {ReactComponent as MAP_PIN_OUTLINE} from '../img/icons/map-pin-outline.svg';
import {ReactComponent as INFO} from '../img/icons/info.svg';
import {ReactComponent as INFORMATION_CIRCLE} from '../img/icons/information_circle.svg';
import {ReactComponent as EDIT} from '../img/icons/edit.svg';
import {ReactComponent as UPLOAD} from '../img/icons/upload.svg';
import {ReactComponent as SEND} from '../img/icons/send.svg';
import {ReactComponent as COVER_FAVOURITE} from '../img/coverFavourite.svg';
import {ReactComponent as CLOSE} from '../img/icons/close.svg';
import {ReactComponent as NOT_EXIST} from '../img/icons/not_exist.svg';
import {ReactComponent as CHECKLIST} from '../img/icons/checklist.svg';
import {ReactComponent as FAVOURITE_WORKDESK} from '../img/icons/favourite_workdesk.svg';
import {ReactComponent as WAITING_ROOM} from '../img/icons/waiting_room.svg';
import {ReactComponent as QUESTION_CIRCLED} from '../img/icons/question_circled.svg';
import {ReactComponent as FILTER} from '../img/icons/filter.svg';
import {ReactComponent as CALENDAR} from '../img/icons/calendar.svg';
import {ReactComponent as CIRCLE_ARROW_FORWARD} from '../img/icons/circle_arrow_forward.svg';
import {ReactComponent as CIRCLE_ARROW_BACKWARD} from '../img/icons/circle_arrow_backward.svg';
import {ReactComponent as MINUS_CIRCLED} from '../img/icons/minus_circled.svg';
import {ReactComponent as PLUS_CIRCLED} from '../img/icons/plus_circled.svg';
import {ReactComponent as PLUS} from '../img/icons/plus.svg';
import {ReactComponent as MINUS} from '../img/icons/minus.svg';
import {ReactComponent as LIGHTENING} from '../img/icons/ligntening.svg';
import {ReactComponent as CLOCK} from '../img/icons/clock.svg';
import {ReactComponent as TASK_LIST_CHECK} from '../img/icons/task_list_check.svg';
import {ReactComponent as TASK_LIST_DISABLED} from '../img/icons/task_list_disable.svg';
import {ReactComponent as TASK_LIST_REMOVE} from '../img/icons/task_list_remove.svg';
import {ReactComponent as TASK_LIST_PEN} from '../img/icons/task_list_pen.svg';
import {ReactComponent as EQUIPMENT} from '../img/icons/equipment.svg';
import {ReactComponent as FOOD_BEVERAGE} from '../img/icons/food_beverage.svg';
import {ReactComponent as SLASH_CIRCLED} from '../img/icons/slash_circled.svg';
import {ReactComponent as MESSAGE} from '../img/icons/message.svg';
import {ReactComponent as WATER_BOTTLE} from '../img/icons/water_bottle.svg';
import {ReactComponent as LUNCH} from '../img/icons/lunch.svg';
import {ReactComponent as COFFEE_AND_TEA} from '../img/icons/coffee_cup.svg';
import {ReactComponent as BREAKFAST} from '../img/icons/breakfast.svg';
import {ReactComponent as DRINKS} from '../img/icons/drinks.svg';
import {ReactComponent as SNACKS} from '../img/icons/snacks.svg';
import {ReactComponent as FORK_KNIFE} from '../img/icons/fork_knife.svg';
import {ReactComponent as VIDEO_CONFERENCING_EQUIPMENT} from '../img/icons/video_conference.svg';
import {ReactComponent as MICROPHONE} from '../img/icons/microphone.svg';
import {ReactComponent as PENS_AND_PAPERS} from '../img/icons/pens_paper.svg';
import {ReactComponent as PRINTER} from '../img/icons/printer.svg';
import {ReactComponent as COMPUTERS} from '../img/icons/computers.svg';
import {ReactComponent as SCANNER} from '../img/icons/scanner.svg';
import {ReactComponent as ENABLE} from '../img/icons/enable.svg';
import {ReactComponent as DISABLE} from '../img/icons/disable.svg';
import {ReactComponent as TARGET} from '../img/icons/target.svg';
import {ReactComponent as LOCATION} from '../img/icons/location.svg';
import {ReactComponent as SEARCH_MEETING_ROOM} from '../img/icons/search_meeting_room.svg';
import {ReactComponent as SEARCH_WORK_DESK} from '../img/icons/search_work_desk.svg';
import {ReactComponent as ARROW_UP} from '../img/icons/arrow_up.svg';
import {ReactComponent as ARROW_UP_FILLED} from '../img/icons/arrow_up_filled.svg';
import {ReactComponent as EYE} from '../img/icons/eye.svg';
import {ReactComponent as EYE_CLOSED} from '../img/icons/eye-closed.svg';
import {ReactComponent as POWER} from '../img/icons/power.svg';
import {ReactComponent as CARET} from '../img/icons/caret.svg';
import {ReactComponent as EXPAND} from '../img/icons/expand.svg';
import {ReactComponent as COLLAPSE} from '../img/icons/collapse.svg';
import {ReactComponent as IMAGE} from '../img/icons/image.svg';
import {ReactComponent as IMAGE_ON_BG} from '../img/icons/image_on_bg.svg';
import {ReactComponent as BED_WITH_CLOCK} from '../img/icons/bed_with_clock.svg';
import {ReactComponent as DESK_WITH_MONITOR} from '../img/icons/desk_with_monitor.svg';
import {ReactComponent as CONVERSATION} from '../img/icons/conversation.svg';
import {ReactComponent as MAP_PIN_HOUSE} from '../img/icons/map_marker_house.svg';
import {ReactComponent as WALKING} from '../img/icons/walking.svg';
import {ReactComponent as DOUBLE_ARROW_DOWN} from '../img/icons/double_arrow_down.svg';
import {ReactComponent as TRIANGLE_WARN} from '../img/icons/triangle_warn.svg';
import {ReactComponent as TRIANGLE} from '../img/icons/treangle.svg';
import {ReactComponent as PROCESSING} from '../img/icons/processing.svg';
import {ReactComponent as CHECK} from '../img/icons/check.svg';
import {ReactComponent as BANQUET} from '../img/icons/setup_banquet.svg';
import {ReactComponent as BOARDROOM} from '../img/icons/setup_block_boardroom.svg';
import {ReactComponent as CIRCLE_OF_CHAIRS} from '../img/icons/setup_circle_chairs.svg';
import {ReactComponent as CLASSROOM} from '../img/icons/setup_classroom.svg';
import {ReactComponent as STANDING_RECEPTION} from '../img/icons/setup_standing_reception.svg';
import {ReactComponent as THEATRE} from '../img/icons/setup_theatre.svg';
import {ReactComponent as U_SHAPE} from '../img/icons/setup_u_shape.svg';
import {ReactComponent as PARTICIPANTS} from '../img/icons/participants.svg';
import {ReactComponent as BULLET_LIST} from '../img/editor/icons/bullet_list.svg';
import {ReactComponent as NUMBERED_LIST} from '../img/editor/icons/numbered_list.svg';
import {ReactComponent as FORMAT_BOLD} from '../img/editor/icons/format_bold.svg';
import {ReactComponent as FORMAT_ITALIC} from '../img/editor/icons/format_italic.svg';
import {ReactComponent as FORMAT_LINK} from '../img/editor/icons/format_link.svg';
import {ReactComponent as FORMAT_STRIKETHROUGH} from '../img/editor/icons/format_strikethrough.svg';
import {ReactComponent as FORMAT_UNDERLINE} from '../img/editor/icons/format_underline.svg';
import {ReactComponent as CHEVRON_DOWN} from '../img/editor/icons/chevron_down.svg';
import {ReactComponent as PENCIL_FILL} from '../img/editor/icons/pencil-fill.svg';
import {ReactComponent as FULL_DAY_PACKAGE} from '../img/icons/packages/full_day_package.svg';
import {ReactComponent as FULL_DAY_PACKAGE_PLUS} from '../img/icons/packages/full_day_package_plus.svg';
import {ReactComponent as HALF_DAY_PACKAGE} from '../img/icons/packages/half_day_package.svg';
import {ReactComponent as SHIPMENT_UPLOAD} from '../img/icons/packages/shipment_upload.svg';
import {ReactComponent as VENUE_BUILDING} from '../img/icons/venue_building.svg';
import {ReactComponent as TALKS} from '../img/icons/talks.svg';
import {ReactComponent as CREDIT_CARDS} from '../img/icons/credit_cards.svg';
import {ReactComponent as AGREEMENT} from '../img/icons/agreement.svg';
import {ReactComponent as CRESCENT} from '../img/icons/crescent.svg';

// NW2 icons
import {ReactComponent as MONITOR} from '../img/icons/nw2/nw2_monitor.svg';
import {ReactComponent as BRIEFCASE} from '../img/icons/nw2/nw2_briefcase.svg';
import {ReactComponent as BED} from '../img/icons/nw2/nw2_bed.svg';
import {ReactComponent as COMMENT} from '../img/icons/nw2/nw2_comment.svg';
import {ReactComponent as DECLINE} from '../img/icons/nw2/nw2_decline.svg';
import {ReactComponent as CHECKED} from '../img/icons/nw2/nw2_checked.svg';
import {ReactComponent as STATUS_CANCEL} from '../img/icons/nw2/nw2_status_cancel.svg';
import {ReactComponent as WARNING} from '../img/icons/nw2/nw2_warn.svg';
import {ReactComponent as SIMPLE_CHECKED} from '../img/icons/simple_checked.svg';
import {ReactComponent as RED_CROSSED} from '../img/icons/simple_crossed.svg';
import {ReactComponent as NW2_EDIT} from '../img/icons/nw2/nw2_edit.svg';
import {ReactComponent as NW2_FILTER} from '../img/icons/nw2/nw2_filter.svg';
import {ReactComponent as NW2_SERVICES} from '../img/icons/nw2/nw2_services.svg';
import {ReactComponent as NW2_SORT} from '../img/icons/nw2/nw2_sort.svg';
import {ReactComponent as NW2_SPACES} from '../img/icons/nw2/nw2_spaces.svg';
import {ReactComponent as STOPWATCH} from '../img/icons/stopwatch.svg';
import {ReactComponent as NW2_MOVE_BACK} from '../img/icons/nw2/nw2_move_back.svg';
import {ReactComponent as NW2_MOVE_BACK_THIN} from '../img/icons/nw2/nw2_move_back_thin.svg';
import {ReactComponent as NW2_ENVELOP} from '../img/icons/nw2/nw2_envelope.svg';
import {ReactComponent as NW2_FOLDER_IMAGE} from '../img/icons/nw2/nw2_folder_image.svg';
import {ReactComponent as NW2_PIN} from '../img/icons/nw2/nw2_pin.svg';
import {ReactComponent as NW2_GROUP} from '../img/icons/nw2/nw2_group.svg';
import {ReactComponent as NW2_STAIRS} from '../img/icons/nw2/nw2_stairs.svg';
import {ReactComponent as NW2_SHOP_CART} from '../img/icons/nw2/nw2_shop_cart.svg';
import {ReactComponent as NW2_THREE_PEOPLE} from '../img/icons/nw2/nw2_three_people.svg';
import {ReactComponent as NW2_ARROW_LEFT} from '../img/icons/nw2/nw2_arrow_left.svg';
import {ReactComponent as NW2_ARROW_RIGHT} from '../img/icons/nw2/nw2_arrow_right.svg';
import {ReactComponent as NW2_LOCATION_OUTLINE} from '../img/icons/nw2/nw2_location_outline.svg';
import {ReactComponent as NW2_CALENDAR} from '../img/icons/nw2/nw2_calendar.svg';
import {ReactComponent as NW_REFRESH_ARROWS} from '../img/icons/nw2/nw2_refresh_arrows.svg';
import {ReactComponent as NW2_ARROW_CIRCLE} from '../img/icons/nw2/nw2_arrow_circle.svg';
import {ReactComponent as NW2_SEARCH} from '../img/icons/nw2/nw2_search.svg';
import {ReactComponent as NW2_SHARE} from '../img/icons/nw2/nw2_share.svg';
import {ReactComponent as NW2_SOCIAL_MAIL} from '../img/social_networks/email.svg';
import {ReactComponent as NW2_WATCH} from '../img/icons/nw2/nw2_watch.svg';
import {ReactComponent as NW2_WATCH_BOLD} from '../img/icons/nw2/nw2_watch_bold.svg';
import {ReactComponent as NW2_CLOSE} from '../img/icons/nw2/nw2_close.svg';
import {ReactComponent as NW2_CANCEL_BIN} from '../img/icons/nw2/nw2_cancel_bin.svg';
import {ReactComponent as NW2_NETWORK} from '../img/icons/nw2/nw2_network.svg';
import {ReactComponent as NW2_PHONE} from '../img/icons/nw2/nw2_phone.svg';
import {ReactComponent as NW2_PHONE_THIN} from '../img/icons/nw2/nw2_phone_thin.svg';
import {ReactComponent as NW2_EMAIL} from '../img/icons/nw2/nw2_email.svg';
import {ReactComponent as NW2_EMAIL_SYMBOL} from '../img/icons/nw2/nw2_email_symbol.svg';
import {ReactComponent as NW2_USER} from '../img/icons/nw2/nw2_user.svg';
import {ReactComponent as NW2_SOCIAL_WHATSAPP} from '../img/social_networks/whatsapp.svg';
import {ReactComponent as NW2_CREDIT_CARD} from '../img/icons/nw2/nw2_credit_card.svg';
import {ReactComponent as NW2_PLANE} from '../img/icons/nw2/nw2_plane.svg';
import {ReactComponent as NW2_PUBLIC_TRANSPORT} from '../img/icons/nw2/nw2_public_transport.svg';
import {ReactComponent as NW2_TRAIN} from '../img/icons/nw2/nw2_train.svg';
import {ReactComponent as NW2_TRADE_SHOW} from '../img/icons/nw2/nw2_trade_show.svg';
import {ReactComponent as NW2_NEAR_CITY} from '../img/icons/nw2/nw2_near_city.svg';
import {ReactComponent as NW2_SYNCHRONIZE_ARROWS} from '../img/icons/nw2/nw2_synchronize_arrows.svg';
import {ReactComponent as NW2_BOOK_HOUSE} from '../img/icons/nw2/nw2_book_house.svg';
import {ReactComponent as NW2_INFO} from '../img/icons/nw2/nw2_info.svg';
import {ReactComponent as NW2_BOOKMARK} from '../img/icons/nw2/nw2_bookmark.svg';
import {ReactComponent as NW2_WORLD} from '../img/icons/nw2/nw2_world.svg';
import {ReactComponent as NW2_LOCK} from '../img/icons/nw2/nw2_lock.svg';
import {ReactComponent as NW2_FAX} from '../img/icons/nw2/nw2_fax.svg';
import {ReactComponent as NW2_FACEBOOK} from '../img/icons/nw2/nw2_facebook.svg';
import {ReactComponent as NW2_INSTAGRAM} from '../img/icons/nw2/nw2_instagram.svg';
import {ReactComponent as NW2_TWITTER} from '../img/icons/nw2/nw2_twitter.svg';
import {ReactComponent as NW2_TICK} from '../img/icons/nw2/nw2_tick.svg';
import {ReactComponent as NW2_UPLOAD} from '../img/icons/nw2/nw2_upload.svg';
import {ReactComponent as NW2_IMAGE} from '../img/icons/nw2/nw2_image.svg';
import {ReactComponent as NW2_INFO_CIRCLE} from '../img/icons/nw2/nw2_info_circle.svg';
import {ReactComponent as NW2_CHECKMARK} from '../img/icons/nw2/nw2_checkmark.svg';
import {ReactComponent as NW2_LIGHT_BULB} from '../img/icons/nw2/nw2_light_bulb.svg';
import {ReactComponent as NW2_ARROW_RIGHT_THIN} from '../img/icons/nw2/nw2_arrow_right_thin.svg';
import {ReactComponent as NW2_ARROW_UP_THIN} from '../img/icons/nw2/nw2_arrow_up_thin.svg';
import {ReactComponent as NW2_ARROW_DOWN_THIN} from '../img/icons/nw2/nw2_arrow_down_thin.svg';
import {ReactComponent as NW2_PHONE_COUNTRY} from '../img/icons/phone_country.svg';

// Payment Logos
import {ReactComponent as AMEX} from '../img/icons/payment_logos/amex.svg';
import {ReactComponent as VISA} from '../img/icons/payment_logos/visa.svg';
import {ReactComponent as MC} from '../img/icons/payment_logos/mc.svg';
import {ReactComponent as UNIONPAY} from '../img/icons/payment_logos/unionpay.svg';

// NMM icons
import {ReactComponent as NMM_PICTURE_DOUBLE} from '../img/icons/nmm/nmm_picture_double.svg';
import {ReactComponent as NMM_CO_WORKING} from '../img/icons/nmm/nmm_co_working.svg';
import {ReactComponent as NMM_CAKE} from '../img/icons/nmm/nmm_cake.svg';
import {ReactComponent as NW2_TASK_LIST} from '../img/icons/nw2/nw2-task-list.svg';
import {ReactComponent as NMM_CHECK_GREEN} from '../img/icons/nmm/nmm_check_green.svg';
import {ReactComponent as NMM_CROSS_RED} from '../img/icons/nmm/nmm_cross_red.svg';

// Point of interests
import {ReactComponent as PLANE} from '../img/icons/nw2/nw2_plane_trip.svg';
import {ReactComponent as RAILROAD_METRO} from '../img/icons/nw2/nw2_railroad_metro.svg';

// Offer steps
import {ReactComponent as OFFER_STEP_1} from '../img/icons/offer/offer_step_1.svg';
import {ReactComponent as OFFER_STEP_2} from '../img/icons/offer/offer_step_2.svg';
import {ReactComponent as OFFER_STEP_3} from '../img/icons/offer/offer_step_3.svg';
import {ReactComponent as OFFER_STEP_4} from '../img/icons/offer/offer_step_4.svg';
import {ReactComponent as OFFER_ARROW} from '../img/icons/offer/offer_arrow.svg';
import {ReactComponent as OFFER_UNIT_COVER} from '../img/icons/offer/offer_unit_cover.svg';
import {ReactComponent as OFFER_DB} from '../img/icons/offer/offer_db.svg';
import {ReactComponent as OFFER_COMMISSION} from '../img/icons/offer/offer_commission.svg';
import {ReactComponent as OFFER_BRIEFCASE} from '../img/icons/offer/offer_briefcase.svg';
import {ReactComponent as STAR} from '../img/icons/offer/star.svg';
import {ReactComponent as HEADPHONES} from '../img/icons/offer/headphones.svg';
import {ReactComponent as OFFER_DOTTED_LINE} from '../img/icons/offer/offer_dotted_line.svg';
import {ReactComponent as FRAME_404} from '../img/icons/offer/frame_404.svg';
import {ReactComponent as TIME_CLOCK_CIRCLE} from '../img/icons/offer/time_clock_circle.svg';
import {ReactComponent as EARTH} from '../img/icons/earth.svg';

const ICON_MAP = {
  // Images
  NOT_EXIST,
  LOGO,
  NW2_WORLD,
  // Amenities
  RECEPTION,
  ELEVATOR,
  RESTAURANT,
  BAR,
  VENDING_MACHINE,
  OUTDOOR_TERRACE,
  LOUNGE_AREA,
  PUBLIC_KITCHEN_NEAR_WORK_AREA,
  SMOKERS_LOUNGE,
  E_CAR_CHARGING_STATION,
  POOL_TABLE,
  PING_PONG_TABLE,
  TABLE_FOOTBALL,
  LIBRARY,
  BOARD_GAMES,
  ARCADE_GAMES,
  PHONE_BOOTH,
  POWER_PLUG,
  WIRELESS_LAN,
  ERGONOMIC_CHAIR,
  OFFICE_DESK,
  DOCKING_STATION,
  HEIGH_ADJUSTABLE_TABLE,
  KEYWORD,
  MOUSE,
  WIRED_LAN,
  NEWWORK_BASICS,
  AIR_CONDITIONING,
  FLIP_CHART,
  NATURAL_DAYLIGHT,
  OFFICE_SPEAKERPHONES,
  PROJECTOR,
  SCREEN,
  WHEELCHAIR_ACCESSIBLE,
  WHITEBOARD,
  // end of Amenities
  // start kpi
  TASK_LIST_CLOCK,
  MONETIZATION_TOUCH_COIN,
  BOOKING_CANCELLATION,
  BOOKING_GROUPS,
  // end kpi
  DOUBLE_ARROW_DOWN,
  WAITING_ROOM,
  CHECKLIST,
  FAVOURITE_WORKDESK,
  USER,
  SEARCH,
  MORE_HORIZONTAL,
  MORE_VERTICAL,
  TABLE_LAMP,
  BOOK_BOOKMARK,
  GO_BACK,
  ARROW_MOVE,
  ARROW_UP,
  ARROW_UP_FILLED,
  ARROW_DOWN_WIDE,
  EMAIL,
  SHARE,
  DOCUMENT,
  IMAGE,
  IMAGE_ON_BG,
  CALENDAR,
  CIRCLE_ARROW_FORWARD,
  CIRCLE_ARROW_BACKWARD,
  LEFT_ARROW_CIRCLED,
  RIGHT_ARROW_CIRCLED,
  INFO,
  INFORMATION_CIRCLE,
  WARN,
  QUESTION_CIRCLED,
  MINUS_CIRCLED,
  PLUS_CIRCLED,
  MINUS,
  PLUS,
  SLASH_CIRCLED,
  ARRIVAL,
  COVER_LABEL,
  DELETE,
  FULLSCREEN,
  MAP_PIN,
  MAP_PIN_STYLED,
  MAP_PIN_OUTLINE,
  MAP_PIN_HOUSE,
  EXPAND,
  COLLAPSE,
  EDIT,
  EYE,
  EYE_CLOSED,
  FILTER,
  UPLOAD,
  SEND,
  COVER_FAVOURITE,
  LIGHTENING,
  TARGET,
  LOCATION,
  CLOCK,
  BUILDING,
  BUILDING_GRAY_BG,
  BUILDING_SUCCESS,
  WHITE_HOUSE,
  WALKING,
  CLOSE,
  FUTURE,
  HISTORY,
  CALENDAR_TODAY,
  CALENDAR_TOMORROW,
  MESSAGE,
  USER_SIGNAL,
  TASK_LIST_CHECK,
  TASK_LIST_DISABLED,
  TASK_LIST_REMOVE,
  TASK_LIST_PEN,
  EQUIPMENT,
  FOOD_BEVERAGE,
  WATER_BOTTLE,
  LUNCH,
  COFFEE_AND_TEA,
  BREAKFAST,
  DRINKS,
  SNACKS,
  FORK_KNIFE,
  VIDEO_CONFERENCING_EQUIPMENT,
  MICROPHONE,
  PENS_AND_PAPERS,
  PRINTER,
  COMPUTERS,
  SCANNER,
  ENABLE,
  DISABLE,
  SEARCH_MEETING_ROOM,
  SEARCH_WORK_DESK,
  POWER,
  SIGN_OUT,
  BED_WITH_CLOCK,
  DESK_WITH_MONITOR,
  CONVERSATION,
  TRIANGLE_WARN,
  TRIANGLE,
  PROCESSING,
  CHECK,
  PARTICIPANTS,
  BANQUET,
  BOARDROOM,
  CIRCLE_OF_CHAIRS,
  CLASSROOM,
  STANDING_RECEPTION,
  THEATRE,
  U_SHAPE,
  // NW2
  MONITOR,
  BRIEFCASE,
  BED,
  COMMENT,
  DECLINE,
  CHECKED,
  STATUS_CANCEL,
  WARNING,
  CARET,
  NW_REFRESH_ARROWS,
  SIMPLE_CHECKED,
  RED_CROSSED,
  NW2_EDIT,
  NW2_ENVELOP,
  NW2_FILTER,
  NW2_SERVICES,
  NW2_SORT,
  NW2_SPACES,
  NW2_MOVE_BACK,
  NW2_MOVE_BACK_THIN,
  NW2_FOLDER_IMAGE,
  NW2_PIN,
  NW2_GROUP,
  NW2_STAIRS,
  NW2_SHARE,
  NW2_SHOP_CART,
  NW2_CANCEL_BIN,
  NW2_NETWORK,
  NW2_THREE_PEOPLE,
  NW2_ARROW_LEFT,
  NW2_ARROW_RIGHT,
  NW2_SYNCHRONIZE_ARROWS,
  NW2_ARROW_CIRCLE,
  NW2_LOCATION_OUTLINE,
  NW2_CALENDAR,
  NW2_SEARCH,
  NW2_WATCH,
  NW2_WATCH_BOLD,
  NW2_CLOSE,
  STOPWATCH,
  NW2_EMAIL,
  NW2_PHONE,
  NW2_PHONE_THIN,
  NW2_USER,
  NW2_SOCIAL_MAIL,
  NW2_SOCIAL_WHATSAPP,
  NW2_BOOK_HOUSE,
  NW2_INFO,
  NW2_BOOKMARK,
  NW2_EMAIL_SYMBOL,
  NW2_LOCK,
  NW2_CREDIT_CARD,
  NW2_FAX,
  NW2_FACEBOOK,
  NW2_INSTAGRAM,
  NW2_TWITTER,
  NW2_PLANE,
  NW2_PUBLIC_TRANSPORT,
  NW2_TRAIN,
  NW2_TRADE_SHOW,
  NW2_NEAR_CITY,
  NW2_TICK,
  NW2_UPLOAD,
  NW2_IMAGE,
  NW2_CHECKMARK,
  NW2_LIGHT_BULB,
  NW2_PHONE_COUNTRY,
  VENUE_BUILDING,
  TALKS,
  CREDIT_CARDS,
  AGREEMENT,
  //Announcement editor
  BULLET_LIST,
  NUMBERED_LIST,
  FORMAT_ITALIC,
  FORMAT_LINK,
  FORMAT_STRIKETHROUGH,
  FORMAT_BOLD,
  FORMAT_UNDERLINE,
  CHEVRON_DOWN,
  PENCIL_FILL,
  //Packages
  FULL_DAY_PACKAGE,
  FULL_DAY_PACKAGE_PLUS,
  HALF_DAY_PACKAGE,
  SHIPMENT_UPLOAD,
  // Payment Logos
  AMEX,
  VISA,
  MC,
  UNIONPAY,
  NW2_INFO_CIRCLE,
  // Point of interests
  PLANE,
  RAILROAD_METRO,
  // NMM icons
  NMM_PICTURE_DOUBLE,
  NMM_CO_WORKING,
  NMM_CAKE,
  NW2_TASK_LIST,
  NW2_ARROW_RIGHT_THIN,
  NW2_ARROW_UP_THIN,
  NW2_ARROW_DOWN_THIN,
  OFFER_STEP_1,
  OFFER_STEP_2,
  OFFER_STEP_3,
  OFFER_STEP_4,
  OFFER_ARROW,
  OFFER_UNIT_COVER,
  OFFER_DB,
  OFFER_COMMISSION,
  OFFER_BRIEFCASE,
  STAR,
  HEADPHONES,
  NMM_CHECK_GREEN,
  NMM_CROSS_RED,
  OFFER_DOTTED_LINE,
  FRAME_404,
  TIME_CLOCK_CIRCLE,
  CRESCENT,
  EARTH,
};

export default ICON_MAP;
