import React, {ReactNode, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {FormSpy, useFormState} from 'react-final-form';

import ItemTitle from './ItemTitle';

import {EMPTY_OBJECT} from 'constants/app';
import {offsetXXLg} from 'constants/styleVars';
import {EFormValidationName} from 'types/venue';
import {ATTENDEES_FIELD_KEY} from 'constants/venue';
import {
  addPreviewDataFormError,
  removePreviewDataFormError,
  setPreviewTotalDrawerVisible,
} from 'store/bookingsCustomer/bookingsCustomerSlice';
import {useAppSelector} from 'store/hooks';

type TProps = {
  title: string | ReactNode;
  subtitle?: string;
  formValidationName?: EFormValidationName;
};

const validateYourDetailsBlock = (errors: Record<string, string>): boolean => {
  return (
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.companyName ||
    !!errors.email ||
    !!errors.phone
  );
};

const validateBillingAddressBlock = (
  errors: Record<string, string>,
): boolean => {
  return (
    !!errors.address || !!errors.postСode || !!errors.city || !!errors.country
  );
};

const validateAttendeesBlock = (errors: Record<string, string>): boolean => {
  return !!errors[ATTENDEES_FIELD_KEY];
};

const validatePaymentBlock = (): boolean => {
  return true; //!!errors.isAdyenComponentValid; // todo update with new payments
};

const validateOptionDateBlock = (errors: Record<string, string>): boolean => {
  return !!errors.optionDate;
};

const ERROR_VALIDATORS = {
  [EFormValidationName.YOUR_DETAILS_BLOCK]: validateYourDetailsBlock,
  [EFormValidationName.ATTENDEES_BLOCK]: validateAttendeesBlock,
  [EFormValidationName.BILLING_ADDRESS_BLOCK]: validateBillingAddressBlock,
  [EFormValidationName.PAYMENT_BLOCK]: validatePaymentBlock,
  [EFormValidationName.OPTION_DATE_BLOCK]: validateOptionDateBlock,
};

const ItemFormTitle = ({title, subtitle, formValidationName}: TProps) => {
  const dispatch = useDispatch();
  const formState = useFormState();

  const isDrawerShowed = useAppSelector(
    ({bookingsCustomer}) => bookingsCustomer.isTotalDrawerVisible,
  );

  // payment section validation
  const isPaymentValidation =
    EFormValidationName.PAYMENT_BLOCK === formValidationName;

  const {submitFailed, errors = EMPTY_OBJECT} = formState;
  const validateBlockError =
    !!formValidationName && ERROR_VALIDATORS[formValidationName];

  const hasValidationError =
    validateBlockError &&
    validateBlockError({
      ...errors,
    });
  const hasError = isPaymentValidation || (submitFailed && hasValidationError);

  useEffect(() => {
    /**
     * scroll to the section with an error when submitFailed fired.
     * the submitFailed fired once until error persists,
     * so we need to keep it in the store for the following handle inside
     * callback on submit button
     */
    if (typeof window !== 'undefined' && hasError && formValidationName) {
      dispatch(addPreviewDataFormError(formValidationName));

      const section = document.getElementById(formValidationName);
      const OFFSET = parseInt(offsetXXLg);

      if (section) {
        const curScroll = window.scrollY;
        window.scrollTo({
          top: section.getBoundingClientRect().top + curScroll - OFFSET,
          behavior: 'smooth',
        });
      }
    }

    return () => {
      // remove when the error has gone
      if (hasError && formValidationName) {
        dispatch(removePreviewDataFormError(formValidationName));
      }
    };
  }, [dispatch, formValidationName, hasError]);

  return (
    <>
      <ItemTitle
        id={formValidationName}
        title={title}
        subtitle={subtitle}
        hasError={hasError}
      />
      <FormSpy
        subscription={{submitFailed: true}}
        onChange={() => {
          /**
           * we need to handle drawer visibility here once separately from useEffect logic,
           * because we also have same logic on click button submit
           */
          if (isDrawerShowed) {
            dispatch(setPreviewTotalDrawerVisible(false));
          }
        }}
      />
    </>
  );
};

export default ItemFormTitle;
