import React, {useCallback, useState} from 'react';

import NW2NumericInput from 'view/components/NW2NumericButtonInput/NW2NumericButtonInput';
import InputCheckbox from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/components/InputCheckbox';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

import {
  EPriceType,
  EResourcesCode,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {IDay} from 'types/dto/IDay.type';

import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {maximumCounterNumber} from 'constants/app';
import {IPreviewExtra} from 'store/venues/types';
import {updateOrdersPreviewData} from 'store/bookingsCustomer/apiActions';
import {useAppDispatch} from 'store/hooks';
import {getExtraNumericInputVisibility} from 'utils/helpers';

import {
  ExtraItemPreview,
  ExtraItemPreviewQty,
  Name,
  Price,
  Quantity,
} from '../Space.styles';
import {FlexContainer} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddFoodBeverageRequest/AddFoodBeverageRequest.styles';
import {PRICE_TYPE_MAP} from 'constants/venue';
import {IOfferUnitExtra} from 'types/offer';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';

type TProps = {
  extra: IExtrasResponse | IOfferUnitExtra;
  chosenExtras?: IPreviewExtra[];
  currency: string;
  operationalTimes: IDay[];
  isExtraChosen?: boolean;
  isPriceHidden?: boolean;
  chosenQuantity?: number;
  dayId: number;
  unitId: number;
  isPreview?: boolean;
  checkInDate?: string;
  checkOutDate?: string;
};

const ExtraItem = ({
  extra,
  currency,
  isExtraChosen,
  chosenQuantity = 1,
  isPriceHidden,
  dayId,
  unitId,
  isPreview,
  checkOutDate,
  checkInDate,
}: TProps) => {
  const [isChecked, setIsChecked] = useState(isExtraChosen);
  const dispatch = useAppDispatch();
  const {name, price, priceType, id} = extra as IExtrasResponse;
  const isFree = (extra as IOfferUnitExtra).price?.value === 0 || price === 0;
  const isWiFi = extra.code === EResourcesCode.WIFI;
  const priceText = isFree ? (
    'Free'
  ) : (
    <>
      <PriceDisplay
        price={(extra as IOfferUnitExtra).price?.value ?? price}
        currency={currency}
      />
      {` / ${PRICE_TYPE_MAP[priceType ?? EPriceType.PER_PERSON]}`}
    </>
  );

  const onNumericInputChangeHandler = useCallback(
    (chosenQuantity: number) => {
      dispatch(
        updateOrdersPreviewData({
          dayId,
          unitId,
          extra: {...(extra as IExtrasResponse), quantity: chosenQuantity},
          checkOutDate,
          checkInDate,
        }),
      );
    },
    [dispatch, dayId, unitId, extra, checkOutDate, checkInDate],
  );

  const onCheckboxClickHandler = useCallback(() => {
    setIsChecked((prevState) => {
      if (prevState) {
        onNumericInputChangeHandler(0);
      } else {
        onNumericInputChangeHandler(chosenQuantity);
      }
      return !prevState;
    });
  }, [onNumericInputChangeHandler, chosenQuantity]);

  const isNumericInputVisible = getExtraNumericInputVisibility(name);

  return (
    <FlexContainer justifyContent='space-between'>
      {isPreview ? (
        <ExtraItemPreview>
          <ExtraItemPreviewQty>
            {!isWiFi && (
              <>
                <Quantity>
                  {'quantity' in extra
                    ? (extra as IOfferUnitExtra).quantity
                    : chosenQuantity}
                </Quantity>
                <span>x</span>
              </>
            )}
          </ExtraItemPreviewQty>
          <span>
            <Name>
              {'quantity' in extra
                ? CODE_TO_EXTRA_NAME[
                    (extra as IOfferUnitExtra).code as EResourcesCode
                  ]
                : name}
            </Name>
            {!isPriceHidden && <Price>{priceText}</Price>}
          </span>
        </ExtraItemPreview>
      ) : (
        <InputCheckbox
          id={String(id)}
          name='foodAndBeverage'
          size={ECheckboxSizes.MEDIUM}
          checked={!!isChecked}
          onClick={onCheckboxClickHandler}
          label={
            <span>
              <Name>{name}</Name>
              {!isPriceHidden && <Price>{priceText}</Price>}
            </span>
          }
          inputProps={null}
          hasError={false}
        />
      )}

      {!isPreview && isChecked && isNumericInputVisible && (
        <NW2NumericInput
          initialValue={chosenQuantity}
          minValue={1}
          maxValue={maximumCounterNumber}
          onChange={onNumericInputChangeHandler}
        />
      )}
    </FlexContainer>
  );
};

export default ExtraItem;
