import React, {ChangeEvent, useEffect, useState} from 'react';

import {
  InputWrapper,
  ChangeButton,
  AddButton,
  StyledInput,
  Label,
} from './NW2NumericButtonInput.styles';
import Icon from 'view/components/Icon/Icon';

type TProps = {
  disabled?: boolean;
  maxValue?: number;
  minValue?: number;
  initialValue?: number;
  onChange?: (val: number) => void;
  size?: 'medium' | 'large';
  hasInput?: boolean;
};

function NW2NumericButtonInput({
  initialValue = 0,
  maxValue = 99,
  minValue = 0,
  disabled = false,
  onChange,
  size = 'medium',
  hasInput = false,
}: TProps) {
  const [currentNumber, setCurrentNumber] = useState(initialValue);

  useEffect(() => {
    if (initialValue) {
      setCurrentNumber(initialValue);
    }
  }, [initialValue]);

  const incrementHandler = () => {
    setCurrentNumber(currentNumber + 1);
    if (onChange) onChange(currentNumber + 1);
  };
  const decrementHandler = () => {
    const number = currentNumber > minValue ? currentNumber - 1 : currentNumber;
    setCurrentNumber(number);
    if (onChange) onChange(number);
  };
  const addHandler = () => {
    setCurrentNumber(1);
    if (onChange) onChange(1);
  };

  const numberInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    //If statement is used to delete all numbers from input
    if (value === '') {
      setCurrentNumber(value as unknown as number);
    } else {
      const numericValue = Number(value);
      setCurrentNumber(numericValue);
    }

    if (onChange) onChange(Number(value));
  };

  const numberInputOnBlurHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(
      minValue,
      Math.min(Number(e.target.value) || minValue, maxValue),
    );

    setCurrentNumber(value);
    if (onChange) onChange(value);
  };

  const getInputLength = () => {
    const length = String(currentNumber).length || 1;
    const widthBySize = size === 'medium' ? 26 : 44;
    const proportion = size === 'medium' ? 10 : 15;

    return length === 1 || length === 2 ? widthBySize : length * proportion;
  };

  return (
    <div>
      {currentNumber || hasInput ? (
        <InputWrapper>
          <ChangeButton
            type='button'
            disabled={disabled || currentNumber === minValue}
            onClick={decrementHandler}
            size={size}
          >
            <Icon transparent icon='MINUS' />
          </ChangeButton>

          <StyledInput
            value={currentNumber}
            type='number'
            onChange={numberInputHandler}
            onBlur={numberInputOnBlurHandler}
            inputSize={size}
            inputLength={getInputLength()}
            readOnly={!hasInput || disabled}
          />

          <ChangeButton
            type='button'
            disabled={disabled || currentNumber === maxValue}
            onClick={incrementHandler}
            size={size}
          >
            <Icon transparent icon='PLUS' />
          </ChangeButton>
        </InputWrapper>
      ) : (
        <AddButton type='button' disabled={disabled} onClick={addHandler}>
          <Icon transparent icon='PLUS_CIRCLED' />
          <Label>Add</Label>
        </AddButton>
      )}
    </div>
  );
}

export default NW2NumericButtonInput;
