import React from 'react';
import styled from 'styled-components';

import TextPopover from 'view/venue/CreateVenue/pages/ContactDetails/components/ContactDetailsForm/TextPopover';
import useLegalEntityField from '../../../hooks/useLegalEntityField';

import {
  NW2FormItemInput,
  NW2FormItemPhoneNumber,
} from 'view/components/NW2FormItem/NW2FormItem';
import {
  companyNameFieldRules,
  legalPhoneFieldRules,
  requiredFieldRules,
} from 'utils/finalFormFieldRules';
import {CountryStateFormSelects} from './CountryStateFormSelects';
import {LegalEntityBlock} from './LegalEntityBlock';
import {ESupplierBusinessInfoFormFields} from 'view/components/NW2Forms/NW2SupplierLegalDetailsForm/types';

import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {NW2Gray600Color} from 'styles/configs/colors';
import {pagePadding} from 'styles/configs/offset';
import {SectionTitle} from 'view/components/NMMSection/NMMSection.styles';
import {fontSizeSm} from 'styles/configs/typography';
import {FormInnerContainer} from '../EditPaymentForm.styles';
import {INPUT_HEIGHT} from 'view/components/NW2FormItem/NW2FormItem.styles';

const StyledNW2FormItemInput = styled(NW2FormItemInput)`
  grid-column: span 2;
`;

const StyledInputNumber = styled(NW2FormItemInput)`
  min-width: 100px;
`;

export function RegisteredBusinessBlock() {
  const isLegalEntityTypeIndividual = useLegalEntityField();
  return (
    <>
      <FormGroup columnNumber={1} gap={pagePadding}>
        <SectionTitle titleMarginBottom='0' titleFontSize={fontSizeSm}>
          Registered Business
        </SectionTitle>

        <LegalEntityBlock
          isLegalEntityTypeIndividual={isLegalEntityTypeIndividual}
        />

        {!isLegalEntityTypeIndividual && (
          <FormInnerContainer>
            <NW2FormItemInput
              name={ESupplierBusinessInfoFormFields.COMPANY_NAME}
              type='text'
              label='Legal business name*'
              placeholder='Legal business name*'
              labelColor={NW2Gray600Color}
              rules={companyNameFieldRules}
            />
            <TextPopover textIndex={1} iconHeight={INPUT_HEIGHT} />
          </FormInnerContainer>
        )}

        <CountryStateFormSelects />
        <FormInnerContainer>
          <FormGroup columnNumber={3} gap={pagePadding}>
            <StyledNW2FormItemInput
              name={ESupplierBusinessInfoFormFields.STREET_NAME}
              type='text'
              label='Street name*'
              placeholder='Street name*'
              labelColor={NW2Gray600Color}
              rules={requiredFieldRules('Street name', {maxLength: 251})}
            />
            <StyledInputNumber
              name={ESupplierBusinessInfoFormFields.BUILDING_NUMBER}
              type='text'
              label='Building no.*'
              placeholder='Building no.*'
              labelColor={NW2Gray600Color}
              rules={requiredFieldRules('Building no.', {maxLength: 16})}
            />
          </FormGroup>
        </FormInnerContainer>
        <FormInnerContainer>
          <FormGroup columnNumber={3} gap={pagePadding}>
            <StyledNW2FormItemInput
              name={ESupplierBusinessInfoFormFields.CITY}
              type='text'
              label='City*'
              placeholder='City*'
              labelColor={NW2Gray600Color}
              rules={requiredFieldRules('City', {maxLength: 251})}
            />
            <StyledInputNumber
              name={ESupplierBusinessInfoFormFields.POSTAL_CODE}
              type='text'
              label='Postal Code*'
              placeholder='Postal Code*'
              labelColor={NW2Gray600Color}
              rules={requiredFieldRules('Postal Code', {maxLength: 16})}
            />
          </FormGroup>
        </FormInnerContainer>
        <FormInnerContainer>
          <NW2FormItemPhoneNumber
            type='text'
            name={ESupplierBusinessInfoFormFields.LEGAL_PHONE}
            placeholder='Telephone number*'
            label='Telephone number*'
            labelColor={NW2Gray600Color}
            rules={legalPhoneFieldRules}
          />
        </FormInnerContainer>
      </FormGroup>
    </>
  );
}
