import React from 'react';

import {
  Content,
  Section,
  Title,
  Paragraph,
  StyledExternalLink,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import {StyledSpan} from 'view/components/Typography';
import {
  fontSizeXLg,
  fontWeightBold,
  NW2FontSizeH5Xs,
} from 'constants/styleVars';
import {Routes} from 'constants/routes';

interface IProps {
  country?: string;
}

const TextContent = ({country}: IProps) => {
  const isUSLocale = country === 'United States' || country === 'Canada';

  return (
    <Content>
      <Section>
        <StyledSpan fontSize={NW2FontSizeH5Xs} fontWeight={fontWeightBold}>
          Contract of Cooperation{' '}
        </StyledSpan>
      </Section>
      <Section>
        <StyledSpan fontSize={fontSizeXLg} fontWeight={fontWeightBold}>
          HRS Contractual Terms{' '}
        </StyledSpan>
      </Section>
      <Section>
        <Title>1. Subject and formation of the agreement</Title>
        <Paragraph>
          The subject of this contract is to entrust HRS in its capacity as
          agent with the mandate to represent accommodation providers (hotels,
          co-working spaces, event locations, bed and breakfast, guest houses,
          residential hotels and similar structures) (hereinafter referred to as
          "Venue") in their capacity as principals with respect to concluding
          contracts for booking services. Without prejudice to its rights under
          the mandate, the Venue agrees to the right of its agent HRS to
          determine the terms and conditions for the operation of the HRS system
          as set out in the following. To achieve this, the Venue, along with
          prices and Venue information provided by each Venue, is entered into
          the global HRS reservation system (hereinafter also referred to as
          "HRS system" or "HRS"). The HRS system includes sales via HRS
          cooperation/sales partners and companies associated with HRS
          (“Associated Companies”). Associated Companies are those in which HRS
          or the direct or indirect shareholders/owners of HRS hold at least 50%
          of the voting rights or shares. This Agreement shall accordingly apply
          to Venue information and bookings, which HRS or the Associated
          Companies implement in the HRS-System or conduct through third party
          systems. This contract comes into force on receipt of the online
          agreement; without prejudice to the Venue's right to change the rates
          for its services via their entry into the HRS system at the time of
          the conclusion of the contract, any modification of the contract
          requires a written addendum signed by HRS and the Venue.
        </Paragraph>
      </Section>
      <Section>
        <Title>2. Release and reservation types</Title>
        <Paragraph>
          2.1. After the contract comes into force, the Venue is released into
          the HRS system. However, without prejudice to its capacity as agent
          HRS reserves the right:
        </Paragraph>
        <Paragraph>
          2.1.1. to abbreviate the Venue name for technical or other reasons.
        </Paragraph>
        <Paragraph>
          2.1.2. to delay release if the following criteria have not been
          fulfilled:
        </Paragraph>
        <Paragraph>
          2.1.2.1. Setting the standard rate (for example HRS rate) for at least
          12 months in advance
        </Paragraph>
        <Paragraph>
          2.1.2.2. Providing or uploading at least 6 high-quality photos into
          the HRS system.
        </Paragraph>
        <Paragraph>
          2.1.2.3. Entering current Venue description texts and standardised
          Venue information and features in the Venue data entry section.
        </Paragraph>
        <Paragraph>
          2.1.2.4. Providing documentation of a current commercial register
          entry, value-added tax number or the identity of the Venue.
        </Paragraph>
        <Paragraph>
          2.1.3. HRS is permitted to translate, abbreviate or modify the HRS
          standard information supplied by the Venue. Unless otherwise agreed,
          the Venue in the descriptive information is not permitted to provide
          means of contacting the Venue outside the HRS system (e.g. telephone
          number, Skype, Twitter) or the website of the Venue or a third-party.
        </Paragraph>
        <Paragraph>
          2.1.4. HRS is permitted to make inclusion or release dependent on the
          payment of a deposit as per Point 15 (payment of commission and
          transaction fee). This applies in particular to cases where payment
          difficulties have arisen in the past or where future payment
          difficulties are anticipated by the Venue.
        </Paragraph>
        <Paragraph>
          2.2. For the purpose of this contract the following reservation types
          shall be distinguished:
        </Paragraph>
        <Paragraph>
          2.2.1. Individual reservations are implemented as per this agreement
          as direct reservations without prior enquiry to the Venue,
          requests-to-book and requests-for-proposal. Bookings are carried out
          online via the HRS system.
        </Paragraph>
        <Paragraph>
          2.2.2. Meeting Room, Group (more than 9 room units per reservation)
          and Office bookings must be made as per the regulations for individual
          reservations and are carried out online via the HRS system or via the
          HRS booking channels and HRS sales partners.
        </Paragraph>
        <Paragraph>
          2.2.3. It may also be a matter of individual agreement that the Venue
          provides a certain allotment of work desks and/ or offices and meeting
          rooms in the HRS system and releases them for direct booking.
        </Paragraph>
      </Section>
      <Section>
        <Title>3. Type of reservation and duties of the Venue</Title>
        <Paragraph>
          For the purpose of this contract a{')'} instant bookings, b{')'}{' '}
          requests-to-book and c{')'} requests-for-proposal (RFP) shall be
          distinguished.
        </Paragraph>
        <Paragraph>
          3.1. For work desks, only instant bookings apply. Bookings are binding
          once finalized by the guest. The duration of the guests’ booking is
          based on an hourly calculation. Start and end time of usage are within
          the operational hours of the Venue as defined within the Venue’s
          profile.
        </Paragraph>
        <Paragraph>
          3.2. For meetings with or without bedrooms and group travel, instant
          bookings, requests-to-book and RFP apply. For each meeting room
          provided by the Venue, the Venue must determine whether it is provided
          based in instant booking or request-to-book method. For instant
          booking functionality, the bookings are binding once finalized by the
          guest. For request-to-book functionality, the Venue receives the
          customers wish to book via the HRS system and must reconfirm or
          decline that request. Bookings are binding once reconfirmed by the
          Venue.
        </Paragraph>
        <Paragraph>
          3.3. To enable instant booking and request-to-book, Venues are asked
          to provide a pricelist for meeting rooms, equipment, catering and
          additional services.
        </Paragraph>
        <Paragraph>
          3.4. In case Venues do not provide a pricing for equipment, catering
          or services, guests are enabled to send an RFP via the HRS system.
          Venue must provide missing prices and availability for the guests'
          requirements or decline in case they are not able to offer, e.g., due
          to a lack of availability. By submitting a proposal, the Venue accepts
          that the booking gets binding once the guest accepts the proposal
          within a given period.
        </Paragraph>
      </Section>
      <Section>
        <Title>4. Intermediary function</Title>
        <Paragraph>
          4.1. Contracts for the relevant services are concluded exclusively
          between the guest and the Venue. HRS acts merely as the Venue’s agent,
          and not in its own name and on its own behalf. The Venue is obliged to
          meet all the demands arising from the contract for the relevant
          services, concluded by HRS in its capacity as the Venues’ agent, and
          to provide these directly towards the paying guest.
        </Paragraph>
        <Paragraph>
          4.2. Should the guest and the Venue conclude a “Travel Contract” in
          accordance with Section 651a ff. of the German Civil Code (BGB) as a
          result of mediation by HRS, the Venue will be solely responsible for
          meeting any additional obligations associated with the fulfilment
          thereof. The Venue indemnifies HRS against all third-party
          breach-of-contract claims or claims regarding its qualifications as a
          travel operator and/or agent based on information provided by the
          Venue, including the necessary and appropriate cost of legal defence.
        </Paragraph>
      </Section>
      <Section>
        <Title>5. Cancelation Policies for bookings </Title>
        <Paragraph>
          5.1. The booker can cancel bookings (meetings, workdesks, groups) free
          of charge within one hour after booking (if start time is not within
          30 minutes).
        </Paragraph>
        <Paragraph>
          5.2. The booker can cancel workdesks free of charge until 24 hours
          before the start time.
        </Paragraph>
        <Paragraph>
          5.3. Bookers can cancel bookings, including meeting rooms, and
          accommodations, based on participant or bedroom count, as specified
          {isUSLocale ? (
            <>
              {' '}
              with each request send to the venue. Venues agree on the
              cancellation policy upon offer creation or when accepting booking
              requests.
            </>
          ) : (
            <>
              {' '}
              in our{' '}
              <a href={Routes.cancellationPolicy}>Cancellation Conditions</a>.
              The specific cancellation terms, including deadlines and potential
              fees, are determined by the number of participants or bedrooms
              reserved.
            </>
          )}
        </Paragraph>
        <Paragraph>
          5.4.{' '}
          {isUSLocale ? (
            <>
              Depending on the bookers policies, venues can upload their own
              cancellation policies as part of the offer submission.
            </>
          ) : (
            <>
              The Venue will accept cancellation without charge for force
              majeure (unforeseeable events or those that cannot be influenced,
              such as political crises, wars, strikes, natural disasters,
              terrorist attacks etc.) that makes it impossible for the guest to
              travel or that could put the life or health of the guest at risk
              or incur unreasonable travel expenses. This also applies if the
              guest was not able to inform the Venue in good time of the no-show
              and the free cancellation deadline has already ended.
            </>
          )}
        </Paragraph>
        {isUSLocale && (
          <Paragraph>
            5.5. The Venue will accept cancellation without charge for force
            majeure (unforeseeable events or those that cannot be influenced,
            such as political crises, wars, strikes, natural disasters,
            terrorist attacks etc.) that makes it impossible for the guest to
            travel or that could put the life or health of the guest at risk or
            incur unreasonable travel expenses. This also applies if the guest
            was not able to inform the Venue in good time of the no-show and the
            free cancellation deadline has already ended.
          </Paragraph>
        )}
      </Section>
      <Section>
        <Title>
          6. Updating rates, availability and Venue information in the HRS
          system
        </Title>
        <Paragraph>
          6.1. The initial Venue rates communicated by the Venues at the time of
          the conclusion of this contract and the registration of the Venue in
          the HRS system form an integral part of this contract. In the
          interests of the reservation security promised to each HRS customer,
          the Venue is obliged to regularly update its rates and availability
          and is responsible for transferring this data to HRS. The Venue bears
          the burden of proof for transmitting its blocked dates, i.e. dates
          when the Venue is not available. The Venue may only notify HRS of its
          rates and availability (including blocked dates) electronically and
          exclusively via the HRS Venue Self-Administration tool (hereinafter
          referred to as Supplier Extranet). The Venue, as a Supplier Extranet
          user, must enter the HRS rate and all future rate changes on the
          Supplier Extranet itself (to supplement the initial online agreement).
          All changes come into effect immediately (as they are displayed in the
          public HRS system) and overwrite any previous changes. If it is not
          possible to enter the data online for technical reasons, the Venue
          will still be obliged to notify HRS of its blocked dates. Such
          incidents are the only situation in which blocked dates may be
          notified by email, fax or telephone.
        </Paragraph>
        <Paragraph>
          6.2. In the event that Venues cannot update their prices, availability
          or other information electronically via the Supplier Extranet:
        </Paragraph>
        <Paragraph>
          6.2.1. the Venue shall provide updates to prices, availability or
          other information within the HRS hours of operation and shall accept
          bookings up to twelve hours after HRS receives these updates.
        </Paragraph>
        <Paragraph>
          6.2.2. the HRS rates stated by the Venue in the contract are entered
          into the HRS system by HRS.
        </Paragraph>
        <Paragraph>
          6.3. The Venue is obliged to keep all relevant Venue information, such
          as photos, videos, venue description texts and standardised venue
          information and features, up-to-date via the Venue Data Maintenance
          function.
        </Paragraph>
        <Paragraph>
          6.4. Misleading, incorrect or illegal information supplied by the
          Venue (concerning, for instance, its rating, facilities, location
          and/or description) may result in claims for compensation.
        </Paragraph>
        <Paragraph>
          6.5. The Venue is aware that the HRS system is based on electronic
          procedures that may be interfered with in spite of the security
          measures in place. For this reason, HRS will be liable for breach of
          contract only in cases of wrongful or gross negligence, in particular
          with respect to the availability and accessibility of the HRS system
          to both the Venue and the guest or the Supplier Extranet. Furthermore,
          HRS will be liable in cases of ordinary negligence only if it acts in
          breach of obligations essential to the fulfilment of this Contract
          (its cardinal duties). The Parties agree that neither of them will be
          liable for the consequences of an unavailability of the HRS system
          caused by force majeure acting on HRS (e.g. power cuts, hacker
          attacks, unexpected reservation/access volumes etc.). The Venue is and
          remains responsible for ensuring the proper functioning of its own
          systems.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          7. Distribution of offers on HRS and within third party systems
        </Title>
        <Paragraph>
          7.1. HRS is free in how it selects and displays offers; any such
          distribution shall be at HRS’ sole discretion. Venue is not entitled
          to request a certain way or level of distribution.
        </Paragraph>
        <Paragraph>
          7.2. HRS has the right, at HRS’ sole discretion, to distribute the
          offers provided by the Venue to third parties to increase the reach of
          those offerings and to make them available and bookable on third party
          systems.
        </Paragraph>
        <Paragraph>
          7.3. The use of a channel manager does not release the Venue from the
          obligation to uphold this contractual agreement. The use of
          third-party systems authorised by the HRS for the transmission of
          prices and availability to the HRS system is subject to special
          conditions:
        </Paragraph>
        <Paragraph>
          7.3.1. The selected channel manager may only update prices and
          availability on the HRS system if it has been given suitable
          authorisation by the Venue to do so.
        </Paragraph>
        <Paragraph>
          7.3.2. Venues that activate a channel manager may still manage their
          prices and availability directly using the Supplier Extranet at any
          time.
        </Paragraph>
        <Paragraph>
          7.3.3. The decision to employ a channel manager to manage its data is
          taken at the Venue’s own risk. The fact that a channel manager has
          been certified by HRS only means that the Venue may select the channel
          manager. However, it does not affect the Venue’s responsibility to
          ensure accurate, timely and complete data transfer. HRS does not
          assume any responsibility for the accurate, timely and complete
          transfer of data by the channel manager. The accuracy, timeliness and
          completeness of notifications or data transferred to HRS shall be
          determined by the time at which they reach HRS, not the point at which
          the change is made in the channel manager software. All errors or
          functional faults will be charged to the Venue. The consequences of
          any liability must be settled between the Venue and the channel
          manager. Notifications and transferable data sent by HRS to the
          channel manager are considered as having reached the Hotel the moment
          they are sent to the channel manager.
        </Paragraph>
        <Paragraph>
          7.3.4. The Venue is obliged to update all relevant venue information,
          such as photos, videos, venue description texts and standardised venue
          information and features, exclusively and directly via the hotel
          application in the HRS Supplier Extranet.
        </Paragraph>
        <Paragraph>
          7.3.5. If the venue is aware of errors or other functional faults in
          the channel manager, it is obliged to manage its prices and
          availability directly within the Supplier Extranet of HRS.
        </Paragraph>
        <Paragraph>
          7.3.6. The Venue may deactivate an authorised channel manager at any
          time to either update prices and availability directly using the
          Supplier Extranet or use another certified channel manager.
        </Paragraph>
      </Section>
      <Paragraph>
        7.3.7. The Venue is obliged not to divulge to third parties, especially
        channel managers, the HRS password it uses for hotel applications on the
        HRS Venue portal as well as for logging on to the Supplier Extranet.
      </Paragraph>
      <Paragraph>
        7.3.8. The use of a channel manager does not exonerate the hotel from
        responsibility for complying with the contractually agreed conditions
        and obligations.
      </Paragraph>
      <Paragraph>
        7.3.9. HRS is free to decide to exclude the use of particular channel
        managers with immediate effect, especially if the channel manager
        affects the stability of the HRS system.
      </Paragraph>
      <Section>
        <Title>
          8. Venue photos and descriptions, use of Venue information for
          marketing
        </Title>
        <Paragraph>
          Venue photos may be uploaded or replaced free of charge at any time at{' '}
          <StyledExternalLink
            href='https://newwork.hrs.com/venue/profile'
            target='_blank'
            rel='noreferrer noopener'
          >
            https://newwork.hrs.com/venue/profile
          </StyledExternalLink>
          . HRS may download photos, graphics and logos free of charge from the
          Venue’s web pages for use on the HRS system and/or to run related
          marketing campaigns. The Venue guarantees that photos, graphics, logos
          supplied to HRS and downloaded files are not covered by third-party
          copyright which could limit or prevent their unrestricted use by HRS.
          HRS may at any time reject photos that do not meet the HRS standard.
          HRS is permitted to supplement the Venue’s photo and video content
          with its own photos and videos, those of third parties and those of
          third parties produced on behalf of HRS. The Venue further agrees to
          production of material (such as photos or videos) about the Venue by
          HRS and/or third parties on behalf of HRS and to publishing such
          material in any known kinds of use at HRS’ discretion at no cost for
          the Venue. The Venue undertakes to allow HRS and its subcontractors
          access to its facilities in order to produce the material. The Venue
          confirms to be aware, that the material will be published only upon
          review of the quality and approval by the HRS-Photo team and that the
          material must not be used for the Venue-owned website nor for
          reference reasons. The Venue also agrees that HRS is entitled to stop
          publishing the material at any time at HRS’ sole discretion, so that
          the Venue is not entitled to request use of the material in the HRS
          System. The Venue may mention that it is connected to the worldwide
          HRS system in its brochures, Venue guides and other publications as
          well as on its web pages. To this end, HRS provides logos for the
          Venue to use free of charge. These can be downloaded at www.hrs.com{' '}
          {'>'} About HRS{'>'} Press. Furthermore, HRS may use the information
          provided by the Venue (especially the name, brands, etc.) for
          marketing purposes.
        </Paragraph>
      </Section>
      <Section>
        <Title>9. Total-price guarantee</Title>
        <Paragraph>
          The Venue guarantees that prices entered are final prices (including
          all taxes and charges) and that all information about taxes and other
          charges provided by the Venue on the HRS system are complete and
          correct. Misleading, incorrect or illegal information supplied by the
          Venue with regard to prices, taxes or other charges may result in
          claims for compensation from the HRS. If a price entered by the Venue
          in the HRS system is not correct but has already been booked by the
          guest, the Venue is obliged to accept this price. A subsequent price
          increase after a booking has been made is not permissible. The HRS
          customer cannot be charged under any circumstances more than the price
          confirmed in the HRS websites.
        </Paragraph>
      </Section>
      <Section>
        <Title>10. Quality guarantee for work desks</Title>
        <Paragraph>
          For each booking, the basic features of a typical work desk or private
          office are agreed to include an office desk, ergonomic chair, an
          internet connection of at least 16 Mbit/s and power plugs. Booking at
          the HRS rate is under no circumstances associated with any lower
          quality for the desk or office booked via HRS in terms of their size,
          features, location or service. HRS customers (guests) are exclusively
          to be provided with desks that have the features stated by the Venue.
        </Paragraph>
      </Section>
      <Section>
        <Title>11. Guarantee of performance</Title>
        <Paragraph>
          The Venue guarantees, including in the relationship with the guest, to
          accept the bookings made via the HRS system. The guest is guaranteed
          to be provided with the facilities at the agreed terms and with the
          features stated in the contract. This obligation exists in particular
          even if the Venue has failed to inform HRS that the relevant periods
          are blocked or to block them in the HRS system. Rebooking in other
          Venues in lower quality categories are not permitted. If a rebooking
          is made or the Venue rejects the alternative arrangements, HRS must be
          informed of this without delay. Additional costs incurred due to
          complaints about the Venue’s quality or at prices not covered in the
          contract or not currently agreed or resulting from
          rebooking/over-booking are to be reimbursed to the guest directly by
          the Venue. If HRS provides services to the customer in advance, the
          additional costs incurred will be invoiced by HRS to the Venue. Under
          no circumstances does this affect the HRS’ claim to commission or
          transaction fee.
        </Paragraph>
      </Section>
      <Section>
        <Title>12. Duty to inform</Title>
        <Paragraph>
          Planned renovation work during ongoing Venue operations, construction
          measures in the immediate vicinity, closing the services (e.g. lounge
          areas) and changes to owners, lessees, tenants or operators or the
          start of insolvency proceedings or liquidation must be notified to HRS
          without delay.
        </Paragraph>
      </Section>
      <Section>
        <Title>13. Venue evaluation</Title>
        <Paragraph>
          13.1. The Venue agrees that the evaluations by HRS guests in the HRS
          system can be shown using both points and free text. There is no right
          to publish all evaluations. HRS in particular reserves the right if
          there is suspected abuse or the intention of deliberate damage to
          remove or not to publish evaluations but is not obliged for technical
          reasons to check the evaluation before publication in the HRS system.
        </Paragraph>
        <Paragraph>
          13.2. HRS is also not obliged to check the truthfulness of the
          evaluations but will ask the guests to provide truthful and legal
          evaluations.
        </Paragraph>
        <Paragraph>
          13.3. If the Venue is of the opinion that an evaluation using free
          text includes untruthful facts or inappropriate opinions, the Venue is
          permitted, whilst excluding additional rights, to send a comment on
          the guest evaluation to HRS (hereinafter referred to as "Venue
          comment"). If the Venue claims that facts are untruthful, the Venue is
          obliged to provide HRS with appropriate evidence (for example photos
          or witness statements). The burden of proof for the untruthfulness of
          the claims made about the facts in an evaluation is with the Venue.
          HRS will assess the documents submitted as it sees fit and revise or
          remove obviously untruthful claims.
        </Paragraph>
        <Paragraph>
          13.4. Venue comments are also subject to checking by HRS. There is no
          right of publication. In particular if the content of this contract is
          contradictory, if there is suspected abuse, an intention to cause
          damage or infringement against data protection or personality rights,
          HRS reserves the right to remove or not to publish Venue comments.
          Venue comments that are to be published are shown in the HRS system
          within the field provided.
        </Paragraph>
      </Section>
      <Section>
        <Title>14. HRS commission, transaction fees and guest rights</Title>

        {isUSLocale ? (
          <Paragraph>
            14.1. HRS receives a commission as a fee for each realized booking
            made.
          </Paragraph>
        ) : (
          <>
            <Paragraph>
              14.1. HRS receives a commission as a fee for each realized booking
              made totalling:
            </Paragraph>
            <Paragraph>14.1.1. 15% on work desk reservations.</Paragraph>
            <Paragraph>14.1.2. 13% on group reservations.</Paragraph>
            <Paragraph>14.1.3. 10% on event/meeting reservations.</Paragraph>
            <Paragraph>
              14.1.4. 3% for bookings made through individually available
              widgets and separately agreed surcharges, if any (for example “top
              rankings”).
            </Paragraph>
          </>
        )}
        {isUSLocale ? (
          <Paragraph>
            14.2. The commission percentage as well as the services the
            commission is calculated on, is specified with each request send to
            the venue. Venues agree on the commission upon offer creation or
            when accepting booking requests.
          </Paragraph>
        ) : (
          <>
            <Paragraph>
              14.2. The basis for calculating the commission is the final price
              paid by the customer including all taxes and charges. The
              value-added tax on which the calculation is based must be notified
              to HRS via the cooperation agreement. If a guest shortens or
              extends the duration of the booking made via the HRS system, the
              HRS commission is always based on what the guest had to pay, i.e.
              in particular any cancellation charges.
            </Paragraph>
            <Paragraph>
              14.3. For all work desk, event and group reservations the
              commission is applied to the final price to be paid by the
              customer including all taxes and charges and including the revenue
              for pre-ordered food and beverages, room rental, equipment,
              provision costs, meeting flat-rates and other flat-rate fees.
            </Paragraph>
            <Paragraph>
              14.4. The claim to commissions extends to the following cases:
            </Paragraph>
            <Paragraph>
              14.4.1. For invoiced no-shows no matter whether and when the guest
              pays for the booking.
            </Paragraph>
            <Paragraph>
              14.4.2. The price is paid in full or in part by HRS or a
              third-party directly to the Venue, e.g. as goodwill to the guest.
            </Paragraph>
          </>
        )}
      </Section>
      <Section>
        <Title>15. Payment of the commission and the transaction fee</Title>
        <Paragraph>
          15.1. The HRS commission and the transaction fee are to be paid
          exclusively to HRS and under no circumstances to a third-party. The
          commission and transaction fee are settled on a monthly basis. HRS
          shall have the right to switch to biweekly invoicing. In case HRS
          wishes to switch to biweekly invoicing, HRS shall inform the Venue in
          writing or via e-mail with four weeks advance notice of such switch.
        </Paragraph>
        <Paragraph>
          15.2. The commission and the transaction fee amounts are subject to
          value-added tax at the legally applicable rate, where due. The
          commission and/or the transaction fee claim may not be offset or
          retained unless the asserted counter-claim is legally binding or
          undisputed.
        </Paragraph>
        <Paragraph>
          15.3. Payments are due for payment immediately and must be paid within
          14 days. Corrections are possible via the online booking overview up
          until the date of invoicing (www.HRS.de/hotelservice). Corrections by
          fax or email must be notified to HRS within 7 days of receiving the
          invoice. After the end of this period the invoiced is considered to
          have been irrevocably approved.
        </Paragraph>
        <Paragraph>
          15.4. Reminder fees are charged after an unsuccessful payment reminder
          at EUR 20.00. In addition, 12% late payment charges per year are added
          to the unpaid invoice amount.
        </Paragraph>
        <Paragraph>
          15.5. Bank fees and other costs of financial transfers incurred in
          connection with paying the commission and/or transaction fee invoice
          are charged to the Venue.
        </Paragraph>
        <Paragraph>
          15.6. Collection and legal costs incurred by HRS are charged on to the
          Venue.
        </Paragraph>
        <Paragraph>
          15.7. In many countries HRS offers the payment option using the
          electronic direct debit procedure. The Venue authorises HRS in writing
          to collect all invoices incurred 20-25 days after the invoicing date
          from the Hotel’s account. The Venue is entitled to contradict the
          collection within 6 weeks of the charge by HRS in writing. Costs for
          rebooking for which HRS is not responsible will be invoiced to the
          Venue with a flat-rate of EUR 10.00.
        </Paragraph>
        <Paragraph>
          15.8. Participation in the electronic direct debit procedure is
          compulsory for venues in all countries where the electronic direct
          debit procedure exists. As an alternative HRS offers the option of
          payment by debiting a credit card.
        </Paragraph>
        <Paragraph>
          15.9. For countries that do not have an electronic direct debit
          procedure, HRS offers payment by bank transfer to an account
          stipulated by HRS.
        </Paragraph>
        <Paragraph>
          15.10. On request by HRS the Venue is obliged to pay a deposit
          totalling EUR 500.00. Depending on the average monthly fee the deposit
          may be increased at the request of HRS. HRS will in particular request
          payment of a deposit if payment difficulties have occurred in the past
          or are expected in the future. HRS is entitled to offset outstanding
          commission payments against this deposit and to request that the
          deposit is replaced up to its original level. The level of the
          security is under no circumstances restricted or limited to the
          Venue’s liability from this contract. HRS is not obliged to invest the
          deposit in an interest-bearing account. If this contract ends HRS will
          reimburse the Venue for any remaining deposit.
        </Paragraph>
      </Section>
      <Section>
        <Paragraph>
          16. Each Venue authorizes HRS support personnel to use the Venue
          services as needed for purposes of testing and operational support,
          and HRS may grant HRS Vouchers to its employees in connection
          therewith. Any use of the spaces and services by HRS support personnel
          as needed for purposes of testing and operational support shall
          require approval of the Venue, shall be subject to availability, and
          shall be at no charge. The Venue shall not refuse approval without
          legitimate reasons.
        </Paragraph>
      </Section>
      <Section>
        <Title>
          17. Legal consequences for infringements against the Venue’s duties
        </Title>
        <Paragraph>
          17.1. The Venue’s duties under this contract exist directly in favour
          of HRS. The Venue is therefore obliged to reimburse HRS for any damage
          incurred by infringing the Venue´s duties under this contract.
          However, the Venue is also obliged to reimburse HRS for costs and
          expenses incurred by HRS in connection with a duty infringement by the
          Venue. In addition, the Venue’s duties arising from the Venue’s
          information and booking, by way of a real contract in favour of a
          third-party, also exist in favour of the relevant guest.
        </Paragraph>
        <Paragraph>
          17.2. To be specific this applies in particular as follows:
        </Paragraph>
        <Paragraph>
          17.2.1. After checking the duty infringed by the Venue, HRS is
          entitled (notwithstanding HRS’ own claims) to fulfil the guest’s
          claims in place of the Venue and to itself assert claims against the
          Venue. The Venue is obliged to reimburse HRS for costs incurred by HRS
          in order to fulfil the guests’ claims, in particular any additional
          costs for alternative offerings for bookings rejected by the Venue and
          such additional costs as telephone calls or taxis.
        </Paragraph>
        <Paragraph>
          17.2.2. The guest has a separate claim against the Venue from the
          Venue’s infringement of duties associated with information provided by
          the Venue. Additional costs incurred due to complaints about the
          Venue’s quality or prices not covered in the contract or not currently
          agreed or resulting from rebooking/over-booking, are to be reimbursed
          to the guest locally and directly by the Venue. Under no circumstances
          does this affect the HRS claim to commission or transaction fee.
        </Paragraph>
        <Paragraph>
          17.2.3. Notwithstanding the provisions stated above the Venue will
          indemnify HRS for all claims by third parties and associated costs
          (such as its appropriate own legal costs) that result from information
          that the Venue provided to HRS.
        </Paragraph>
        <Paragraph>
          17.2.4. If a guest is rejected on the basis of the booking being made
          using information from the Venue, the Venue is also obliged to provide
          a written declaration to the guest that clarifies that HRS is not
          responsible for the improper services provided by the Venue.
        </Paragraph>
        <Paragraph>
          17.3. The claims of HRS can be asserted as part of the commission
          invoice. HRS will charge the Venue an administrative fee of at present
          EUR 30,-- for each complaint received and therefore deduct those fees
          from the pay-out.
        </Paragraph>
      </Section>
      <Section>
        <Title>18. Booking block</Title>
        <Paragraph>
          The following circumstances authorise the HRS to directly and
          temporarily block the Venue from receiving any additional bookings:
        </Paragraph>
        <Paragraph>
          18.1. The Venue rejects a proper booking made via the HRS system.
        </Paragraph>
        <Paragraph>
          18.2. There is a dispute about the receipt of a properly carried out
          booking, or where the booked service is refused in spite of a booking
          that was carried out properly.
        </Paragraph>
        <Paragraph>
          18.3. The guest is given the booked service of lower quality.
        </Paragraph>
        <Paragraph>
          18.4. In spite of a properly implemented booking the guest is
          accommodated in another Venue.
        </Paragraph>
        <Paragraph>
          18.5. The guest is requested to pay a higher rate than the one booked
          and confirmed via HRS.
        </Paragraph>
        <Paragraph>
          18.6. The booked service is charged even though cancellation was
          received in time or the guest’s credit card is subject to unauthorised
          charges.
        </Paragraph>
        <Paragraph>
          18.7. Rejection / cancellation of a booking due to incorrect credit
          card data whilst infringing Number 3 of this contract.
        </Paragraph>
        <Paragraph>
          18.8. Late payment of the commission or transaction fee, unjustified
          reduction in the commission or transaction fee invoice or refusal to
          pay the deposit.
        </Paragraph>
        <Paragraph>
          18.9. The offer or behaviour of the Venue infringes statutory
          provisions.
        </Paragraph>
        <Paragraph>
          18.10. Other behaviour that could damage HRS’ business or reputation
          or is uncooperative.
        </Paragraph>
        <Paragraph>
          18.11. Not granting the free services guaranteed to the HRS guest.
        </Paragraph>
        <Paragraph>
          18.12. Unusual strong occurrence of negative Venue evaluations or
          complaints by HRS guests.
        </Paragraph>
        <Paragraph>
          18.13. Inappropriate or out-of-date information in the Venue
          description, features or images.
        </Paragraph>
        <Paragraph>18.14. In case of legal disputes.</Paragraph>
        <Paragraph>
          After blocking the Venue, HRS reserves the right to only release the
          Venue for bookings again once all overdue commissions, transaction
          fees or other claims have been paid. In specific cases HRS reserves
          the right to only release the Venue after payment of a deposit for
          additional bookings.
        </Paragraph>
      </Section>
      <Section>
        <Title>19. Duration of the contract and termination</Title>
        <Paragraph>
          This contract is not restricted to a particular period of time. It may
          be terminated by either party in writing without stating a specific
          reason with a 30-day notice period. The right to extraordinary
          cancellation for a justifiable cause remains unaffected. In all cases
          of termination, the Venue is obliged to handle all bookings carried
          out via HRS but not yet completed until the contract ends as per the
          conditions agreed here. Claims for fees remain unaffected by the
          termination.
        </Paragraph>
      </Section>
      <Section>
        <Title>20. Termination by HRS without notice</Title>
        <Paragraph>
          Specially where the quality of the Venue worsens significantly or
          where one or more of the facts stated under the booking block section
          occur, the HRS is entitled to terminate the contract for extraordinary
          reasons without complying with a notice period.
        </Paragraph>
      </Section>
      <Section>
        <Title>
          21. Transfer of the contract as a whole and changes to these
          contractual terms
        </Title>
        <Paragraph>
          21.1. HRS has the right to transfer this contract to Associated
          Companies (as defined above in Number 1).
        </Paragraph>
        <Paragraph>
          21.2. This contract may be modified by HRS as follows: HRS will inform
          the Venue about intended changes and the change date by email or fax
          with a notice period of at least six (6) weeks. The approval of the
          Venue to the change is considered to have been issued automatically if
          the Venue still enters or makes available work desks, offices and
          related services with HRS after the change date. Reference is to be
          made to this in the notification. If the Venue makes work desks,
          offices and related services available to HRS after the change date,
          the modified version applies from the change date onwards. If the
          Venue contradicts the change within four (4) weeks of receiving the
          notification of the change, this is considered to be normal
          termination of the contract with effect from the change date such that
          the Venue offerings can no longer be booked after the change date.
        </Paragraph>
      </Section>
      <Section>
        <Title>22. Severability clause</Title>
        <Paragraph>
          The ineffectiveness of a provision in the contract does not affect the
          effectiveness of the other contractual provisions. Rather the other
          provisions continue to apply and the ineffective provision is to be
          replaced if legally permissible by a provision that comes as close as
          possible to the economic purpose of the ineffective provision.
        </Paragraph>
      </Section>
      <Section>
        <Title>23. Translated versions</Title>
        <Paragraph>
          Versions translated into other languages are used to improve
          comprehension. In cases of dispute about the content or interpretation
          of these general terms and conditions, contradictions or discrepancies
          between the English language version and one in another language, the
          English language version is binding and conclusive.
        </Paragraph>
      </Section>
      <Section>
        <Title>24. Jurisdiction</Title>
        <Paragraph>
          This contract shall be governed exclusively by German law, to the
          exclusion of private international law. The exclusive court of
          jurisdiction and place of fulfilment is Cologne, Germany. HRS is
          alternatively permitted to select the head office of the Venue as the
          court of jurisdiction and place of fulfilment.
        </Paragraph>
      </Section>
    </Content>
  );
};

export default TextContent;
