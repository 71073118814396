import React from 'react';

import {RegisteredBusinessBlock} from './components/RegisteredBusinessBlock';
import {LegalInformationBlock} from './components/LegalInformationBlock';
import {OperatingExecutivesBlock} from './components/OperatingExecutivesBlock';
import {EVenueProfilePaymentSections} from 'view/venue/NW2VenueProfile/types';

import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {offsetXLg, offsetXXLg, pagePadding} from 'styles/configs/offset';
import {
  Section,
  SectionTitle,
} from 'view/components/NMMSection/NMMSection.styles';
import {
  SECTION_MARGIN,
  SECTION_PADDING,
} from 'view/venue/NW2VenueProfile/components/NMMPayout/FormSection';

export function EditPaymentForm() {
  return (
    <>
      <Section
        sectionMargin={SECTION_MARGIN}
        sectionPadding={`0 0 ${offsetXXLg} 0`}
      >
        <FormGroup
          columnNumber={1}
          columnNumberXLg={2}
          gap={pagePadding}
          alignItems='start'
        >
          <RegisteredBusinessBlock />
          <LegalInformationBlock />
        </FormGroup>
      </Section>
      <Section
        sectionMargin={SECTION_MARGIN}
        sectionPadding={SECTION_PADDING}
        id={EVenueProfilePaymentSections.OPERATING_EXECUTIVES}
      >
        <SectionTitle titleMarginBottom={offsetXLg}>
          {EVenueProfilePaymentSections.OPERATING_EXECUTIVES}
        </SectionTitle>
        <OperatingExecutivesBlock />
      </Section>
    </>
  );
}
