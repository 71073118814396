import React, {ReactNode} from 'react';

import {InputContainer, InputText, Label} from './InputBlock.styles';

type TProps = {
  htmlFor: string;
  color?: string;
  labelText: string | ReactNode | null;
  children: ReactNode;
  isFocused: boolean;
  isHidden?: boolean;
  isNoBorder?: boolean;
  isNoMargin?: boolean;
  isNoPadding?: boolean;
  isWidthAuto?: boolean;
  positionRelative?: boolean;
  flex?: number;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  noWrap?: boolean;
  onClick?: () => void;
};

const InputBlock = ({
  htmlFor,
  color,
  labelText,
  children,
  isFocused,
  isHidden,
  isNoBorder,
  isNoPadding,
  isNoMargin,
  isWidthAuto,
  positionRelative,
  alignItems,
  noWrap,
  onClick,
  flex = 1,
}: TProps) => {
  if (isHidden) return null;
  return (
    <InputContainer
      isNoBorder={isNoBorder}
      isNoPadding={isNoPadding}
      isWidthAuto={isWidthAuto}
      isNoMargin={isNoMargin}
      positionRelative={positionRelative}
      alignItems={alignItems}
      flex={flex}
      onClick={onClick}
    >
      <Label htmlFor={htmlFor} color={color} isFocused={isFocused}>
        {labelText && <span>{labelText}</span>}
      </Label>
      <InputText noWrap={noWrap} isFocused={isFocused}>
        {children}
      </InputText>
    </InputContainer>
  );
};

export default InputBlock;
