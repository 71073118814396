import {setAttendees} from 'view/venue/NW2BookingPreview/helpers';
import {ERoomType} from 'types/dto/ERoomType.type';
import queryString from 'query-string';
import {EMPTY_OBJECT} from 'constants/app';
import {TStoredCard} from 'store/payment/types';
import {
  IBookingPreviewFormData,
  IPreviewExtra,
  TAttendeeFormField,
} from 'store/venues/types';
import {TSelectedPackageItem} from 'types/dto/IPackages.type';
import {
  IPreviewUnitResponse,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {ATTENDEE_FIELD_KEY} from 'constants/venue';
import {IEditFormState} from 'view/venue/NW2BookingEdit/helpers';
import {IOfferUnitExtra} from 'types/offer';
import {IExtraResponse} from 'types/dto/IPublicVenue';

const setAttendeesForBooking = (attendees: Record<string, string>[]) =>
  attendees.reduce(
    (
      prev: Record<string, string>,
      curr: Record<string, string>,
      index: number,
    ) => ({
      ...prev,
      [`${ATTENDEE_FIELD_KEY}${index}`]: curr[`${ATTENDEE_FIELD_KEY}`],
    }),
    {},
  );

export const getFormData = (formValues: string): Record<string, any> => {
  const {bookedUnits, attendees, ...rest} = JSON.parse(formValues);
  return {
    user: {
      firstName: rest.firstName,
      lastName: rest.lastName,
      email: rest.email,
      phone: rest.phone,
      companyName: rest.companyName,
    },
    billingAddress: {
      additionalReference: rest.additionalReference,
      city: rest.city,
      companyName: rest.companyName,
      costCenter: rest.costCenter,
      country: rest.country,
      postCode: rest.postCode,
      streetHouseNumber: rest.streetHouseNumber,
    },
    bookedUnits,
    attendees,
    ...(attendees?.length ? setAttendeesForBooking(attendees) : {}),
    participants: rest.participants,
  };
};

type TPaymentDataPayload = {
  formData: IBookingPreviewFormData;
  unitIDs?: string;
  previewUnits?: TGroupedUnitsByDay[];
  paymentsRequest?: null | Record<string, any>;
};

type TPaymentBookingDataPayload = {
  userId?: number;
  venueID: number;
  isOffice: boolean;
} & TPaymentDataPayload;

type TPaymentOfferDataPayload = {
  offerId: string;
  formData: IBookingPreviewFormData;
};

type TEditPaymentDataPayload = {
  userId?: number;
  venueID: number;
  formData: IEditFormState;
} & Omit<TPaymentDataPayload, 'formData'>;

const setExtrasForBooking = (
  extrasList: (IPreviewExtra | IOfferUnitExtra | IExtraResponse)[],
) => {
  return extrasList.map((extra) => ({
    accommodationExtraId:
      extra.accommodationExtraId || (extra as IOfferUnitExtra).extraId,
    bookedQuantity:
      (extra as IPreviewExtra).chosenQuantity ||
      (extra as IPreviewExtra).bookedQuantity ||
      (extra as IPreviewExtra).quantity,
  }));
};

const getBillingAddressDto = <T extends Record<string, any>>(formData: T) => {
  const {
    companyName,
    streetHouseNumber,
    postCode,
    city,
    country,
    costCenter,
    additionalReference,
    uuid = '',
    customerId,
  } = formData;

  return {
    additionalReference,
    city,
    companyName,
    costCenter,
    country,
    postCode,
    streetHouseNumber,
    uuid,
    customerId,
  };
};

const setUnit =
  (
    attendees: TAttendeeFormField[],
    meetingRoomCapacity: string | (string | null)[] | null,
    customerSelectedPackages: TSelectedPackageItem[] | undefined,
  ) =>
  (unit: any) => {
    const packageItem = customerSelectedPackages?.find(
      ([id]) => id === unit.unitId,
    );
    const packageId = packageItem && packageItem[1] ? packageItem[1].id : null;

    return {
      packageId,
      unitId: unit.unitId,
      unitQuantity: 1,
      checkInDate: unit.checkInDate,
      checkOutDate: unit.checkOutDate,
      attendees: setAttendees(attendees),
      ...(unit.roomType === ERoomType.MEETING_ROOM ||
      unit.unitType === ERoomType.MEETING_ROOM
        ? {
            setupStyle:
              unit.chosenSetupStyle ||
              unit.setupStyle ||
              unit.requestedSetupStyle,
          }
        : {}),
      bookedExtras: setExtrasForBooking(unit.chosenExtras || unit.bookedExtras),
      participants:
        unit.roomType === ERoomType.MEETING_ROOM ||
        unit.unitType === ERoomType.MEETING_ROOM
          ? Number(
              unit.requestedCapacity ||
                unit.unitFilter?.capacity ||
                meetingRoomCapacity ||
                unit.participants,
            )
          : null,
      ...(unit.unitBookingId ? {unitBookingId: unit.unitBookingId} : {}),
    };
  };

export const getPaymentDataPayload = ({
  userId,
  formData,
  isOffice,
  paymentsRequest = null,
  venueID,
}: TPaymentBookingDataPayload) => {
  const {meetingRoomCapacity} =
    queryString.parse(window.location.search.slice(1)) || EMPTY_OBJECT;

  const {
    // user data
    firstName,
    lastName,
    email,
    phone,
    // end of user data
    attendees,
    orderDays,
    aboutMeeting,
  } = formData;

  const billingAddressDto = isOffice
    ? null
    : getBillingAddressDto<IBookingPreviewFormData>(formData);

  const unitBookingDtos = orderDays.flatMap(({units, selectedPackage}) => {
    return units.map(setUnit(attendees, meetingRoomCapacity, selectedPackage));
  });

  const orderDaysGrouped = orderDays.map(
    ({
      units,
      selectedPackage,
      participants,
      checkInDate,
      foodAndBeverage,
      eventType,
    }) => {
      const unitBookings = units.map(
        setUnit(attendees, meetingRoomCapacity, selectedPackage),
      );

      return {
        date: checkInDate,
        unitBookings,
        eventType,
        participants,
        foodAndBeverage: setExtrasForBooking(
          foodAndBeverage as (
            | IPreviewExtra
            | IOfferUnitExtra
            | IExtraResponse
          )[],
        ),
      };
    },
  );

  return {
    orderDays: orderDaysGrouped,
    unitBookingDtos,
    firstName,
    lastName,
    email,
    phone,
    ...(userId ? {userId} : {}),
    billingAddressDto: {
      ...billingAddressDto,
      customerId: 0,
      isDefault: false,
      isUsed: false,
    },
    paymentsRequest,
    eventName: aboutMeeting || '',
    accommodationId: venueID,
  };
};

export const getEditPaymentDataPayload = ({
  userId,
  formData,
  paymentsRequest = null,
  venueID,
}: TEditPaymentDataPayload) => {
  const {
    // user data
    firstName,
    lastName,
    email,
    phone,
    // end of user data
    orderDays,
    aboutMeeting,
  } = formData;

  const billingAddressDto = getBillingAddressDto<IEditFormState>(formData);

  const unitBookingDtos = orderDays.reduce(
    (prev, day) => [...prev, ...day.unitBookings],
    [] as IPreviewUnitResponse[],
  );

  return {
    orderDays,
    unitBookingDtos,
    firstName,
    lastName,
    email,
    phone,
    ...(userId ? {userId} : {}),
    billingAddressDto: {
      ...billingAddressDto,
      customerId: billingAddressDto.customerId || userId,
    },
    paymentsRequest,
    eventName: aboutMeeting || '',
    accommodationId: venueID,
  };
};

export const getPaymentOfferDataPayload = ({
  formData,
  offerId,
}: TPaymentOfferDataPayload) => {
  const {aboutMeeting} = formData;

  return {
    offerId,
    billingAddressDto: {
      ...getBillingAddressDto(formData),
      customerId: 0,
      isDefault: false,
      isUsed: false,
    },
    eventName: aboutMeeting || '',
  };
};

export const findDefaultCreditCard = (
  storedPaymentMethods: TStoredCard[],
  defaultCreditCardId: string,
  userId?: string | number,
) => {
  if (!userId || !storedPaymentMethods || !storedPaymentMethods.length)
    return null;
  return (
    storedPaymentMethods.find((card) => card.uuid === defaultCreditCardId) ||
    storedPaymentMethods.find((card) => card.defaultPaymentMethod) ||
    storedPaymentMethods[0]
  );
};
