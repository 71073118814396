import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import queryString from 'query-string';
import _get from 'lodash/get';
import {useLocation, useNavigate} from 'react-router-dom';

import AnchorMenu from 'view/components/SupplierLayout/components/AnchorMenu';
import ContactsDetails from './components/ContactsDetails/ContactsDetails';
import Header from './components/Header/Header';
import LegalDetails from './components/LegalDetails/LegalDetails';
import NW2VenueDescription from './components/NW2VenueDescription';
import NW2AmenitiesWrapper from './components/NW2AmenitiesForm/NW2AmenitiesWrapper';
import NW2OpeningHours from './components/NW2OpeningHours';
import NMMSection from 'view/components/NMMSection';
import PointsOfInterestWrapper from './components/PointsOfInterest';
import SidebarMenu from 'view/components/SupplierLayout/components/SidebarMenu';
import {NW2Images} from './components/NW2Images';
import NMMPayout from './components/NMMPayout/NMMPayout';
import {useAppSelector, useAppDispatch} from 'store/hooks';
import NW2AnnouncementPopup from '../../supplier/AnnouncementPopup/AnnouncementPopup';
import {TImageFile} from 'view/components/ImageUploaderComponent';
import FloorsWrapper from './components/Floors';
import useGlobalVenue from '../hooks/useGlobalVenue';

import {EMPTY_ARRAY, EMPTY_OBJECT} from 'constants/app';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {
  getAmenities,
  getVenueCharacters,
  getVenueCurrencies,
  setVenueImagesListFromResponse,
} from 'store/venue/actions';
import {IAmenity} from 'types/dto/IAmenity';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {
  ECorporateVenueProfilePages,
  EPublicVenueProfilePages,
  EVenueProfilePages,
  EVenueProfileSections,
  EVenueSupplierPagesWithHeader,
} from './types';
import {
  AnchorSections,
  AnchorsWrapper,
  LoaderContainer,
  Wrapper,
} from './NW2VenueProfile.styles';
import {getPage} from './helpers';
import {venueAmenitiesSet} from 'types/amenities';
import {IAnnouncement} from 'types/venue';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import {useCorporateOffices} from 'hooks/useCorporateOffices';
import {getCorporateOffices} from 'store/corporateOffice/apiActions';

interface IProps {
  accommodationType: EAccommodationType;
}

function NW2VenueProfile({accommodationType}: IProps) {
  const dispatch = useAppDispatch();
  const [globalVenue] = useGlobalVenue();
  const {hash} = useLocation();
  const navigate = useNavigate();
  const {corporateAccountId, corporateOffices, isLoading} =
    useCorporateOffices();

  const queryData = queryString.parse(location.search.slice(1)) || EMPTY_OBJECT;
  const venueIdFromQuery = queryData.id || '';
  const venueIdFromStore =
    useSelector((state) => _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.id`)) ||
    '';
  const userId: number = useAppSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.userId`),
  );
  const isLoggedIntoSystem = useAppSelector(
    ({app}) => app.user.isLoggedIntoSystem,
  );

  const venueAmenities: IAmenity[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.amenities`),
  );
  const venueImages: TImageFile[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.documents`),
  );
  const announcements: IAnnouncement[] = useAppSelector((state) =>
    _get(state, `venue.announcements`),
  );

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const amenities: IAmenity[] =
    useSelector((state) => _get(state, 'venue.amenities')) || EMPTY_ARRAY;

  const venueId = +venueIdFromQuery || +venueIdFromStore;

  const isExternalVenue = accommodationType === EAccommodationType.VENUE;

  const [isDataLoaded, setDataLoaded] = useState(false);
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);

  // initially fetch data on mount

  useEffect(() => {
    if (corporateAccountId && !corporateOffices?.length) {
      dispatch(getCorporateOffices(Number(corporateAccountId), false));
    }
  }, [corporateAccountId, corporateOffices?.length, dispatch]);

  useEffect(() => {
    if (venueId && !isDataLoaded) {
      if (!amenities.length) {
        dispatch(getAmenities(venueAmenitiesSet));
      }

      if (!corporateAccountId) {
        dispatch(getVenueCurrencies());
        dispatch(getVenueCharacters());
      }
      setDataLoaded(true);
    }
  }, [
    amenities.length,
    corporateAccountId,
    dispatch,
    isDataLoaded,
    venueId,
    venueImages,
  ]);

  // images
  const [isImgsLoaded, setImgsLoaded] = useState(false);
  useEffect(() => {
    if (venueImages && !isImgsLoaded) {
      dispatch(setVenueImagesListFromResponse(venueImages));
      setImgsLoaded(true);
    }
  }, [dispatch, isImgsLoaded, venueImages]);
  const [isEditShowed, setEditShowed] = useState(false);
  const onToggleImagesEdit = () => {
    setEditShowed((prev) => !prev);
  };

  //help get data for multi office management
  useEffect(() => {
    if (isLoading || !corporateAccountId) return;

    const isOtherVenue = Number(corporateAccountId) !== globalVenue?.venue?.id;

    if (isOtherVenue) {
      setDataLoaded(false);
      setImgsLoaded(false);
    }
  }, [corporateAccountId, globalVenue?.venue?.id, isLoading]);

  // pagination
  const [activeDetailsSection, setActiveDetailsSection] = useState<string>(
    EVenueProfilePages.INFO,
  );

  const sidebarSections = useMemo(() => {
    const sections = isExternalVenue
      ? Object.values(EPublicVenueProfilePages)
      : Object.values(ECorporateVenueProfilePages);

    return sections.map((item) => ({
      title: item,
      href: item,
    }));
  }, [isExternalVenue]);

  const anchorMenuItems = useMemo(() => {
    const items = Object.values(EVenueProfileSections);

    if (!isExternalVenue) {
      const itemsToExclude = [
        EVenueProfileSections.AMENITIES,
        EVenueProfileSections.POINTS_OF_INTEREST,
      ];
      return items.filter((item) => !itemsToExclude.includes(item));
    }

    return items;
  }, [isExternalVenue]);

  const handleChangeDetailsSection = useCallback(
    (sectionName: string) => {
      navigate({
        // Previously we had here: 'isExternalVenue ? Routes.publicVenue : Routes.corporateVenue',
        // but looks like it overhead.
        // Using location.pathname could be enough especially when now we have additional Agent role.
        pathname: location.pathname,
        hash: sectionName.replaceAll(' ', '').toLowerCase(),
      });
    },
    [navigate],
  );

  useEffect(() => {
    if (hash) setActiveDetailsSection(getPage(hash));
  }, [hash]);

  if (!globalVenue || !venueAmenities || !globalVenue.spaces)
    return (
      <LoaderContainer>
        <NW2Loader />
      </LoaderContainer>
    );

  const publicAnnouncements = announcements?.filter((announcement) =>
    announcement?.visibilityAreas.includes(
      isExternalVenue ? 'PUBLIC' : 'CORPORATE',
    ),
  );
  const isAnnouncementToShow =
    currentAnnouncementIndex + 1 < publicAnnouncements.length;

  const showNextAnnouncement = () => {
    if (isAnnouncementToShow) {
      setCurrentAnnouncementIndex(currentAnnouncementIndex + 1);
    }
  };

  return (
    <Wrapper>
      {publicAnnouncements.length > 0 && isLoggedIntoSystem && (
        <NW2AnnouncementPopup
          key={publicAnnouncements[currentAnnouncementIndex].id}
          announcementBody={{
            title: publicAnnouncements[currentAnnouncementIndex].title,
            subTitle: publicAnnouncements[currentAnnouncementIndex].subtitle,
            htmlString: decodeURIComponent(
              atob(publicAnnouncements[currentAnnouncementIndex].body),
            ),
          }}
          isMobile={isMobile}
          announcementId={publicAnnouncements[currentAnnouncementIndex].id}
          userId={Number(corporateAccountId) || userId}
          showNextAnnouncement={showNextAnnouncement}
        />
      )}

      <SidebarMenu
        title='VENUE DETAILS'
        activeSection={activeDetailsSection}
        sections={sidebarSections}
        handleSectionChange={handleChangeDetailsSection}
      />

      <main>
        {/* Info section */}
        {activeDetailsSection === EVenueProfilePages.INFO && (
          <>
            <Header
              globalVenue={globalVenue}
              showHeaderForSection={EVenueSupplierPagesWithHeader.INFO}
            />

            <AnchorsWrapper>
              <AnchorSections>
                <NMMSection
                  id={EVenueProfileSections.IMAGES}
                  title='Images'
                  isEditOn={isEditShowed}
                  onEdit={onToggleImagesEdit}
                >
                  <NW2Images
                    isEditShowed={isEditShowed}
                    onToggleEdit={onToggleImagesEdit}
                    venueId={venueId}
                    isExternalVenue={isExternalVenue}
                  />
                </NMMSection>

                <NW2VenueDescription
                  venue={globalVenue.venue}
                  isExternalVenue={isExternalVenue}
                />

                <NW2OpeningHours venueId={globalVenue.venue.id} />

                {isExternalVenue && (
                  <NW2AmenitiesWrapper
                    venue={globalVenue.venue}
                    amenities={amenities}
                    isExternalVenue={isExternalVenue}
                  />
                )}

                <FloorsWrapper
                  venue={globalVenue.venue}
                  isExternalVenue={isExternalVenue}
                />

                {isExternalVenue && (
                  <PointsOfInterestWrapper
                    venue={globalVenue.venue}
                    isExternalVenue={isExternalVenue}
                  />
                )}
              </AnchorSections>

              <AnchorMenu sections={anchorMenuItems} />
            </AnchorsWrapper>
          </>
        )}

        {/* Payment */}
        {activeDetailsSection === EVenueProfilePages.PAYMENT && (
          <NMMPayout globalVenue={globalVenue} />
        )}

        {/* Legal Details */}
        {activeDetailsSection === EVenueProfilePages.DETAILS && (
          <LegalDetails />
        )}

        {/* Contacts */}
        {activeDetailsSection === EVenueProfilePages.CONTACTS && (
          <ContactsDetails globalVenue={globalVenue} />
        )}
      </main>
    </Wrapper>
  );
}

export default NW2VenueProfile;
