import React, {ReactNode} from 'react';

import AnchorMenu from 'view/components/SupplierLayout/components/AnchorMenu';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import PrePostAccommodationBlock from 'view/venue/Offer/components/OfferRequestAccommodationBlock/PrePostAccommodationBlock';
import {Status} from './Status/Status';
import {BookingUnitExtras} from './BookingUnitExtras';
import {CancellationPolicyBlock} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import {BookingDetailsUnit} from './BookingDetailsUnit';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {
  getArrayOfUnitsFromOrderDays,
  getBookingDetailsType,
  hiddenBillingAddressReasons,
} from '../helpers';
import {
  formatDate,
  getAddressStringFromBillingAddress,
} from 'utils/stringUtils';
import {useBookingSections} from '../hooks/useBookingSections';

import {
  Text,
  Container,
  MainTitle,
  Wrapper,
  DaysBlock,
  DayTitle,
  DayContent,
  UnitBlock,
  UnitDayPrice,
  DaySubTotal,
  DaySubTotalPrice,
  TotalSection,
  TotalTitle,
  TotalPrice,
  FeesText,
  // After units
  Section,
  SubTitle,
  StyledSubTitle,
  StyledText,
  StyledTextes,
  BoldSpan,
  MainSubtitle,
} from '../BookingDetails.styles';
import {MODAL_TWO_COLS_RIGHT_COL_ID} from 'constants/app';
import {
  EBookingDetailsSections,
  IBookingChanges,
  IUnitBookingDetails,
} from 'types/bookingOrders';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {EBookingStatus} from 'types/booking';
import {IOrderDay} from 'types/dto/IBooking.types';
import {EEventType} from 'types/venue';
import {ERequestDetailsSections} from 'types/offer';
import {offsetDef, offsetXLg} from 'constants/styleVars';
import {
  TitleWrapper,
  Title,
} from 'view/venue/NW2BookingConfirmation/components/NW2BookingConfirmationUnitsBlock/NW2BookingConfirmationUnitsBlock.styles';
import {useAppSelector} from 'store/hooks';

type TProps = {
  billingAddressDto: ICustomerBillingAddress;
  isRfp: boolean;
  resolvedAt: string;
  createdAt: string;
  currencySymbol?: string;
  totalPriceAndCurrency: ReactNode;
  bookingStatus: EBookingStatus;
  prevBookingStatus?: EBookingStatus;
  declinedReason: string | null;
  holdUp: string;
  declinedMessage: string | null;
  venueTimeZone: string;
  isEdited: boolean;
  handleBookingChangeSidebarVisibility: () => void;
  isRTCPendingStatus: boolean;
  updatedAt: string;
  bookingChangesObj: Record<string, IBookingChanges>;
  orderDays: IOrderDay[];
  orderNumber: string;
  currency?: string;
};

const BookingDetailsInfo = ({
  billingAddressDto,
  isRfp,
  resolvedAt,
  createdAt,
  totalPriceAndCurrency,
  bookingStatus,
  declinedReason,
  holdUp,
  declinedMessage,
  venueTimeZone,
  isEdited,
  handleBookingChangeSidebarVisibility,
  isRTCPendingStatus,
  prevBookingStatus,
  updatedAt,
  bookingChangesObj,
  orderDays,
  orderNumber,
  currency,
}: TProps) => {
  const dynamicCancellationPolicy = useAppSelector(
    ({cancellationPolicy}) => cancellationPolicy.dynamicCancellationPolicy,
  );
  const isDynamicCancellationPolicyLoading = useAppSelector(
    ({cancellationPolicy}) =>
      cancellationPolicy.isDynamicCancellationPolicyLoading,
  );
  const locale = useAppSelector(({app}) => app.locale);

  const freeOfCharge =
    dynamicCancellationPolicy?.policies?.[0]?.freeOfCharge || 0;

  const filteredOrderDays = getFilteredUnitsByEventType(orderDays);
  const preArrivalData = getFilteredUnitsByEventType(
    orderDays,
    EEventType.PRE_ARRIVAL,
  );
  const postEventData = getFilteredUnitsByEventType(
    orderDays,
    EEventType.POST_EVENT,
  );

  const units = getArrayOfUnitsFromOrderDays(filteredOrderDays);

  const isRfpUnconfirmed = isRfp && bookingStatus !== EBookingStatus.CONFIRMED;
  const isSingle = Object.keys(units).length === 1;

  const isPreArrival = !!preArrivalData.length;
  const isPostEvent = !!postEventData.length;

  const bookingDetailsType = getBookingDetailsType(
    isSingle,
    isRfpUnconfirmed,
    bookingStatus,
  );

  const sections = useBookingSections({
    bookingDetailsType,
    units,
    preArrivalData,
    postEventData,
  }).filter(Boolean);

  const title = isRfpUnconfirmed
    ? EBookingDetailsSections.REQUEST_DETAILS
    : EBookingDetailsSections.BOOKING_DETAILS;

  const commonPropsForPrePostBlock = {
    isOffer: true,
    titleMargin: `0 0 ${offsetDef}`,
    currency,
  };

  const isCanceledStatus =
    bookingStatus === EBookingStatus.CANCELED ||
    bookingStatus === EBookingStatus.RFP_CANCELLED;

  return (
    <Container>
      <div>
        {bookingStatus !== EBookingStatus.RFP_PENDING && (
          <Section id={EBookingDetailsSections.STATUS}>
            <Status
              bookingStatus={bookingStatus}
              prevBookingStatus={prevBookingStatus}
              declinedReason={declinedReason}
              resolvedAt={resolvedAt}
              holdUp={holdUp}
              currency={currency}
              freeOfCharge={freeOfCharge}
              venueTimeZone={venueTimeZone}
              totalPriceAndCurrency={totalPriceAndCurrency}
              declinedMessage={declinedMessage}
              isEdited={isEdited}
              updatedAt={updatedAt}
              handleBookingChangeSidebarVisibility={
                handleBookingChangeSidebarVisibility
              }
              bookingChangesObj={bookingChangesObj}
              isPolicyLoading={isDynamicCancellationPolicyLoading}
            />
          </Section>
        )}

        <MainTitle>{title}</MainTitle>
        {isRTCPendingStatus && (
          <MainSubtitle>
            Confirmed on{' '}
            {DateUtils.getDateWithoutHours(
              createdAt,
              DateFormats['1 Jan, 2023'],
            )}
          </MainSubtitle>
        )}

        {isPreArrival && (
          <section id={ERequestDetailsSections.PRE_ARRIVAL}>
            <PrePostAccommodationBlock
              bookingData={preArrivalData}
              {...commonPropsForPrePostBlock}
            />
          </section>
        )}

        {getFilteredUnitsByEventType(orderDays).map((orderDay, index) => {
          const {
            checkInDate,
            unitBookings,
            foodAndBeverage,
            bedrooms: accommodations,
          } = orderDay;
          const date = formatDate(checkInDate, 'd MMM, YYYY', 'en-US');
          const dayId = `Day ${index + 1}`;
          const hasPackage = unitBookings.some((unit) => unit.packageId);
          const bedroomDateRange = DateUtils.getBedroomDateRange(checkInDate);

          return (
            <section key={`${date} ${index}`} id={isSingle ? title : ''}>
              <Wrapper>
                <DaysBlock id={dayId}>
                  <DayTitle>
                    {isPreArrival || isPostEvent || !isSingle
                      ? dayId
                      : 'Details'}
                  </DayTitle>
                  <DayContent>{date}</DayContent>
                </DaysBlock>

                <UnitBlock>
                  {unitBookings.map((unit, idx: number) => {
                    return (
                      <BookingDetailsUnit
                        key={`${unit.unitId} ${idx}`}
                        unit={unit as IUnitBookingDetails}
                        index={idx}
                        currency={currency}
                        isUnitSingle={unitBookings.length === 1}
                      />
                    );
                  })}
                  {!!foodAndBeverage?.length && (
                    <>
                      <TitleWrapper noTopMargin={!unitBookings.length}>
                        <Title>
                          {hasPackage
                            ? 'Additional Food & beverage'
                            : 'Food & beverage'}
                        </Title>
                      </TitleWrapper>
                      <BookingUnitExtras
                        data={foodAndBeverage}
                        currency={currency}
                      />
                    </>
                  )}
                  {!!accommodations?.length && (
                    <>
                      {/* todo update with AccommodationTitle */}
                      <TitleWrapper
                        noTopMargin={
                          !unitBookings.length && !foodAndBeverage?.length
                        }
                      >
                        <Title>Accommodation</Title>
                        <div>{bedroomDateRange}</div>
                      </TitleWrapper>

                      <BookingUnitExtras
                        data={accommodations}
                        currency={currency}
                        isBedroom
                      />
                    </>
                  )}

                  <UnitDayPrice margin={`${offsetXLg} 0 0`}>
                    <DaySubTotal>Day sub-total:</DaySubTotal>
                    <DaySubTotalPrice>
                      <PriceDisplay
                        price={orderDay.totalPriceForDay}
                        currency={currency}
                      />
                    </DaySubTotalPrice>
                  </UnitDayPrice>
                </UnitBlock>
              </Wrapper>
            </section>
          );
        })}

        {isPostEvent && (
          <section id={ERequestDetailsSections.POST_EVENT}>
            <PrePostAccommodationBlock
              bookingData={postEventData}
              {...commonPropsForPrePostBlock}
            />
          </section>
        )}

        <TotalSection>
          <TotalTitle>
            {isRfpUnconfirmed ? 'Request total' : 'Booking total'}
          </TotalTitle>
          <div>
            <TotalPrice>{totalPriceAndCurrency}</TotalPrice>
            <FeesText>Includes taxes and fees</FeesText>
          </div>
        </TotalSection>
        {(!isRfp || bookingStatus === EBookingStatus.CONFIRMED) && (
          <Section id={EBookingDetailsSections.CONFIRMATION_DETAILS}>
            <MainTitle>Confirmation details</MainTitle>
            <StyledText>
              <BoldSpan>Booking confirmed on:</BoldSpan>{' '}
              {/* resolvedAt is null for non rfp */}
              {DateUtils.getFormattedDateTime(
                resolvedAt || createdAt,
                venueTimeZone,
                DateFormats['01 Jan, 2023'],
                locale,
              )}
            </StyledText>
            <Text>
              All instant bookings get confirmed immediately on behalf of the
              venue.
            </Text>
          </Section>
        )}
        <Section id={EBookingDetailsSections.PAYMENT_INFORMATION}>
          <MainTitle>Payment information</MainTitle>
          <SubTitle>Arrange payment directly with the booker</SubTitle>
          <Text>
            We defer to the venue to arrange a payment method directly with the
            booker. Once this process is finalised, you may utilise the provided
            billing address to generate an invoice.
          </Text>
        </Section>
        <Section id={EBookingDetailsSections.BILLING_ADDRESS}>
          <MainTitle>Billing address</MainTitle>
          {isRfp && bookingStatus !== EBookingStatus.CONFIRMED ? (
            <>
              <SubTitle>Why can’t I see billing address?</SubTitle>
              <Text>{hiddenBillingAddressReasons[bookingStatus]}</Text>
            </>
          ) : (
            <>
              <Text>
                As bookings are concluded between the booker and the venue,
                please provide an invoice for all booked services - as part of
                this confirmation plus all additional services booked on-site -
                to the booker.
              </Text>
              <StyledSubTitle>
                Use the following address to generate an invoice:
              </StyledSubTitle>
              <StyledText>
                {getAddressStringFromBillingAddress(billingAddressDto)}
              </StyledText>
              <StyledText>
                Cost center: {billingAddressDto?.costCenter || '-'}
              </StyledText>
              <StyledText>
                Additional note: {billingAddressDto?.additionalReference || '-'}
              </StyledText>
            </>
          )}
        </Section>
        <Section id={EBookingDetailsSections.TERMS_AND_CONDITIONS}>
          <MainTitle>Terms & Conditions</MainTitle>
          <SubTitle>How is booker charged?</SubTitle>
          <StyledTextes>
            We defer to the venue to arrange a payment method directly with the
            booker. Once this process is finalised, you may utilise the provided
            billing address to generate an invoice.
          </StyledTextes>
          <SubTitle>What happens if the booker cancels?</SubTitle>
          <StyledText>
            If the booker cancels within their free-of-charge booking period
            they will not be charged.
          </StyledText>
          <StyledTextes>
            If the booker cancels outside of their free-of-charge booking
            period, you may utilise the provided billing address to generate an
            invoice.
          </StyledTextes>

          {[
            EBookingStatus.CONFIRMED,
            EBookingStatus.CANCELED,
            EBookingStatus.RFP_PENDING,
            EBookingStatus.RFP_CANCELLED,
            EBookingStatus.EDIT_CANCELED_BY_CONFIRM,
            EBookingStatus.EDIT_MODE_CANCELED,
            EBookingStatus.RTC_PENDING,
            EBookingStatus.RTC_DECLINED,
            EBookingStatus.RTC_CANCELLED,
            EBookingStatus.RTC_EXPIRED,
          ].includes(bookingStatus) && (
            <>
              <SubTitle>What is the cancellation policy?</SubTitle>
              <StyledTextes>
                <CancellationPolicyBlock
                  orderNumber={orderNumber}
                  isCanceledStatus={isCanceledStatus}
                />
              </StyledTextes>
            </>
          )}

          <SubTitle>What if there are additional charges?</SubTitle>
          <StyledTextes>
            Only extras which are not part of this booking can, and should be,
            charged on-site.
          </StyledTextes>
        </Section>
      </div>

      <AnchorMenu
        rootId={MODAL_TWO_COLS_RIGHT_COL_ID}
        sections={sections as string[]}
      />
    </Container>
  );
};

export default BookingDetailsInfo;
