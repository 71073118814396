import React from 'react';

import BillingAddressView from 'view/components/BillingAddress/BillingAddressView';
import VenueDetailsCard from 'view/venue/NW2BookingConfirmation/components/VenueDetailsCard';
import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import AboutMeeting from 'view/venue/NW2BookingConfirmation/components/AboutMeeting';
import NW2BookingConfirmationUnitsBlock from 'view/venue/NW2BookingConfirmation/components/NW2BookingConfirmationUnitsBlock';
import {OfferRequestReviewUnits} from 'view/venue/Offer/components/OfferRequestReviewUnits/OfferRequestReviewUnits';
import OfferOptionDate from 'view/venue/Offer/components/OfferOptionDate/OfferOptionDate';
import ItemPaymentInformation from 'view/venue/components/LeftSideContainer/ItemPaymentInformation';

import {WhatIsNext} from 'view/venue/Offer/components/WhatIsNext/WhatIsNext';
import {Bedrooms} from 'view/venue/NW2BookingPreview/components/Bedrooms/Bedrooms';

import {useAppSelector} from 'store/hooks';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EBookingStatus} from 'types/booking';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {StyledLeftSideContainer} from 'view/venue/components/LeftSideContainer/LeftSideContainer.styles';
import {
  EGroupStatus,
  EOfferStatus,
  ERequestStatus,
  IOfferRequestDay,
} from 'types/offer';
import {CancellationPolicyBlock} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import {ERoomType} from 'types/dto/ERoomType.type';
import BookingConfirmCancellationPolicy from '../BookingConfirmCancellationPolicy/BookingConfirmCancellationPolicy';

type TProps = {
  orderNumber?: string;
  bookingStatus?: EBookingStatus | ERequestStatus | EOfferStatus;
  billingAddress?: ICustomerBillingAddress;
  meetingName?: string;
  customerDeleted?: boolean;
  isOffer?: boolean;
  isOfferOrRequest?: boolean;
  isGroupRequestPage?: boolean;
  groupStatus?: EGroupStatus | null;
  accommodationType?: EAccommodationType;
  preArrivals?: IOfferRequestDay[];
  postEvents?: IOfferRequestDay[];
  roomType?: ERoomType;
};

function BookingConfirmationBlockList({
  orderNumber,
  accommodationType,
  bookingStatus,
  billingAddress,
  customerDeleted,
  meetingName,
  isOffer,
  isOfferOrRequest,
  preArrivals,
  postEvents,
  isGroupRequestPage,
  groupStatus,
  roomType,
}: TProps) {
  const isPublicVenue = accommodationType === EAccommodationType.VENUE;
  const isVenueContactsHidden = bookingStatus !== EBookingStatus.CONFIRMED;

  const orderStatus = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.bookingStatus,
  );

  if (!orderNumber) return null;

  const isCancellationPolicyHidden =
    !isPublicVenue ||
    isOfferOrRequest ||
    (orderStatus &&
      [
        EBookingStatus.PASSED,
        EBookingStatus.RFP_DECLINED,
        EBookingStatus.RFP_EXPIRED,
        EBookingStatus.RTC_EXPIRED,
      ].includes(orderStatus));

  const isCanceledStatus =
    orderStatus === EBookingStatus.CANCELED ||
    orderStatus === EBookingStatus.RFP_CANCELLED;

  const cancellationPolicyTitle = isCanceledStatus
    ? 'Cancellation policy applied'
    : 'Modification & Cancellation conditions';

  return (
    <StyledLeftSideContainer>
      <LeftSideItem
        item={{
          title: 'Venue contact details',
          children: <VenueDetailsCard />,
        }}
        isHidden={isVenueContactsHidden || isOfferOrRequest}
      />
      {
        <LeftSideItem
          item={{
            title: cancellationPolicyTitle,
            children:
              roomType === ERoomType.WORK_SPACE ? (
                <BookingConfirmCancellationPolicy />
              ) : (
                <CancellationPolicyBlock
                  orderNumber={orderNumber}
                  isCanceledStatus={isCanceledStatus}
                  isRTBText={orderStatus === EBookingStatus.RFP_PENDING}
                />
              ),
          }}
          isHidden={isCancellationPolicyHidden}
        />
      }
      <LeftSideItem
        item={{
          title: 'About meeting',
          children: <AboutMeeting meetingName={meetingName} />,
        }}
        isHidden={!meetingName}
      />
      {bookingStatus === ERequestStatus.REQUEST_PENDING && (
        <>
          <LeftSideItem
            item={{
              title: 'Option date',
              children: <OfferOptionDate />,
            }}
          />
        </>
      )}

      {!!preArrivals?.length && (
        <Bedrooms rooms={preArrivals} title='PRE-ARRIVAL' />
      )}

      {isOfferOrRequest ? (
        <OfferRequestReviewUnits
          isOffer={isOffer}
          isGroupRequestPage={isGroupRequestPage}
          hasPreArrivals={!!preArrivals?.length}
          hasPostEvents={!!postEvents?.length}
        />
      ) : (
        <NW2BookingConfirmationUnitsBlock
          hasPreArrivals={!!preArrivals?.length}
          hasPostEvents={!!postEvents?.length}
        />
      )}

      {!!postEvents?.length && (
        <Bedrooms rooms={postEvents} title='POST-EVENT' />
      )}

      <>
        {/*TODO: task NWRK-2780, NWRK-2781 */}
        {/* do not remove because we may need it in some nearest future */}
        {/*<LeftSideItem*/}
        {/*  item={{*/}
        {/*    title: 'Payment Method',*/}
        {/*    children: <Nw2BookingConfirmationPaymentMethod />,*/}
        {/*  }}*/}
        {/*  isHidden={!isPublicVenue || customerDeleted || isOfferOrRequest}*/}
        {/*/>*/}

        <ItemPaymentInformation
          isHidden={accommodationType === EAccommodationType.CORPORATE_OFFICE}
        />

        <LeftSideItem
          item={{
            title: 'Company address',
            children: (
              <BillingAddressView
                billingAddress={billingAddress}
                showIcon={bookingStatus !== EOfferStatus.OFFER_CONFIRMED}
              />
            ),
          }}
          isHidden={!billingAddress || customerDeleted || isOfferOrRequest}
        />
      </>
      {(bookingStatus === ERequestStatus.REQUEST_PENDING ||
        groupStatus === EGroupStatus.GROUP_REQUEST_PENDING) && (
        <LeftSideItem
          item={{
            title: 'What’s next',
            children: <WhatIsNext isGroupRequest={isGroupRequestPage} />,
          }}
        />
      )}
    </StyledLeftSideContainer>
  );
}

export default BookingConfirmationBlockList;
