import React, {ReactNode} from 'react';

import Icon from 'view/components/Icon';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';

import DateUtils, {DateFormats} from 'utils/dateUtils';
import {getRoomStatusDetails} from '../../helpers';
import {getTimeToCancel} from 'utils/helpers';
import {EOfferStatus, ERequestStatus} from 'types/offer';
import {EBookingStatus} from 'types/booking';
import {IDetailsObject} from './types';
import {Group} from 'styles/basicStyledComponents/group';
import {Link} from 'view/components/Typography';
import {getCurrencySymbol} from 'utils/stringUtils';
import {
  BorderContainer,
  Columns,
  Container,
  DeclineContainer,
  Description,
  Message,
  Span,
  StyledContainer,
  StyledLink,
  StyledSpan,
  SubTitle,
  Taxes,
  Title,
  TotalBlock,
  TotalSum,
  TotalTitle,
  Wrapper,
} from './Status.styles';
import {offsetXLg} from 'constants/styleVars';
import {offsetSm, offsetXSm} from 'styles/configs';
import {IBookingChanges} from 'types/bookingOrders';
import {FULL_FREE_OF_CHARGE} from 'constants/app';
import {useAppSelector} from 'store/hooks';
import {ELocale} from 'types/locale';

interface IStatusProps {
  data: IDetailsObject;
  totalPriceAndCurrency: ReactNode;
  isRequestWithBedroom?: boolean;
  hasTotalBlock?: boolean;
  freeOfCharge?: number;
  currency?: string;
  navigateTo?: () => void;
  isRTCPendingStatus?: boolean;
}

type TEditTextGroup = {
  isEdited: boolean;
  updatedAt: string;
  handleBookingChangeSidebarVisibility: () => void;
  isRTCPendingStatus?: boolean;
  prevBookingStatus: EBookingStatus;
  bookingChangesObj: Record<string, IBookingChanges>;
};

type TEditTextVariants = Record<string, ReactNode>;

const Default = ({
  data,
  totalPriceAndCurrency,
  navigateTo,
  isRTCPendingStatus,
}: IStatusProps) => (
  <>
    <Title>{data.title}</Title>
    <Columns divided='3fr auto'>
      <div>
        {data.description}
        {isRTCPendingStatus && (
          <StyledLink onClick={navigateTo}>
            View booking change request
          </StyledLink>
        )}
      </div>
      <TotalBlock>
        <TotalTitle>{data.totalTitle}</TotalTitle>
        <Taxes>Includes taxes and fees</Taxes>
        <TotalSum>{totalPriceAndCurrency}</TotalSum>
      </TotalBlock>
    </Columns>
  </>
);

const EditTextGroup = ({
  updatedAt,
  handleBookingChangeSidebarVisibility,
  isEdited,
  isRTCPendingStatus,
  prevBookingStatus,
  bookingChangesObj,
}: Partial<TEditTextGroup>) => {
  if (
    !isEdited ||
    isRTCPendingStatus ||
    !prevBookingStatus ||
    !bookingChangesObj
  )
    return null;

  let editDate;

  if (
    [
      EBookingStatus.RTC_DECLINED,
      EBookingStatus.RTC_EXPIRED,
      EBookingStatus.RTC_CANCELLED,
    ].includes(prevBookingStatus)
  ) {
    editDate = DateUtils.getDateWithoutHours(
      bookingChangesObj[prevBookingStatus].updatedAt,
      DateFormats['1 Jan, 2023'],
    );
  } else {
    editDate = DateUtils.getDateWithoutHours(
      updatedAt,
      DateFormats['1 Jan, 2023'],
    );
  }

  const editTextVariants: TEditTextVariants = {
    [EBookingStatus.RTC_DECLINED]: (
      <>
        <Icon icon='WARN' />
        <div>
          <Link onClick={handleBookingChangeSidebarVisibility}>
            Last update declined on {editDate}
          </Link>
        </div>
      </>
    ),
    [EBookingStatus.RTC_EXPIRED]: (
      <>
        <Icon icon='WARN' />
        <div>
          <Link onClick={handleBookingChangeSidebarVisibility}>
            Last update expired on {editDate}
          </Link>
        </div>
      </>
    ),
    [EBookingStatus.RTC_CANCELLED]: (
      <>
        <Icon icon='WARN' />
        <div>
          <Link onClick={handleBookingChangeSidebarVisibility}>
            Last update cancelled by booker on {editDate}
          </Link>
        </div>
      </>
    ),

    edited: (
      <>
        <Icon icon='CHECK' />
        <div>
          <Link onClick={handleBookingChangeSidebarVisibility}>
            View latest changes by booker
          </Link>{' '}
          (Last updated on {editDate})
        </div>
      </>
    ),
  };

  const variantToShow =
    editTextVariants[prevBookingStatus] || editTextVariants.edited;

  return (
    <Group gap={offsetSm} align='center'>
      {variantToShow}
    </Group>
  );
};

const Cancelled = ({
  data,
  totalPriceAndCurrency,
  freeOfCharge,
  currency,
  isRequestWithBedroom,
  hasTotalBlock = true,
}: IStatusProps) => (
  <>
    <Title>{data.title}</Title>
    <Columns divided='3fr auto'>
      <div>{data.description}</div>

      {hasTotalBlock && (
        <TotalBlock>
          <TotalTitle>{data.totalTitle}</TotalTitle>
          <Taxes>Includes taxes and fees</Taxes>
          <TotalSum>
            {isRequestWithBedroom
              ? '-'
              : freeOfCharge === FULL_FREE_OF_CHARGE
              ? `0,00 ${getCurrencySymbol(currency || '')}`
              : totalPriceAndCurrency}
          </TotalSum>
        </TotalBlock>
      )}
    </Columns>
  </>
);

interface IRequestCancelledProps {
  data: IDetailsObject;
  timeToCancel: string;
}

const RequestCancelled = ({data, timeToCancel}: IRequestCancelledProps) => (
  <>
    <Title>{data.title}</Title>
    <Columns divided='1fr 1fr'>
      <div>{data.description}</div>
      {timeToCancel && (
        <TotalBlock>
          <TotalTitle>{data.totalTitle}</TotalTitle>
          <TotalSum>{timeToCancel}</TotalSum>
        </TotalBlock>
      )}
    </Columns>
  </>
);

interface IDeclineStatusProps {
  data: IDetailsObject;
  reason: string | null;
  message: string | null;
  when: string;
  byWhom?: string;
  isOfferDeclined?: boolean;
}

const Declined = ({
  data,
  reason,
  when,
  message,
  byWhom,
  isOfferDeclined,
}: IDeclineStatusProps) => (
  <>
    <Title>{data.title}</Title>
    <DeclineContainer>
      {when && (
        <div>
          <SubTitle>Declined on: </SubTitle>
          {when}
        </div>
      )}
      {byWhom && !isOfferDeclined && (
        <div>
          <SubTitle>Declined by: </SubTitle> {byWhom}
        </div>
      )}
      {reason && (
        <div>
          <SubTitle>Reason: </SubTitle> {reason}
        </div>
      )}
      {message && (
        <>
          {isOfferDeclined ? (
            <div>
              <SubTitle>Message:</SubTitle>
              {message}
            </div>
          ) : (
            <>
              <Message>
                <b>Additional message:</b>
              </Message>
              <div>{message}</div>
            </>
          )}
        </>
      )}
    </DeclineContainer>
  </>
);

const Sent = ({
  data,
  totalPriceAndCurrency,
  creatorName,
  sentAt,
  venueTimeZone,
  expirationDate,
  isOfferAcceptingExpired,
  locale,
}: {
  data: IDetailsObject;
  totalPriceAndCurrency: ReactNode;
  venueTimeZone: string;
  sentAt?: string | null;
  creatorName?: string;
  expirationDate?: string;
  isOfferAcceptingExpired?: boolean;
  locale: ELocale;
}) => {
  const dataTimeContainer = (dataTime: string) => {
    return (
      <>
        <StyledSpan>
          {DateUtils.getFormattedDateTime(
            dataTime,
            venueTimeZone,
            DateFormats['22/05/1997'],
            locale,
          )}
        </StyledSpan>
      </>
    );
  };

  return (
    <>
      <Title>{data.title}</Title>
      <Description marginBottom={offsetXLg}>{data.description}</Description>
      <Columns divided={isOfferAcceptingExpired ? '1fr' : '305px 1fr'}>
        <div>
          {sentAt && (
            <Container divided='158px 1fr'>
              <Span>Offer sent on: </Span>
              <span>{dataTimeContainer(sentAt)}</span>
            </Container>
          )}
          {creatorName && (
            <Container divided='158px 1fr'>
              <Span>Offer submitted by: </Span>
              <Span>{creatorName}</Span>
            </Container>
          )}
          {expirationDate && (
            <StyledContainer divided='158px 1fr'>
              {isOfferAcceptingExpired ? (
                <>
                  <Span>Response deadline: </Span>
                  <div>{dataTimeContainer(expirationDate)}</div>
                </>
              ) : (
                <>
                  {/* optionDate = expirationDate */}
                  <Span>Option date: </Span>
                  <b>{dataTimeContainer(expirationDate)}</b>
                </>
              )}
            </StyledContainer>
          )}
        </div>
        {!isOfferAcceptingExpired && (
          <TotalBlock alignBottom={true}>
            <TotalTitle>{data.totalTitle}</TotalTitle>
            <Taxes>Includes taxes and fees</Taxes>
            <TotalSum>{totalPriceAndCurrency}</TotalSum>
          </TotalBlock>
        )}
      </Columns>
    </>
  );
};

interface IProps {
  totalPriceAndCurrency: ReactNode;
  resolvedAt: string;
  holdUp: string | null;
  bookingStatus: EBookingStatus | ERequestStatus | EOfferStatus;
  prevBookingStatus?: EBookingStatus;
  venueTimeZone: string;
  currency: string | undefined;
  declinedReason: string | null;
  freeOfCharge: number;
  declinedMessage: string | null;
  sentAt?: string | null;
  declinedBy?: string;
  creatorName?: string;
  expirationDate?: string;
  isEdited?: boolean;
  handleBookingChangeSidebarVisibility?: () => void;
  updatedAt?: string;
  bookingChangesObj?: Record<string, IBookingChanges>;
  isRequestWithBedroom?: boolean;
  isPolicyLoading?: boolean;
}

export const Status = ({
  bookingStatus,
  totalPriceAndCurrency,
  resolvedAt,
  declinedReason,
  holdUp,
  freeOfCharge,
  currency,
  declinedMessage,
  venueTimeZone,
  declinedBy,
  creatorName,
  sentAt,
  expirationDate,
  isEdited,
  handleBookingChangeSidebarVisibility,
  prevBookingStatus,
  updatedAt,
  bookingChangesObj,
  isRequestWithBedroom,
  isPolicyLoading,
}: IProps) => {
  const data = getRoomStatusDetails(freeOfCharge)[bookingStatus];
  const locale = useAppSelector(({app}) => app.locale);

  const isOfferAcceptingExpired =
    bookingStatus === EOfferStatus.OFFER_ACCEPTING_EXPIRED;

  const isRTCPendingStatus = bookingStatus === EBookingStatus.RTC_PENDING;

  if (!data) return null;

  return (
    <BorderContainer>
      <Wrapper bookingStatus={bookingStatus} gap={offsetXSm} direction='column'>
        {(bookingStatus === EBookingStatus.CONFIRMED ||
          bookingStatus === EBookingStatus.PASSED ||
          isRTCPendingStatus) && (
          <>
            <Default
              data={data}
              totalPriceAndCurrency={totalPriceAndCurrency}
              navigateTo={handleBookingChangeSidebarVisibility}
              isRTCPendingStatus={isRTCPendingStatus}
            />

            <EditTextGroup
              isEdited={isEdited}
              updatedAt={updatedAt}
              handleBookingChangeSidebarVisibility={
                handleBookingChangeSidebarVisibility
              }
              isRTCPendingStatus={isRTCPendingStatus}
              prevBookingStatus={prevBookingStatus}
              bookingChangesObj={bookingChangesObj}
            />
          </>
        )}

        {(bookingStatus === EBookingStatus.CANCELED ||
          bookingStatus === ERequestStatus.REQUEST_EXPIRED) &&
          (isPolicyLoading ? (
            <NW2Loader />
          ) : (
            <Cancelled
              data={data}
              totalPriceAndCurrency={totalPriceAndCurrency}
              freeOfCharge={freeOfCharge}
              currency={currency}
              isRequestWithBedroom={isRequestWithBedroom}
              hasTotalBlock={bookingStatus !== EBookingStatus.CANCELED}
            />
          ))}

        {(bookingStatus === EBookingStatus.RFP_DECLINED ||
          bookingStatus === ERequestStatus.REQUEST_DECLINED ||
          bookingStatus === EOfferStatus.OFFER_DECLINED) && (
          <Declined
            data={data}
            reason={declinedReason}
            message={declinedMessage}
            byWhom={declinedBy}
            when={DateUtils.getFormattedDateTime(
              resolvedAt,
              venueTimeZone,
              DateFormats["22/05/1997 'at' 22:22 (+02:00)"],
              locale,
            )}
            isOfferDeclined={bookingStatus === EOfferStatus.OFFER_DECLINED}
          />
        )}

        {(bookingStatus === EBookingStatus.RFP_CANCELLED ||
          bookingStatus === ERequestStatus.REQUEST_CANCELED) && (
          <RequestCancelled
            data={data}
            timeToCancel={getTimeToCancel(holdUp)}
          />
        )}

        {bookingStatus === EBookingStatus.RFP_EXPIRED && (
          <Default data={data} totalPriceAndCurrency={totalPriceAndCurrency} />
        )}

        {(bookingStatus === EOfferStatus.OFFER_PENDING ||
          bookingStatus === EOfferStatus.OFFER_ACCEPTING_EXPIRED) && (
          <Sent
            data={data}
            totalPriceAndCurrency={totalPriceAndCurrency}
            sentAt={sentAt}
            venueTimeZone={venueTimeZone}
            creatorName={creatorName}
            expirationDate={expirationDate}
            isOfferAcceptingExpired={isOfferAcceptingExpired}
            locale={locale}
          />
        )}
      </Wrapper>
    </BorderContainer>
  );
};
