import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import queryString from 'query-string';
import {useLocation, useNavigate} from 'react-router-dom';
import _get from 'lodash/get';

import NW2SearchSection from 'view/components/NW2SearchSection/NW2SearchSection';
import useSearchData from '../hooks/useSearchData';
import NW2VenuesContainer from 'view/venue/NW2VenuesList/components/NW2VenuesContainer';
import NW2VenueListGoogleMap from './components/NW2VenueListGoogleMap/NW2VenueListGoogleMap';
import CONFIG from './NW2VenuesList.config';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getVisibleRadius} from 'utils/googleMapUtils';
import {TMarkerLocation} from 'types/venue';
import {
  searchVenuesAction,
  setGoogleMapRefetch,
  setGoogleMapVisibleRadius,
} from 'store/venues/actions';
import {
  setMeetingRequestData,
  setMultiSearchTimeData,
  setSearchCriteria,
  setSearchCriteriaVisibleRadius,
  setSearchListLoaded,
} from 'store/search/searchSlice';
import {INITIAL_RADIUS} from 'constants/app';

import {
  StickyContainer,
  StyledContainer,
  StyledMap,
  StyledWrapper,
} from './NW2VenuesList.styles';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {useVenueSearchAction} from '../NW2VenueDetails/useVenueSearchAction';
import {IMergedVenue} from 'types/search';
import {makeMultiSearchPayload} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {ShortList} from '../Offer/customer/ShortList';
import {useShortListHandlers} from '../Offer/hooks/useShortListHandlers';
import {clearOperationalTimes} from 'store/venueDetails/venueDetailsSlice';
import {cleanWorkDesksDetails} from 'store/workDesks/workDesksSlice';

function NW2VenuesList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {isLoggedInUser} = useLoggedInUser();
  const {
    checkIsShortListItemAdded,
    handleAddShortListItem,
    handleRemoveShortListItem,
  } = useShortListHandlers();

  const venuesContainerRef = useRef<HTMLDivElement>(null);
  const [activeMarker, setActiveMarker] = useState<IMergedVenue | null>(null);
  const [isFullListShowed, setFullListShowed] = useState(false);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isGoogleMapExpanded: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.isGoogleMapExpanded'),
  );
  const loading: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.loading'),
  );

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const filteredBedroomExtras = useMemo(
    () => extrasOption.filter((item) => item.type === EResourcesType.BEDROOM),
    [extrasOption],
  );

  // Set initial Data for single room search
  const {initialSearchData, searchData, querySearchData, bedroomsCatering} =
    useSearchData();
  const activeMarkerId = activeMarker?.accommodationId || 0;

  // Set initial Data for multiroom search
  useEffect(() => {
    const {initialTimeData, meetingRequestData, ...restSearchData} =
      initialSearchData;

    dispatch(setSearchCriteria(restSearchData));
    dispatch(setMultiSearchTimeData(initialTimeData));
    dispatch(setMeetingRequestData(meetingRequestData));
  }, [dispatch, initialSearchData]);

  useEffect(() => {
    return () => {
      // clear venue details in store on unmount/navigate to details page
      dispatch(cleanWorkDesksDetails()); // works only for WD
      dispatch(clearOperationalTimes());
      dispatch(setSearchListLoaded(false));
    };
  }, [dispatch]);

  useVenueSearchAction({
    initialSearchData,
    bedroomsCatering,
    startDate: querySearchData.startDate,
    endDate: querySearchData.endDate,
  });

  //marker or venue list item click
  const onVenueItemClick = useCallback(
    (marker: IMergedVenue, hasPrice: boolean, isPinClick?: boolean) => () => {
      if (activeMarker?.accommodationId === marker.accommodationId) {
        setActiveMarker(null);
      }

      if (isPinClick) {
        setActiveMarker(marker);
      }

      if (!hasPrice) {
        setFullListShowed(true);
        return;
      }
    },
    [activeMarker?.accommodationId],
  );

  //short list
  const onRequestForOfferClick = useCallback(
    (venue: IMergedVenue) => () => {
      const {accommodationId, totalPrice, coverImageLink, location, name} =
        venue;

      const isItemAdded = checkIsShortListItemAdded?.(accommodationId);

      if (isItemAdded) {
        handleRemoveShortListItem?.(accommodationId);
        return;
      }

      onVenueItemClick(venue, !!totalPrice)();
      handleAddShortListItem?.({
        id: accommodationId,
        img: coverImageLink as string,
        location,
        name,
      });
    },
    [
      checkIsShortListItemAdded,
      handleAddShortListItem,
      handleRemoveShortListItem,
      onVenueItemClick,
    ],
  );

  const closeActiveVenueItem = useCallback(() => {
    setActiveMarker(null);
  }, [setActiveMarker]);

  useEffect(() => {
    if (loading) {
      closeActiveVenueItem();
    }
  }, [loading, closeActiveVenueItem]);

  const getVenuesForGoogleMap = useCallback(
    ({lat, lng, map}: TMarkerLocation & {map: google.maps.Map}): void => {
      const visibleRadius = getVisibleRadius({map});

      const {multiSearchPayload} = makeMultiSearchPayload({
        meetingRequestData: initialSearchData.meetingRequestData,
        bedroomsCatering,
        filteredBedroomExtras,
      });

      const requestData = {
        ...initialSearchData,
        filterDays: multiSearchPayload,
        page: 0,
        offerPage: 0,
        latitude: String(lat),
        longitude: String(lng),
        radius: visibleRadius || INITIAL_RADIUS,
        searchString: '', // backend needs it to be an empty string!
      };

      dispatch(setGoogleMapVisibleRadius(visibleRadius as number));
      dispatch(setSearchCriteriaVisibleRadius(visibleRadius as number));
      dispatch(searchVenuesAction(requestData));

      dispatch(setGoogleMapRefetch(false));

      // scroll to top on new search
      window.scrollTo({
        top: venuesContainerRef.current
          ? window.scrollY +
            venuesContainerRef.current.getBoundingClientRect().top
          : 0,
        behavior: 'smooth',
      });
      // save page to query search to also use it from query params
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({
          ...requestData,
          searchString: searchData.searchString,
        }),
      });
    },
    [
      bedroomsCatering,
      dispatch,
      filteredBedroomExtras,
      initialSearchData,
      location.pathname,
      navigate,
      searchData.searchString,
    ],
  );

  // Scroll left side venue item into view on its active google map marker click
  useLayoutEffect(() => {
    if (activeMarkerId && !isMobile && !isGoogleMapExpanded) {
      setTimeout(() => {
        const element = document.getElementById(`venue-${activeMarkerId}`);
        if (element) {
          const positionY =
            element.getBoundingClientRect().top +
            window.scrollY -
            CONFIG.filtersHeight;
          window.scrollTo({top: positionY, behavior: 'smooth'});
        }
      }, 100); // no other way
    }
  }, [activeMarkerId, isMobile, isGoogleMapExpanded]);

  return (
    <>
      <NW2SearchSection
        isLoggedInUser={isLoggedInUser}
        resultPageMode
        isMinimised
        isNoFixed
      />

      <StyledWrapper>
        {/* TODO: commented for release purpose*/}
        {/*{!isMobile && <NW2VenuesFilter />}*/}
        <StyledContainer ref={venuesContainerRef}>
          <StyledMap isMapExpanded={isGoogleMapExpanded}>
            <StickyContainer>
              <NW2VenueListGoogleMap
                checkIsShortListItemAdded={checkIsShortListItemAdded}
                onRequestForOfferClick={onRequestForOfferClick}
                closeActiveVenueItem={closeActiveVenueItem}
                onVenueItemClick={onVenueItemClick}
                getVenuesForGoogleMap={getVenuesForGoogleMap}
                activeMarker={activeMarker}
                isFullListShowed={isFullListShowed}
              />
            </StickyContainer>
          </StyledMap>
          {!isGoogleMapExpanded && (
            <NW2VenuesContainer
              onRequestForOfferClick={onRequestForOfferClick}
              onVenueItemClick={onVenueItemClick}
              isMobile={isMobile}
              isGoogleMapExpanded={isGoogleMapExpanded}
              activeMarkerId={activeMarkerId}
              isFullListShowed={isFullListShowed}
            />
          )}
        </StyledContainer>
      </StyledWrapper>

      <ShortList querySearchData={querySearchData} />
    </>
  );
}

export default NW2VenuesList;
