import React from 'react';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-final-form';

import {useAppSelector} from 'store/hooks';
import {setOpenLoginPopup} from 'store/app/appSlice';
import {setPreviewTotalDrawerVisible} from 'store/bookingsCustomer/bookingsCustomerSlice';
import {StyledButtonConfirm} from 'view/venue/NW2BookingPreview/components/NW2BookingTotal/NW2BookingTotal.styles';
import {offsetXXLg} from 'constants/styleVars';

type TProps = {
  submitting?: boolean;
  isRfp?: boolean;
  isMobile: boolean;
  isDrawerShowed: boolean;
  isOffice: boolean;
  handleSubmitClick?: () => void;
  extrasCount?: number;
  btnText?: string;
  isOfferRequest?: boolean;
  isOfferPreview?: boolean;
  isLoggedInUser?: boolean;
};

export function ButtonConfirm({
  submitting,
  isRfp,
  isMobile,
  isDrawerShowed,
  extrasCount,
  btnText = 'Book Now',
  isOffice,
  isOfferRequest,
  isOfferPreview,
  isLoggedInUser,
}: TProps) {
  const form = useForm();
  const dispatch = useDispatch();
  const adyenComponent = useAppSelector(({payment}) => payment.adyenComponent); // todo remove
  const isPaymentInProcess = useAppSelector(
    ({payment}) => payment.isPaymentInProcess,
  );
  const isBookingInProcess = useAppSelector(({customer}) => customer.isLoading);
  const isRequestLoading = useAppSelector(({offers}) => offers.isLoading);
  const errorSections = useAppSelector(
    ({bookingsCustomer}) => bookingsCustomer.errorFormSections,
  );

  const handleSubmitClick = () => {
    // scroll to the section with error
    if (isDrawerShowed) {
      dispatch(setPreviewTotalDrawerVisible(false));
    }
    if (typeof window !== 'undefined' && errorSections.length) {
      const section = document.getElementById(errorSections[0]);
      if (section) {
        const curScroll = window.scrollY;
        const OFFSET = parseInt(offsetXXLg);

        window.scrollTo({
          top: section.getBoundingClientRect().top + curScroll - OFFSET,
          behavior: 'smooth',
        });
      }
    }
  };

  const onClick = () => {
    if (!isLoggedInUser) {
      dispatch(setOpenLoginPopup({openLoginPopup: true}));
      return;
    }
    if (isMobile && !isDrawerShowed) {
      dispatch(setPreviewTotalDrawerVisible(true));
      return;
    }
    if (adyenComponent?.submit && !isOffice && !isOfferRequest) {
      adyenComponent.submit();
    }

    form.submit();
    handleSubmitClick();
  };

  const buttonText =
    isPaymentInProcess || isBookingInProcess || isRequestLoading
      ? 'Loading...'
      : isRfp
      ? isMobile
        ? 'Request'
        : 'Send request'
      : btnText;

  if (typeof submitting === 'undefined') return null;
  // To confirm payment and validate form fields we need type='submit' on current button
  // Function onClick used to validate adyen payment component
  return (
    <StyledButtonConfirm
      type='button'
      disabled={submitting}
      buttonType='primary'
      hasCart={!!extrasCount}
      onClick={onClick}
      loading={isPaymentInProcess}
      size={isOfferPreview ? 'medium' : undefined}
      fullWidth
    >
      {buttonText}
    </StyledButtonConfirm>
  );
}
