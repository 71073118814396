import styled, {css} from 'styled-components';

import {
  fontSizeMd,
  fontSizeXSm,
  fontWeightBold,
  googleMapSearchPredictionsMinWidth,
  mdBp,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Primary,
  offsetDef,
  offsetXLg,
  offsetXXXSm,
  textColor,
  nw2VenuesListZIndex,
  lgBp,
} from 'constants/styleVars';

const inputStyles = css`
  background: none;
  padding: ${offsetXXXSm} 0 0;
  border: none;
  outline: none;
  width: 100%;
  font-size: ${fontSizeMd};

  &::placeholder {
    color: ${NW2Gray600Color};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:disabled {
    pointer-events: none;
  }
`;

export const InputContainer = styled.div<{
  isNoBorder?: boolean;
  isNoMargin?: boolean;
  isNoPadding?: boolean;
  isWidthAuto?: boolean;
  positionRelative?: boolean;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  flex?: number;
}>(
  ({
    isNoBorder,
    isNoMargin,
    isNoPadding,
    isWidthAuto,
    positionRelative,
    alignItems,
    flex,
  }) => css`
    flex: ${flex};
    display: flex;
    align-items: ${alignItems || 'flex-start'};
    flex-direction: column;
    user-select: none;
    font-size: ${fontSizeMd};
    position: ${positionRelative ? 'relative' : 'initial'};

    &:first-of-type {
      flex: ${isWidthAuto ? '' : '0 1 100%'};
      padding-left: 0;
      border-left: none;
      padding-bottom: ${isNoPadding ? '' : offsetDef};
      margin-bottom: ${isNoMargin ? '' : offsetDef};
      border-bottom: ${isNoBorder ? '' : `1px solid ${NW2Gray200Color}`};
    }

    &:nth-of-type(4) {
      flex: ${isWidthAuto ? '' : '0 1 100%'};
      padding-top: ${isNoPadding ? '' : offsetDef};
      margin-top: ${isNoMargin ? '' : offsetDef};
      border-top: ${isNoBorder ? '' : `1px solid ${NW2Gray200Color}`};
    }

    ${!isWidthAuto &&
    css`
      & > div {
        width: 100%; // needed by GoogleApiWrapper's div!
      }
    `};

    input {
      ${inputStyles}
    }

    @media (min-width: ${lgBp}px) {
      &:first-of-type {
        min-width: ${isWidthAuto ? '' : googleMapSearchPredictionsMinWidth};
      }

      &:nth-of-type(2) {
        max-width: ${isWidthAuto ? '' : '253px'};
        min-width: 200px;
      }
    }

    @media (min-width: ${mdBp}px) {
      padding: ${isNoPadding ? '' : `0 ${offsetXLg}`};
      border-left: ${isNoBorder ? '' : `1px solid ${NW2Gray200Color}`};

      &:first-of-type {
        flex: ${isWidthAuto ? '' : 3};
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      &:nth-of-type(4) {
        flex: ${isWidthAuto ? '' : 1};
        border-top: none;
        padding-top: 0;
        margin-top: 0;
        min-width: ${isWidthAuto ? '' : '200px'};
      }

      &:not(:nth-of-type(4)):last-of-type {
        padding-right: 0;
        min-width: 50px;
      }
    }
  `,
);

export const NW2SearchFormInput = styled.input`
  ${inputStyles}
`;

export const InputComponentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.label<{isFocused: boolean; color?: string}>`
  text-transform: uppercase;
  color: ${({isFocused, color}) =>
    isFocused ? textColor : color || NW2Gray600Color};
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXSm};
  letter-spacing: 0.02em;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 10px;
    top: 50%;
    left: 2px;
    transition: width 0.15s, background-color 0.15s;
    z-index: 0;

    ${({isFocused}) =>
      isFocused
        ? `   
        width: 100%;
        background-color: ${NW2Primary};
      `
        : `
        width: 0;
        background-color: transparent;
      `}
  }

  span {
    position: relative;
    z-index: ${nw2VenuesListZIndex};
  }
`;

export const InputText = styled.div<{
  isFocused: boolean;
  noWrap?: boolean;
}>`
  white-space: ${({noWrap}) => (noWrap ? 'nowrap' : '')};
  color: ${({isFocused, color}) =>
    isFocused ? textColor : color || NW2Gray600Color};

  .ant-input-number {
    color: inherit;
  }
`;
