import {useCallback, useEffect, useMemo, useState} from 'react';
import {DateTime} from 'luxon';

import {
  setEndDateForVenuesSearch,
  setSearchFocusedInput,
  setStartDateForVenuesSearch,
} from 'store/search/searchSlice';
import {
  TMultiDateChangeFn,
  TMultiDateType,
} from 'view/components/NW2Datepicker/NW2DatePicker/NW2MultiDatePicker.types';
import {TSelectedGroupDate} from 'view/components/NW2Datepicker/NW2DatePicker.types';

import {setMultiSearchTimeData} from 'store/search/searchSlice';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import DateUtils, {getDateTime} from 'utils/dateUtils';
import {getISOString} from 'utils/dateUtils';

import {setHeaderCollapsed} from 'store/app/appSlice';
import useLockedBody from 'hooks/useLockedBody';
import {SEARCH_GROUPS_DATE} from '../../constants';
import {ALLOWED_DAYS_DIFFERENCE_FOR_RFP} from 'constants/days';
import {DEFAULT_TIME_DATA, loopOverDates} from 'view/components/NW2Datepicker';
import {EEventType} from 'types/venue';

type TPreSelectionDate = Partial<TSelectedGroupDate> | null;

interface IReturnProps {
  startDate: Date | null;
  endDate: Date | null;
  onDateChange: TMultiDateChangeFn;
  onToggleDatePicker: () => void;
  selectedDate: TSelectedGroupDate;
  isOpened: boolean;
  preSelectionDate: TPreSelectionDate;
  nightsCount: number;
}

export function useGroupsDatePicker(): IReturnProps {
  const dispatch = useAppDispatch();

  const isHeaderCollapsed = useAppSelector(({app}) => app.headerCollapsed);
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const initialTimeData = useAppSelector(({search}) => search.initialTimeData);

  const [isInitialized, setInitialized] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const [preSelectionDate, setPreSelectionDate] =
    useState<TPreSelectionDate>(null);
  const [startPickerDate, setStartPickerDate] = useState('');
  const [endPickerDate, setEndPickerDate] = useState('');
  const [nightsCount, setNightsCount] = useState(1);

  const [selectedDate, setSelectedDate] = useState<TSelectedGroupDate>({
    startDate: '',
    endDate: '',
  });

  const handleUpdateStoreData = useCallback(
    (timeStart: string, timeEnd: string) => {
      //needed in order to get correct date range for searching accommodations
      const minusOneEndDay = getISOString(
        getDateTime(timeEnd).minus({days: 1}),
      );
      const updatedTimeData = loopOverDates(timeStart, minusOneEndDay);

      dispatch(setStartDateForVenuesSearch(timeStart));
      dispatch(setEndDateForVenuesSearch(timeEnd));

      dispatch(setMultiSearchTimeData(updatedTimeData));
    },
    [dispatch],
  );

  const {defaultDateStart, defaultDateEnd} = useMemo(() => {
    // set initial dates
    const {days} = DateUtils.getDifferenceInDaysAndHours(
      searchCriteria.startDate,
      searchCriteria.endDate,
    );

    if (days && days >= 1) {
      return {
        defaultDateStart: searchCriteria.startDate,
        defaultDateEnd: searchCriteria.endDate,
      };
    }

    return {
      defaultDateStart: DateUtils.getISOFromDate(
        DateUtils.addDays(
          DEFAULT_TIME_DATA[0].timeStart,
          ALLOWED_DAYS_DIFFERENCE_FOR_RFP,
        ),
      ),
      defaultDateEnd: DateUtils.getISOFromDate(
        DateUtils.addDays(
          DEFAULT_TIME_DATA.at(-1)?.timeEnd as string,
          ALLOWED_DAYS_DIFFERENCE_FOR_RFP + 1,
        ),
      ),
    };
  }, [searchCriteria.endDate, searchCriteria.startDate]);

  const handleSetNights = useCallback((startDate: string, endDate: string) => {
    const {days} = DateUtils.getDifferenceInDaysAndHours(startDate, endDate);
    if (days) {
      setNightsCount(days);
    }
  }, []);

  useEffect(() => {
    // INIT
    if (isInitialized) return;

    // set local state dates
    setStartPickerDate(defaultDateStart);
    setEndPickerDate(defaultDateEnd);
    setSelectedDate({
      startDate: defaultDateStart,
      endDate: defaultDateEnd,
    });

    // set nights counter
    handleSetNights(defaultDateStart, defaultDateEnd);

    // set store dates
    handleUpdateStoreData(defaultDateStart, defaultDateEnd);

    setInitialized(true);
  }, [
    defaultDateEnd,
    defaultDateStart,
    handleSetNights,
    handleUpdateStoreData,
    isInitialized,
  ]);

  const {start, end} = useMemo(() => {
    //data for indirect day setting
    const dayDays = initialTimeData?.filter(
      ({eventType}) => eventType === EEventType.DAY,
    );
    const start = dayDays?.at(0)?.timeStart as string;
    const end = getISOString(
      getDateTime(dayDays?.at(-1)?.timeEnd).plus({days: 1}),
    );
    return {start, end};
  }, [initialTimeData]);

  useEffect(() => {
    //set picker dates indirectly through redux
    if (isInitialized) {
      setStartPickerDate(start);
      setEndPickerDate(end);

      setSelectedDate({
        startDate: start,
        endDate: end,
      });

      setPreSelectionDate({
        startDate: start,
        endDate: end,
      });

      handleSetNights(start, end);
    }
  }, [end, handleSetNights, isInitialized, start]);

  const onDateChange = (payload: TMultiDateType) => {
    if (Array.isArray(payload)) {
      const [start, end] = payload;

      const startDate = start ? getISOString(DateTime.fromJSDate(start)) : '';
      const endDate = end ? getISOString(DateTime.fromJSDate(end)) : '';

      setStartPickerDate(startDate);
      setPreSelectionDate({startDate});

      if (startDate === endDate) {
        // prevent to pick same date as start
        return;
      }

      setEndPickerDate(endDate);

      if (start && end) {
        // selection done
        setSelectedDate({
          startDate,
          endDate,
        });

        // set nights counter
        handleSetNights(startDate, endDate);

        setPreSelectionDate({startDate, endDate});

        // set store dates
        handleUpdateStoreData(startDate, endDate);

        // close DP on selection done
        setOpened(false);
      }
    }
  };

  const onClickHandler = useCallback(() => {
    if (isHeaderCollapsed) {
      dispatch(setHeaderCollapsed(false));
    }
  }, [isHeaderCollapsed, dispatch]);

  const {setLocked} = useLockedBody(false);

  useEffect(() => {
    if (!isMobile) return;

    setLocked(isOpened);
  }, [isMobile, isOpened, setLocked]);

  const onBlur = useCallback(() => {
    dispatch(setSearchFocusedInput(''));
  }, [dispatch]);

  const onUnfinishedSelection = useCallback(() => {
    if (!isOpened && (startPickerDate !== start || endPickerDate !== end)) {
      // reset DP dates if selection wasn't completed
      setStartPickerDate(start);
      setEndPickerDate(end);
    }
  }, [end, endPickerDate, isOpened, start, startPickerDate]);

  const onToggleDatePicker = useCallback(() => {
    if (!isOpened) {
      dispatch(setSearchFocusedInput(SEARCH_GROUPS_DATE));
    } else {
      onBlur();
    }

    onUnfinishedSelection();
    onClickHandler();
    setOpened((prev) => !prev);
    setPreSelectionDate(null);
  }, [dispatch, isOpened, onBlur, onClickHandler, onUnfinishedSelection]);

  return {
    startDate: startPickerDate
      ? DateTime.fromISO(startPickerDate).toJSDate()
      : null,
    endDate: endPickerDate ? DateTime.fromISO(endPickerDate).toJSDate() : null,
    onDateChange,
    selectedDate,
    onToggleDatePicker,
    isOpened,
    preSelectionDate,
    nightsCount,
  };
}
