import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Form} from 'react-final-form';
import {DateTime} from 'luxon';

import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
// import useOrderSubmit from 'view/venue/hooks/useOrderSubmit';

// import {ProcessingPaymentBlock} from 'view/components/AdyenPayment/components';
import {ConfirmOfferModal} from './components/ConfirmOfferModal/ConfirmOfferModal';
import {OfferRequestLeftSidePreview} from './components/OfferRequestLeftSidePreview/OfferRequestLeftSidePreview';
import {OfferReviewFooter} from './components/OfferReviewFooter';
import {OfferRequestTotal} from './components/OfferRequestSecondStep/components/OfferRequestTotal';
import {OfferSteps} from './components/OfferSteps/OfferSteps';
import {NW2SpaceDetails} from '../NW2VenueDetails/components';
import {DeclineModal} from 'view/components/RequestDecline/DeclineModal';

import {
  StyledMain,
  StyledWrapper,
} from '../NW2BookingPreview/NW2BookingPreview.styles';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import LocalStorageService from 'infra/common/localStorage.service';
import {TImage} from 'types/app';
import {findCoverImage} from 'utils/helpers';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {useIntersectionObserver} from 'hooks/useIntersectionObserver';
import {
  OfferReviewMobileActions,
  StyledHeader,
  StyledTitle,
} from './Offer.styles';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import {useOfferHandlers} from './hooks/useOfferHandlers';
import {Routes} from 'constants/routes';
import {IRfpHandlers} from 'view/supplier/hooks/useRfpHandlers';
import {useCommonExtras} from 'view/venue/hooks/useCommonExtras';
import {
  EDeclineItemType,
  EOfferStatus,
  ERequestStatus,
  IOfferV2,
} from 'types/offer';
import {useOfferBookingData} from './hooks/useOfferBookingData';
import {getGroupDetails, getOfferById} from 'store/offers/apiActions';
import {useOfferRedirect} from '../hooks/useOfferRedirect';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {useRedirectResult} from 'view/components/AdyenPayment/hooks/useRedirectResult';
import {clearVenueData} from 'store/venue/actions';
import {setRedirectLink} from 'store/app/appSlice';
import {
  clearGroupDetails,
  clearOfferDetails,
  setOffersError,
  setOffersLoading,
} from 'store/offers/offersSlice';
import {IBookingPreviewFormData} from 'store/venues/types';
import {
  getCustomerBookingOrder,
  handleOnSuccessPostCustomerBookingOrder,
} from 'store/customer/apiActions';
import {getPaymentOfferDataPayload} from '../../components/AdyenPayment/helpers';
import {offersContainer} from '../../../app';
import {IBookingOrderResponse} from 'types/dto/IBooking.types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {getCustomerBillingAddresses} from 'store/app/apiActions';

const OFFER_REVIEW_FOOTER = 'offerReviewFooter';
const NUMBER_OF_DISPLAYED_OFFERS = 1;

export function OfferReview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {isLoggedInUser} = useLoggedInUser();
  const {isRedirectResult} = useRedirectResult();
  const {id: offerId = ''} = useParams<{id: string}>();

  const [isConfirmModalShowed, setIsConfirmModalShowed] = useState(false);

  const onModalOpen = () => {
    setIsConfirmModalShowed(true);
  };
  const onModalClose = () => {
    setIsConfirmModalShowed(false);
  };

  const offerDetails = useAppSelector(({offers}) => offers.offerDetails);
  const isDetailsLoading = useAppSelector(({offers}) => offers.isLoading);
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isLoading = useAppSelector(({offers}) => offers.isLoading);
  const groupDetails = useAppSelector(({offers}) => offers.groupDetails);
  const role = useAppSelector(({app}) => app.user.role);

  const isAgentRole = role === EUserRoleCognito.ROLE_AGENT;

  const [params] = useSearchParams();
  const isFromCompareList = params.get('isFromCompareList') === 'true';

  const pendingOffers =
    groupDetails?.offers.filter(
      ({status}) => status === EOfferStatus.OFFER_PENDING,
    ).length || 0;

  const offersToDecline =
    pendingOffers > 0
      ? pendingOffers - NUMBER_OF_DISPLAYED_OFFERS
      : pendingOffers;

  const requestsToCancel =
    groupDetails?.requests.filter(
      ({status}) => status === ERequestStatus.REQUEST_PENDING,
    ).length || 0;

  const isInstantConfirm = offersToDecline + requestsToCancel === 0; // todo looks like useless code

  const {
    accommodationId,
    createdAt,
    id,
    checkIn,
    checkOut,
    customer,
    price,
    number,
    status,
    groupId,
    expirationDate,
    venue,
  } = offerDetails as IOfferV2;

  const locale = useAppSelector(({app}) => app.locale);

  const offerBookingData = useOfferBookingData(venue?.id);

  const respondDay = DateTime.fromISO(expirationDate).setZone(
    venue?.location.timeZone,
  );

  const documents = venue?.documents;

  const {onSuccessRedirect} = useOfferRedirect({
    venueId: accommodationId,
    offerId: id,
  });

  const offerHandlers = useOfferHandlers({
    onCloseOrderDetails: onSuccessRedirect,
    forwardLink: Routes.customerBookings,
  });
  const {onShowDeclineModal} = offerHandlers;

  const changeHandlers = {
    rfpHandlers: {} as IRfpHandlers,
    offerHandlers,
  };

  useEffect(() => {
    if (isDetailsLoading) return;

    if (isLoggedInUser && !offerDetails.checkIn) {
      dispatch(
        getOfferById({
          id: offerId,
          onSuccess: (orderNumber, venueId) => {
            if (!isRedirectResult) {
              navigate(`/booking/${orderNumber}?venueId=${venueId}`);
            }
          },
          onError: (error) => {
            if (error?.status === 404) {
              navigate(Routes.customerBookings);
            }
          },
        }),
      );
    }
  }, [
    dispatch,
    isDetailsLoading,
    isLoggedInUser,
    isRedirectResult,
    navigate,
    offerDetails.checkIn,
    offerId,
  ]);

  useEffect(() => {
    const storageUserId = LocalStorageService.getByKey('userId');

    if (!isLoggedInUser && !storageUserId) {
      navigate(Routes.mainLanding);
    }
  }, [isLoggedInUser, navigate]);

  useEffect(() => {
    if (isDetailsLoading) return;

    if (isAgentRole && customer && customer.id) {
      dispatch(getCustomerBillingAddresses(customer.id));
    }
  }, [customer, dispatch, isAgentRole, isDetailsLoading]);

  const venueAddress = getAddressStringFromLocation(venue?.location);
  const venueCoverImage = useMemo(
    () => findCoverImage((documents as TImage[]) || []),
    [documents],
  );

  //already declined and already accepted offer
  useEffect(() => {
    if (!id || isRedirectResult) return;

    if (status === EOfferStatus.OFFER_CONFIRMED) {
      navigate(`/booking/${number}?venueId=${accommodationId}`);
    }

    if (
      status === EOfferStatus.OFFER_DECLINED ||
      status === EOfferStatus.OFFER_ACCEPTING_EXPIRED
    ) {
      navigate(`/request-overview/${offerId}?isOffer=true`);
    }
  }, [
    accommodationId,
    id,
    isRedirectResult,
    navigate,
    offerId,
    number,
    status,
  ]);

  useEffect(() => {
    if (groupId && !groupDetails) {
      dispatch(getGroupDetails(groupId));
    }
  }, [dispatch, groupDetails, groupId]);

  useEffect(() => {
    return () => {
      dispatch(setRedirectLink(''));
      dispatch(clearOfferDetails());
      dispatch(clearGroupDetails());
      dispatch(clearVenueData());
    };
  }, [dispatch]);

  useCommonExtras();

  // handle sticky total behavior
  const headerRef = useRef(null);
  const headerEntry = useIntersectionObserver(headerRef, {});
  const isTotalSticky: boolean | undefined = !headerEntry?.isIntersecting;

  const onFormSubmit = (formData: IBookingPreviewFormData) => {
    const data = getPaymentOfferDataPayload({
      offerId: offerDetails.id,
      formData,
    });

    dispatch(setOffersLoading(true));
    offersContainer.acceptOffer({
      payload: data,
      onSuccess: (response: IBookingOrderResponse) => {
        dispatch(handleOnSuccessPostCustomerBookingOrder(response));
        dispatch(getCustomerBookingOrder(response.orderNumber));
        onSuccessRedirect(response, true);
        dispatch(setOffersLoading(false));
      },
      onError: (error: any) => {
        dispatch(setOffersError(error));
      },
    });
  };

  const onGoToVenueDetails = useCallback(() => {
    if (isFromCompareList) {
      navigate(-1);
      return;
    }

    if (isAgentRole) {
      navigate({
        pathname: Routes.agentView,
      });
      return;
    }

    navigate({
      pathname: Routes.customerBookings,
    });
  }, [isAgentRole, isFromCompareList, navigate]);

  // prevent keypress enter
  const onKeyPress = (e: KeyboardEvent) => {
    e.key === 'Enter' && e.preventDefault();
  };

  if (!offerBookingData || !id || !venue?.id || !groupDetails)
    return <NW2Loader height='100%' />;

  const onConfirm = () => {
    const formElem = document.getElementById(OFFER_REVIEW_FOOTER);
    // { cancelable: true } required for Firefox
    // https://github.com/facebook/react/issues/12639#issuecomment-382519193
    formElem?.dispatchEvent(
      new Event('submit', {cancelable: true, bubbles: true}),
    );
  };

  return (
    <>
      <StyledWrapper>
        <NW2Container>
          <StyledHeader ref={headerRef}>
            <NW2Button
              buttonType='secondary'
              minimized
              size='small'
              onClick={onGoToVenueDetails}
              icon={<Icon transparent icon='NW2_MOVE_BACK' />}
            />
            <StyledTitle>Bookings overview</StyledTitle>
          </StyledHeader>

          <OfferSteps activeStep={3} />

          <Form
            onSubmit={onFormSubmit}
            initialValues={offerBookingData?.formInitialValues}
            keepDirtyOnReinitialize
            render={({handleSubmit}) => (
              <form
                onSubmit={handleSubmit}
                onKeyDown={onKeyPress}
                id={OFFER_REVIEW_FOOTER}
              >
                <StyledMain>
                  <div>
                    {/*<ProcessingPaymentBlock />*/}

                    <OfferRequestLeftSidePreview
                      preArrivals={offerBookingData?.preArrivals}
                      postEvents={offerBookingData?.postEvents}
                      isOfferPreview
                    />
                  </div>

                  <OfferRequestTotal
                    currency={venue?.currency}
                    venueName={venue?.name}
                    venueCoverImage={venueCoverImage}
                    totalPrice={price?.value}
                    isTotalSticky={isTotalSticky}
                    venueAddress={venueAddress}
                    preArrivals={offerBookingData?.preArrivals}
                    postEvents={offerBookingData?.postEvents}
                    btnSubmitText='respond now'
                    isButtonSubmitDesktopHidden
                    isIconCartHidden
                    isOfferPreview
                    isAgentRole={isAgentRole}
                    customMobileButtons={
                      !isAgentRole && (
                        <OfferReviewMobileActions>
                          <NW2Button
                            onClick={onShowDeclineModal(id)}
                            size='medium'
                            inverted
                          >
                            decline
                          </NW2Button>
                          <NW2Button
                            buttonType='primary'
                            onClick={isInstantConfirm ? onConfirm : onModalOpen}
                            disabled={isLoading}
                            size='medium'
                          >
                            book now
                          </NW2Button>
                        </OfferReviewMobileActions>
                      )
                    }
                  />
                </StyledMain>
              </form>
            )}
          />
        </NW2Container>
      </StyledWrapper>

      {!isMobile && (
        <OfferReviewFooter
          respondDay={respondDay}
          receivedDate={DateUtils.getFormattedDateTime(
            createdAt,
            venue?.location.timeZone,
            DateFormats['01 Jan, 2023'],
            locale,
          )}
          respondDate={
            expirationDate
              ? DateUtils.getFormattedDateTime(
                  expirationDate,
                  venue?.location.timeZone,
                  DateFormats['01 Jan, 2023'],
                  locale,
                )
              : '-'
          }
          onConfirm={isInstantConfirm ? onConfirm : onModalOpen}
          onDecline={onShowDeclineModal(id)}
          isLoading={isLoading}
          hideButtons={isAgentRole}
        />
      )}
      <DeclineModal
        orderId={id}
        headerText='decline offer'
        changeHandlers={changeHandlers}
        checkInDate={checkIn}
        checkOutDate={checkOut}
        company={customer?.companyName}
        declineItem={EDeclineItemType.CUSTOMER}
        currency={price?.currency}
        holdUp=''
        orderNumber={number}
        totalPrice={price?.value}
        isMobile={isMobile}
        isOffer
        isCustomer
      />
      <ConfirmOfferModal
        isConfirmModalShowed={isConfirmModalShowed}
        isMobile={isMobile}
        offersToDecline={offersToDecline}
        onConfirm={onConfirm}
        onModalClose={onModalClose}
        requestsToCancel={requestsToCancel}
        isLoading={isLoading}
      />
      <NW2SpaceDetails isFooterHidden />
    </>
  );
}
