import {AppDispatch, RootState} from '../types';
import {setPreviewRooms} from './bookingsCustomerSlice';
import {TSelectedPackageItem} from 'types/dto/IPackages.type';
import {EResourcesType} from 'types/dto/IExtras.type';
import {
  IPreviewUnitResponse,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {IExtraResponse} from 'types/dto/IPublicVenue';
import {TSetExtraBookingPreviewValuePayload} from './types';

export const addPackageBookingPreview =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const previewRoomsState = state.bookingsCustomer.previewRooms;
    const selectedPackages = state.customer.customerSelectedPackages;
    const previewRooms = previewRoomsState.map((unit, index) => ({
      ...unit,
      selectedPackage: selectedPackages?.[index],
    }));
    dispatch(setPreviewRooms(previewRooms));
  };

export const deletePackageBookingPreview =
  (dayIndex: number, unitId: number) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const previewRoomsState = state.bookingsCustomer.previewRooms;
    const selectedPackages = state.customer.customerSelectedPackages;

    if (!selectedPackages) return;

    const previewRooms = previewRoomsState.map((unit, index) => {
      return dayIndex === index
        ? {
            ...unit,
            selectedPackage: selectedPackages[index].map(([id, packageData]) =>
              id === unitId ? [id, null] : [id, packageData],
            ) as TSelectedPackageItem[],
          }
        : unit;
    });

    dispatch(setPreviewRooms(previewRooms));
  };

const updateSelectedExtras = (
  extra: IExtraResponse,
  allExtras: IExtraResponse[],
) => {
  const isUpdateExistedValue = allExtras.some(
    (item) => item.code === extra.code,
  );
  return isUpdateExistedValue
    ? allExtras
        .map((chosenExtra) => {
          if (extra.code !== chosenExtra.code) return chosenExtra;
          return extra;
        })
        .filter((item) => item.quantity)
    : [...allExtras, extra];
};

export const updateOrdersPreviewData =
  (payload: TSetExtraBookingPreviewValuePayload) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const previewRoomsState = state.bookingsCustomer.previewRooms;
    const previewDesksState = state.bookingsCustomer.previewDesks;

    const previewUnitsState = previewRoomsState?.length
      ? previewRoomsState
      : previewDesksState;

    const {dayId, unitId, extra, checkInDate, checkOutDate} = payload;

    const isFoodAndBeverage =
      extra.extraType === EResourcesType.FOOD_AND_BEVERAGE;

    const shouldUpdateUnit = (unit: IPreviewUnitResponse) => {
      return (
        !isFoodAndBeverage &&
        unit.unitId === unitId &&
        unit.checkInDate === checkInDate &&
        unit.checkOutDate === checkOutDate
      );
    };

    const updateUnitChosenExtras = (unit: IPreviewUnitResponse) => {
      const chosenExtras = updateSelectedExtras(extra, unit.chosenExtras);
      // filter chosenExtras if index is greater than 0 and extraType is not FOOD_AND_BEVERAGE
      const filteredChosenExtras = chosenExtras.filter(
        ({extraType}) => extraType !== EResourcesType.FOOD_AND_BEVERAGE,
      );

      return {
        ...unit,
        chosenExtras: filteredChosenExtras,
      };
    };

    const previewRooms = previewUnitsState.map(
      ({foodAndBeverage, units, ...item}, index) => {
        if (index !== dayId)
          return {
            foodAndBeverage,
            units: units as IPreviewUnitResponse[],
            ...item,
          };

        const updatedUnits = (units as IPreviewUnitResponse[]).map((unit) =>
          shouldUpdateUnit(unit) ? updateUnitChosenExtras(unit) : unit,
        ) as IPreviewUnitResponse[];

        const updatedFoodAndBeverage = isFoodAndBeverage
          ? updateSelectedExtras(extra, foodAndBeverage)
          : foodAndBeverage;

        return {
          foodAndBeverage: updatedFoodAndBeverage,
          units: updatedUnits,
          ...item,
          unitBookings: updatedUnits,
        };
      },
    ) as TGroupedUnitsByDay[];

    dispatch(setPreviewRooms(previewRooms));
  };
